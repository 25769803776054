import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withTranslation } from 'react-i18next';

/*use transition effect for Dialog boxes*/
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class CallMe extends React.Component {

	constructor(props) {
		super(props);
		this.state = { 
			callme_dialog: false
		};
	}

	handleOpen = () => {
		this.setState({ callme_dialog: true });
	}

	handleClose = () => {
		this.setState({ callme_dialog: false });
	}
     
	render () {
		const {t} = this.props;
		return (
			<div style={this.props.NoConnectionState ? styles.wrapper_no_connection : styles.wrapper}>
        	<Button 
				id="call_me"
				variant="outlined" 
				color="secondary" 
				style={this.props.showButton ? (this.props.NoConnectionState ? styles.callme_button_no_connection : styles.callme_button) : styles.invisible}
				onClick = {this.handleOpen}
			>
            	{t('call me')}
          	</Button>

			<Dialog
				open={this.state.callme_dialog}
				onClose={this.handleClose}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
			<DialogTitle id="alert-dialog-slide-title" style={styles.dialog_title}>{t('notify mobile operator?')}</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-slide-description">
				{t('If confirmed, expect Mobile Operator to contact you shortly.')}
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button 
				id="call_me_cancel"
				onClick={this.handleClose} 
				color="primary" 
			>
				{t('cancel')}
			</Button>
			<Button 
				id="call_me_call_me"
				onClick={() => {
					window.MCC.sendVerification('call_sr')
					this.handleClose()
				}}
				color="secondary" 
			>
				{t('call me')}
			</Button>
			</DialogActions>
			</Dialog>
			</div>
		);
	}
}

const styles = {
	wrapper: {
		width: '65%',
	},
	dialog_title: {
		textTransform: 'capitalize'
	},
	wrapper_no_connection: {
		width: '33.3%',
  	},
	callme_button: {
		width: '100%',
		color: 'red',
		fontWeight: 'bold',
		fontSize: '3.5vh',
		borderRadius: 25,
  	},
	callme_button_no_connection: {
		height: '100%',
		width: '100%',
		color: 'red',
		fontWeight: 'bold',
		fontSize: '3.5vh',
		borderRadius: 10,
	},
	invisible: {
		display: 'none'
	},
}

export default withTranslation()(CallMe);