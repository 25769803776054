import React from 'react';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import BottomRow from '../BottomRow';
import { socket} from '../../App';
import NavBar from '../NavBar';
import CallMe from '../CallMe';
import VerificationProgress from './VerificationProgress';
import PlayersFieldSelector from './PlayersFieldSelector';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

let classes={};

class MatchInfoState extends React.Component {

  constructor(props) {
    super(props);
    classes = Object.assign(props.classes);
  }

  state = {
    progress: window.MCC.report.prematch_step4_confirmed ? 100 : 75,
  }
  
  sendCommand = (key, team, value) => {

    let cmdObj = {
      key: key,
      team: team,
      value: value
    }

    if (socket) { 
      socket.emit('command', cmdObj);
      window.MCC.dispatchEvent('nav_bar', 'setOverlay');
    }
  }

  handleChange = (event) => {

    let key = event.target.name;
    let team = 0;

    /* Sanity check for adding please select as option - SRT-1142 */
    if (event.target.value && event.target.value === 'please select') {
      return;
    }

    if (event.target.value === 'players on the court' || (window.MCC.match_info.players === 'players on the court' && event.target.name !== 'players')){
      socket.emit('report', {'prematch_step4_confirmed' : 'yes'});
      window.MCC.sendVerification('steps');
      this.setState({progress: 100});
    }
    if (event.target.name === 'home_color_1' || event.target.name === 'away_color_1'){
      key = 'shirt_color_1';
    }
    if (event.target.name === 'home_color_2' || event.target.name === 'away_color_2'){
      key = 'shirt_color_2';
    }
    if (event.target.name === 'home_color_1' || event.target.name === 'home_color_2'){
      team = 1;
    }
    if (event.target.name === 'away_color_1' || event.target.name === 'away_color_2'){
      team = 2;
    }           
    this.sendCommand(key, team, event.target.value);
  };

  render() {
    const {t} = this.props;

    return (
      <div className="dropdowns" style={styles.flex_column} data-test="MatchInfoState">
        <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props} data-test="NavBar"/>

        <VerificationProgress progress={this.state.progress}/>

        <div className="info_box" style={styles.info_box}>
            <div style={{fontWeight : 'bold' , margin: 0, textTransform: 'capitalize'}}>{t('match info 1- 15 min.')}</div>
        </div>

        <div className="neutral_venue_attendance" style={styles.flex}>
          
          <div style={styles.neutral_venue}>
            <TextField 
                id="venue"
                disabled
                label={'*' + t('neutral venue')}
                style={styles.textField}
                value={(window.MCC.event_info && window.MCC.event_info.venue && window.MCC.event_info.venue.ground) ? t(window.MCC.event_info.venue.ground).toUpperCase() : t('home').toUpperCase()}
                inputProps={{
                  name: 'neutral_venue'
                }}
            />
          </div>

          <div style={styles.attendance}>
            <form className="root" autoComplete="off" style={styles.attendance_root}>
              <FormControl id="select_attendence" className="formControl" style={styles.attendance_formControl}>
                <InputLabel htmlFor="comment-simple">*{t('attendance')}</InputLabel>
                <Select
                    value={typeof window.MCC.match_info !== 'undefined' && typeof window.MCC.match_info.attendance !== 'undefined' ? window.MCC.match_info.attendance : ''}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'attendance'
                    }}
                >
                    <MenuItem disabled value="please select">{t('please select')}</MenuItem>
                    <MenuItem value="0%">0%</MenuItem>
                    <MenuItem value="10%">10%</MenuItem>
                    <MenuItem value="20%">20%</MenuItem>
                    <MenuItem value="30%">30%</MenuItem>
                    <MenuItem value="40%">40%</MenuItem>
                    <MenuItem value="50%">50%</MenuItem>
                    <MenuItem value="60%">60%</MenuItem>
                    <MenuItem value="70%">70%</MenuItem>
                    <MenuItem value="80%">80%</MenuItem>
                    <MenuItem value="90%">90%</MenuItem>
                    <MenuItem value="100%">100%</MenuItem>
                  </Select>
              </FormControl>
            </form>
          </div>
        </div>

        <div className="home_color_1" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_home_color_1" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">*{t('home colors')}</InputLabel>
              <Select
                  value={typeof window.MCC.match_info !== 'undefined' && typeof window.MCC.match_info.home_color_1 !== 'undefined' ? window.MCC.match_info.home_color_1 : ''}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'home_color_1'
                  }}
              >
                  <MenuItem disabled value="please select" classes={{root: classes.root}}>{t('please select')}</MenuItem>
                  <MenuItem value="white" classes={{root: classes.root}}>{t('white')}</MenuItem>
                  <MenuItem value="black" classes={{root: classes.root}}>{t('black')}</MenuItem>
                  <MenuItem value="red" classes={{root: classes.root}}>{t('red')}</MenuItem>
                  <MenuItem value="blue" classes={{root: classes.root}}>{t('blue')}</MenuItem>
                  <MenuItem value="green" classes={{root: classes.root}}>{t('green')}</MenuItem>
                  <MenuItem value="yellow" classes={{root: classes.root}}>{t('yellow')}</MenuItem>
                  <MenuItem value="orange" classes={{root: classes.root}}>{t('orange')}</MenuItem>
                  <MenuItem value="purple" classes={{root: classes.root}}>{t('purple')}</MenuItem>
                  <MenuItem value="pink" classes={{root: classes.root}}>{t('pink')}</MenuItem>
                  <MenuItem value="brown" classes={{root: classes.root}}>{t('brown')}</MenuItem>
                  <MenuItem value="grey" classes={{root: classes.root}}>{t('grey')}</MenuItem>
                </Select>
            </FormControl>
          </form>
        </div>

        <div className="home_color_2" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_home_color_2" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">*{t('home colors')}</InputLabel>
              <Select
                  value={typeof window.MCC.match_info !== 'undefined' && typeof window.MCC.match_info.home_color_2 !== 'undefined' ? window.MCC.match_info.home_color_2 : ''}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'home_color_2'
                  }}
              >
                  <MenuItem disabled value="please select" classes={{root: classes.root}}>{t('please select')}</MenuItem>
                  <MenuItem value="white" classes={{root: classes.root}}>{t('white')}</MenuItem>
                  <MenuItem value="black" classes={{root: classes.root}}>{t('black')}</MenuItem>
                  <MenuItem value="red" classes={{root: classes.root}}>{t('red')}</MenuItem>
                  <MenuItem value="blue" classes={{root: classes.root}}>{t('blue')}</MenuItem>
                  <MenuItem value="green" classes={{root: classes.root}}>{t('green')}</MenuItem>
                  <MenuItem value="yellow" classes={{root: classes.root}}>{t('yellow')}</MenuItem>
                  <MenuItem value="orange" classes={{root: classes.root}}>{t('orange')}</MenuItem>
                  <MenuItem value="purple" classes={{root: classes.root}}>{t('purple')}</MenuItem>
                  <MenuItem value="pink" classes={{root: classes.root}}>{t('pink')}</MenuItem>
                  <MenuItem value="brown" classes={{root: classes.root}}>{t('brown')}</MenuItem>
                  <MenuItem value="grey" classes={{root: classes.root}}>{t('grey')}</MenuItem>
                </Select>
            </FormControl>
          </form>
        </div>

        <div className="away_color_1" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_away_color_1" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">*{t('away colors')}</InputLabel>
              <Select
                  value={typeof window.MCC.match_info !== 'undefined' && typeof window.MCC.match_info.away_color_1 !== 'undefined' ? window.MCC.match_info.away_color_1 : ''}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'away_color_1'
                  }}
              >
                  <MenuItem disabled value="please select" classes={{root: classes.root}}>{t('please select')}</MenuItem>
                  <MenuItem value="white" classes={{root: classes.root}}>{t('white')}</MenuItem>
                  <MenuItem value="black" classes={{root: classes.root}}>{t('black')}</MenuItem>
                  <MenuItem value="red" classes={{root: classes.root}}>{t('red')}</MenuItem>
                  <MenuItem value="blue" classes={{root: classes.root}}>{t('blue')}</MenuItem>
                  <MenuItem value="green" classes={{root: classes.root}}>{t('green')}</MenuItem>
                  <MenuItem value="yellow" classes={{root: classes.root}}>{t('yellow')}</MenuItem>
                  <MenuItem value="orange" classes={{root: classes.root}}>{t('orange')}</MenuItem>
                  <MenuItem value="purple" classes={{root: classes.root}}>{t('purple')}</MenuItem>
                  <MenuItem value="pink" classes={{root: classes.root}}>{t('pink')}</MenuItem>
                  <MenuItem value="brown" classes={{root: classes.root}}>{t('brown')}</MenuItem>
                  <MenuItem value="grey" classes={{root: classes.root}}>{t('grey')}</MenuItem>
                </Select>
            </FormControl>
          </form>
        </div>

        <div className="away_color_2" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_away_color_2" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">*{t('away colors')}</InputLabel>
              <Select
                  value={typeof window.MCC.match_info !== 'undefined' && typeof window.MCC.match_info.away_color_2 !== 'undefined' ? window.MCC.match_info.away_color_2 : ''}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'away_color_2'
                  }}
              >
                  <MenuItem disabled value="please select" classes={{root: classes.root}}>{t('please select')}</MenuItem>
                  <MenuItem value="white" classes={{root: classes.root}}>{t('white')}</MenuItem>
                  <MenuItem value="black" classes={{root: classes.root}}>{t('black')}</MenuItem>
                  <MenuItem value="red" classes={{root: classes.root}}>{t('red')}</MenuItem>
                  <MenuItem value="blue" classes={{root: classes.root}}>{t('blue')}</MenuItem>
                  <MenuItem value="green" classes={{root: classes.root}}>{t('green')}</MenuItem>
                  <MenuItem value="yellow" classes={{root: classes.root}}>{t('yellow')}</MenuItem>
                  <MenuItem value="orange" classes={{root: classes.root}}>{t('orange')}</MenuItem>
                  <MenuItem value="purple" classes={{root: classes.root}}>{t('purple')}</MenuItem>
                  <MenuItem value="pink" classes={{root: classes.root}}>{t('pink')}</MenuItem>
                  <MenuItem value="brown" classes={{root: classes.root}}>{t('brown')}</MenuItem>
                  <MenuItem value="grey" classes={{root: classes.root}}>{t('grey')}</MenuItem>
                </Select>
            </FormControl>
          </form>
        </div>

        <div className="players_status" style={styles.players_status}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_players" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">*{t('players are')}</InputLabel>
              <PlayersFieldSelector />
            </FormControl>
          </form>
        </div>
        
        <div style={styles.confirm_callme_field}>
          <CallMe showButton/>

          <Button 
              id="main_page"  
              variant="outlined" 
              color="secondary"
              disabled={!(window.MCC.match_info && window.MCC.match_info.players === 'players on the court') || (window.MCC.game_status.status_id !== 1 && (window.MCC.match_info && window.MCC.match_info.players === 'players on the court'))} 
              style={((window.MCC.match_info && window.MCC.match_info.players === 'players on the court') && window.MCC.game_status.status_id === 1) ? styles.confirm : styles.confirm_disabled}
              data-test='main_page'
              onClick = {() => {
                window.MCC.current_nocommand_state =  'StandByState';
                this.props.setPage('StandByState', {set_name: '1st set', id: this.props.id});
              }}
          >
              {t('main page')}
          </Button>      

          {/* <MainPageButton {...this.props}/> */}
        </div>
        <BottomRow 
          setPage={this.props.setPage}
          left_arrow="none" 
          right_arrow={this.props.prev_state || "CommentState"}
          id={this.props.id}
          team={this.props.team}
        />
      </div>
    );
  }
}

/*CSS Styles*/

const styles = {
  formControl: {
    margin       : 0 ,
    width        : '60%',
    textTransform: 'capitalize'
  },
  attendance_formControl: {
    //margin       : 0 ,
    width        : '87%',
    textTransform: 'capitalize'
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },    
  textField: {
    //margin       : 2,
    width        : '60%',
    textTransform: 'capitalize'
  },
  flex : {
    display : 'flex',
    justifyContent: 'space-evenly', 
    height: '9%',
  },
  flex_column:{
    display : 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  root: {
    width: '100%',
    justifyContent: 'space-around',
    display: 'flex',
  },
  attendance_root: {
    width: '100%',
    //justifyContent: 'space-around',
    display: 'flex',
  },
  row: {
    display : 'flex',
    width: '100%',
    position:'absolute',
    bottom:0,
  },
  info_box: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width : '100%',
    height: '3.5%',
    textAlign: 'center',
    //paddingBottom: '2%',
  },
  neutral_venue: {
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'capitalize',
    //marginTop: '1.5%'
  },
  attendance: {
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'capitalize',
    width: '30%',
    //marginTop: '1.5%'
  },
  players_status : {
    display : 'flex',
    justifyContent: 'space-evenly', 
    height: '8%',
  },
  confirm_callme_field: {
    display : 'flex',
    marginTop: '3%',
    //height: '14%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirm: {
    margin: 2,
    width: '65%',
    color: 'green',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    borderRadius: 25,
  },
  confirm_disabled: {
    margin: 2,
    width: '65%',
    color: 'grey',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    borderRadius: 25,
  }
}

const overrideStyles = {
  root: {
    textTransform: 'capitalize' 
  } 
}

MatchInfoState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(overrideStyles)(MatchInfoState));