import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withTranslation } from 'react-i18next';

const OfflineScoringRow = props => {
    const {t} = props;
    return(
      <ListItem 
        button style={props.point ? styles.point : styles.misconduct}
      >
          <ListItemText 
            disableTypography
            primary={t(window.MCC.offline_scoring_data[props.id].team.toLowerCase()).toUpperCase() + ': ' + t(window.MCC.offline_scoring_data[props.id].key.toLowerCase()).toUpperCase() + ' ' + (typeof props.point !== 'undefined' ? props.point : '') + t(' at ') + window.MCC.offline_scoring_data[props.id].time} 
            style={styles.text}
          />
      </ListItem>
    )
}

const styles = {
    point: {
      color: 'white',
      backgroundColor: '#4CAF50',
      border: '1px solid white',
      borderRadius: "20px",
      height: '10%',
      width: '100%',
      opacity: 0.7,
    },
    misconduct: {
      color: 'white',
      backgroundColor: '#df2029',
      border: '1px solid white',
      borderRadius: "20px",
      height: '10%',
      width: '100%',
      opacity: 0.7,
    },
    text: {
      fontWeight : 'bold',
      fontSize: 20,
      fontFamily: 'Rubik'
    }
};

export default withTranslation()(OfflineScoringRow);