import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {pulse} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { withTranslation } from 'react-i18next';

const commandRow = props => {
  
  const {t} = props;

  const command      = window.MCC.commands[props.id];

  const setValue = () => {
    let val = '';

    if (typeof command.value !== 'undefined'){
      if (typeof command.value !== 'object'){
        val = t(command.value);
        if (command.key === 'comment'){
          val = t(command.value.toLowerCase());
        }        
        if (command.key === 'status'){
          val = t(window.MCC.status_types[command.value].title.toLowerCase());
        }
      }else {
        if (command.value.type){
          val += t(command.value.type);
        }
        if (command.value.key){
          val += ' ' + t(command.value.key.replace(/_/g, " ")) + ' :';
        } 
        if (command.value.value){
          val += ' ' + t(command.value.value);
        }               
      }
    }
        
    return val.replace(/_/g, " ");
  };

  const show_score_commands = ['point', 'adjust_score_up', 'adjust_score_down']

  const is_confirmed = window.MCC.commands[props.id].confirmed === 0 ? '*' : '',
        value        = setValue(),
        logtype      = props.logtype === '' ? 'full' : (props.logtype === 'short_' ? 'short' : false),
        team_text    = command.team === 1 ? ' [H] ' : (command.team === 2 ? ' [A] ' : ' '),
        edit_delete  = logtype === 'full' && window.MCC.all_edit_commands.includes(command.key) ? 'D/E' : (logtype === 'full' && window.MCC.all_delete_commands.includes(command.key) ? 'D' : (logtype === 'full' && window.MCC.all_only_edit_commands.includes(command.key)) ? 'E' : ''),
        timestamp    = command.timestamp ? new Date(command.timestamp).toLocaleTimeString('it-IT', { timeZone: 'UTC' }) : '',
        log_text     = logtype === 'full' ? (t(window.MCC.command_log[command.key].text) + ' ' + value) : (command.value ? value : t(window.MCC.command_log[command.key].text)),
        speed        = props.logtype === 'short_' ? ('(' + props.speed + ' ms)') : '',
        game_time    = command.game_time ? ('['+Math.floor(command.game_time/60)+':'+(command.game_time%60 < 10 ? '0'+command.game_time%60 : command.game_time%60)+']') : '[0:00]',
        score        = show_score_commands.includes(command.key) && command.confirmed === 1 && typeof props.score_home !== 'undefined' && typeof props.score_away !== 'undefined' ? (props.score_home + ':' + props.score_away) : '';
        
    return(
      <ListItem 
        key={command.id} 
        disabled={command.confirmed === -1 ? true : false}
        button style={Object.assign({ backgroundColor: window.MCC.command_log[command.key].color }, 
                      command.confirmed === -1 ? Object.assign(styles.cancelled, styles[props.logtype + 'logline']) : styles[props.logtype + 'logline'])}
 
        onClick = {() => {
          if (window.MCC.all_edit_commands.includes(command.key)) {
            props.setPage(
              'EditDeleteState' , 
              { 
                    timestamp : timestamp,
                    game_time : game_time,
                           id : command.id ,
                   back_state : 'CommandLogState',
                   team       : command.team
              }
            );
          } else if (window.MCC.all_delete_commands.includes(command.key)) {
            props.setPage(
              'DeleteState',
              {            id : props.id,
                    timestamp : timestamp,
                    game_time : game_time,
                   back_state : logtype === 'full' ? 'CommandLogState' : window.MCC.mainPage,
                   team       : command.team

              }
            );
          } else if (window.MCC.all_only_edit_commands.includes(command.key)) {
            props.setPage(
              'EditOnlyState',
              {            id   : props.id,
                    timestamp   : timestamp,
                    game_time   : game_time,
                   back_state   : logtype === 'full' ? 'CommandLogState' : window.MCC.mainPage,
                   team         : command.team === 1 ? "[H]" : "[A]",
                   command_key  : window.MCC.commands[props.id].key

              }
            );
          }
          window.MCC.is_command_log_delete = true;
        }}>
        <StyleRoot style={is_confirmed === '*' ? styles.reactAnimation : styles[props.logtype + 'text']}>
          <ListItemText 
            disableTypography
            primary={(logtype === 'full' ? timestamp : '') + ' #' + command.id + t(team_text) + log_text + is_confirmed + ' ' + speed + ' ' + edit_delete} 
            id={command.id} 
            style={styles[props.logtype + 'text']}
          />
          </StyleRoot>
          <span style={styles.score}>{score}</span>
      </ListItem>
    )
}

const styles = {
    logline: {
      color: 'white',
      border: '0.5px solid white',
      borderRadius: "20px",
      height: '100%',
      width: '100%'
    },
    text: {
      fontWeight : 'bold',
      fontSize: '2.3vh',
      fontFamily: 'Rubik',
      textTransform: 'upperCase'
    },
    score: {
      fontWeight : 'bold',
      fontSize: '3vh',
      fontFamily: 'Rubik',
      color: '#ff3300',
    },
    short_logline: {
      color: 'white',
      border: '0.5px solid white',
      borderRadius: 7,
      height: '33%',
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0,
    },
    short_text: {
      fontWeight : 'bold',
      fontSize: '1.9vh',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontFamily: 'Rubik',
      textTransform: 'upperCase'
    },
    cancelled: {
      textDecoration: 'line-through',
      fontStyle: 'italic',
      opacity: 0.5
    },
    reactAnimation: {
    animation: 'infinite 0.8s',
    animationName: Radium.keyframes(pulse, 'pulse'),
  }
};

export default withTranslation()(commandRow);