import React from 'react';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { socket } from '../../App';
import NavBar from '../NavBar';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

let classes={};

class EditConfirmState extends React.Component {

	constructor(props) {
		super(props);
		classes = Object.assign(props.classes);
	}

	state = {
	    team_change : typeof this.props.changed_team_value !== 'undefined' && this.props.changed_team_value ? this.props.changed_team_value.toString() : window.MCC.commands[this.props.id].team.toString(),
	    point_type  : typeof window.MCC.commands[this.props.id].value !== 'undefined' ? window.MCC.commands[this.props.id].value.type : ''
	};

	handleChange = event => {
	    this.setState({ [event.target.name]: event.target.value });
	};

	/*Emit command object*/
	sendCommand = (key, value , team , confirmed) => {

	    let cmdObj = {
	      key: key,
	      team: team,
	      value: value,
	      confirmed: confirmed,
	      mcc_id: Date.now(),
	      id: this.props.id
	    }

	    if (cmdObj.value !== '') {
		  socket.emit('command', cmdObj);
		  window.MCC.dispatchEvent('nav_bar', 'setOverlay');
	    }
	}

	pointTypeCheck = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
			  return (
				<div className='custom-ui' style={{}}>
				  <h1>Please add Point Type!</h1>
				</div>
			  );
			}
		  });
	};

	render () {
	const {t} = this.props;
    return ( 
    	<div className='wrapper' style={styles.wrapper}>
				<NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
	    		<div style={styles.info_text} >
					<p>{t('command -')}</p>
				</div>

				<div>
					<p style={Object.assign({textTransform: 'uppercase' , textAlign: 'center' , fontSize: '1.3em' , fontWeight: 'bold'})} >
					{this.props.timestamp} #{this.props.id} {t(this.props.team_text)} {t(this.props.command_name)} {this.props.command_type ? t(this.props.command_type) : ''}</p>
				</div>
				
				<div style={styles.flex}>
					<div style={styles.divs}>

				<form className="root" autoComplete="off" style={styles.root}>
            		<FormControl id="select_team" className="formControl" style={styles.formControl}>
              			<InputLabel htmlFor="comment-simple">*Team</InputLabel>
		              	<Select 
							value={this.state.team_change}
							disabled={window.MCC.platform === 'mobile_callcenter' && window.MCC.commands[this.props.id].status_id !== window.MCC.game_status.status_id ? true : false}
							onChange={this.handleChange}
							inputProps={{
								name: 'team_change'
							}}
						>
							<MenuItem disabled value="" classes={{root: classes.root}}>
								<em>{t('none')}</em>
							</MenuItem>
							<MenuItem value="1" classes={{root: classes.root}}>{t('home')}</MenuItem>
							<MenuItem value="2" classes={{root: classes.root}}>{t('away')}</MenuItem>
		              </Select>
            		</FormControl>
		        </form>
			
				<form className="root" autoComplete="off" style={styles.root}>
	            	<FormControl id="select_point_type" className="formControl" style={styles.formControl}>
		              	<InputLabel htmlFor="comment-simple">*Point Type</InputLabel>
		              	<Select 
						  	value={this.state.point_type}
							onChange={this.handleChange}
							inputProps={{
								name: 'point_type'
							}}
						>
		                	<MenuItem disabled value="" classes={{root: classes.root}}>
		                	  <em>{t('none')}</em>
		                	</MenuItem>
	                		<MenuItem value="block" classes={{root: classes.root}}>{t('block')}</MenuItem>
	                		<MenuItem value="kill" classes={{root: classes.root}}>{t('kill')}</MenuItem>
	                		<MenuItem value="out" classes={{root: classes.root}}>{t('out')}</MenuItem>
	                		<MenuItem value="block_out" classes={{root: classes.root}}>{t('block out')}</MenuItem>
	                		<MenuItem value="ace" classes={{root: classes.root}}>{t('ace')}</MenuItem>
	                		<MenuItem value="serve_error" classes={{root: classes.root}}>{t('serve error')}</MenuItem>
	                		<MenuItem value="error" classes={{root: classes.root}}>{t('error')}</MenuItem>
							<MenuItem value="penalty" classes={{root: classes.root}}>{t('penalty')}</MenuItem>
		              	</Select>
	            	</FormControl>
          		</form>
				</div>

					<div style={styles.confirm_back_field}>
						<Button 
							id="confirm"
							variant="contained" 
							style={Object.assign({background: '#4CAF50'}, styles.buttons , styles.point)}
							onClick = {() => {
									if (this.state.point_type && this.state.point_type !== '') {
		                    			this.sendCommand('point', {"type": this.state.point_type} ,this.state.team_change * 1 , 1)
									}
									else {	
										// Alert pop up will be triggered to inform SR - SRT-975
										this.pointTypeCheck();
										
									}
								//special check for not showing the main page when you edit point in inactive status	
							}}
						>
							{t('confirm')}
						</Button>

						<Button 
							id="back"
							variant="contained" 
							style={Object.assign({background: '#0084ff'}, styles.buttons , styles.point)}
							onClick = {() => {
								window.MCC.return_to_main_state ? 
								this.props.setPage('MainState') 
								: 
								this.props.setPage('CommandLogState', {id: window.MCC.back_id ? window.MCC.back_id : (window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.id : this.props.id) , 
																	   team: window.MCC.back_team ? window.MCC.back_team : (window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.team : this.props.team) });
								
								window.MCC.is_command_log_delete = false;
							}}
						>
							{t('back')}
						</Button>	
					</div>	
				</div>		
    	</div>
    );
	}
}	

const styles = {
	wrapper: {
		position: 'absolute',
	    height: '100%',
	    width: '100%',
	    display : 'flex',
	    flexDirection: 'column',
	},
	nav_bar: {
	    height : '4%',
	    width : '100%',
	    background: 'linear-gradient(#00FF00, #8d6e63)',
	    textAlign : 'center' ,
	},
	point : {
	    color: 'white',
	    fontWeight : 'bold',
	    fontSize: '1.3em',
	},
	divs: {
		width: '100%',
		borderRadius: '15px',
		margin: 3,
	},
	buttons: {
		flexGrow: 1,
		width: '100%',
		borderRadius: '15px',
		margin: 3,
	},
	confirm_back_field: {
	    display : 'flex',
	    paddingTop: '10%',
	    height: '40%',
	    width: '70%',
	    flexDirection: 'column',
	    alignItems: 'center',
  },
	flex : {
		display : 'flex',
		flexDirection: 'column',
		height: '50%',
		alignItems: 'center',
	},
	root: {
    	width: '100%',
    	justifyContent: 'space-around',
    	display: 'flex',
  },
   	formControl: {
    	margin: 2,
    	width: '90%',
		maxWidth: 500,
		textTransform: 'capitalize'
  },
  	info_text: {
		textAlign: 'center', 
		fontSize: '1em', 
		fontWeight: 'bold',
		textTransform: 'capitalize'
	  }
}	


const overrideStyles = {
	root: {
	  textTransform: 'capitalize' 
	} 
  }
  
  EditConfirmState.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
  export default withTranslation()(withStyles(overrideStyles)(EditConfirmState));