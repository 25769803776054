import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import BottomRow from '../BottomRow';
import { socket } from '../../App';
import NavBar from '../NavBar';
import CallMe from '../CallMe';
import VerificationProgress from './VerificationProgress';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

let classes={};

class VerTwoState extends React.Component {

  constructor(props) {
    super(props);
    classes = Object.assign(props.classes);
  }

  state = {
    mobile_confirm  : window.MCC.event_info && window.MCC.event_info.stadium_reporter ? window.MCC.event_info.stadium_reporter.phone[0] : '',
    mobile2_confirm : window.MCC.event_info && window.MCC.event_info.stadium_reporter ? window.MCC.event_info.stadium_reporter.phone[1] : '',
    connection_type : window.MCC.report.connection_type ? window.MCC.report.connection_type.value : '',
    network_provider: window.MCC.report.network_provider ? window.MCC.report.network_provider.value : '',
    inside_stadium    : window.MCC.report.inside_stadium ? window.MCC.report.inside_stadium.value : '',
    confirm     : (window.MCC.report.connection_type && 
                   window.MCC.report.network_provider && window.MCC.report.network_provider.value !== '' &&
                   window.MCC.report.inside_stadium && window.MCC.report.inside_stadium.value === 'yes') ? true : false
  };

  componentDidMount = () => {
    window.MCC.prematchPage = 'VerTwoState';
  }   

  handleChange = event => {
    let confirm = true;
    
    socket.emit('report', {[event.target.name]: event.target.value});

    if (event.target.value.replace(/\s+/g, '')  !== ''){
      for (var prop in this.state){
        if (prop !== event.target.name){
          if (prop === 'connection_type' && this.state[prop] === ''){
            confirm = false;
            break;
          }         
          if (prop === 'network_provider' && this.state[prop].replace(/\s+/g, '') === ''){
            confirm = false;
            break;
          }
          if (prop === 'inside_stadium' && this.state[prop] !== 'yes'){
            confirm = false;
            break;
          }
        }
        // If Inside venue == NO to make CONFIRM inactive
        else{
          if (prop === 'inside_stadium' && event.target.value !== 'yes') {
            confirm = false;
          }
        }
      }
    }else{
      confirm = false;
    }
    
    this.setState({ [event.target.name]: event.target.value, confirm: confirm });
  };


  render() {
    const {t} = this.props;

    return (
      <div className="dropdowns" style={styles.flex_column}>
        <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
        <div className="info_box" style={styles.info_box}>
        <VerificationProgress progress={25} data-test="ver_two_progress"/>
            <p data-testid="verification_step_2" style={{fontWeight : 'bold' , margin: '1%', textTransform: 'capitalize'}}>{t('verification 2 - 40 min.')}</p>
        </div>

        <div className="mobile_confirm" style={styles.mobile_confirm}>
          <TextField 
              disabled
              id="mobile_confirm"
              label={'*' + t('1st mobile number')}
              style={styles.textField}
              value={this.state.mobile_confirm}
              onChange={this.handleChange}
              inputProps={{
                name: 'mobile_confirm'
              }}
          />

        </div>

        <div className="mobile2_confirm" style={styles.mobile_confirm}>
          <TextField 
              disabled
              id="mobile2_confirm"
              label={'*' + t('2nd mobile number')}
              style={styles.textField}
              value={this.state.mobile2_confirm}
              onChange={this.handleChange}
              inputProps={{
                name: 'mobile2_confirm'
              }}
          />

        </div>

        <div className="connection_type" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_connection_type" className="formControl" style={styles.formControl}>
              <InputLabel data-testid="type_of_connection" htmlFor="type-comment-simple">*{t('type of connection')}</InputLabel>

                <Select
                    id="type-comment-simple"
                    value={this.state.connection_type}
                    onChange={this.handleChange} 
                    inputProps={{
                      name: 'connection_type'
                    }}
                >
                    <MenuItem disabled classes={{root: classes.root}}>{t('please select')}</MenuItem>
                    <MenuItem value="2G" classes={{root: classes.root}}>{t('2g')}</MenuItem>
                    <MenuItem value="3G" classes={{root: classes.root}}>{t('3g')}</MenuItem>
                    <MenuItem value="4G" classes={{root: classes.root}}>{t('4g/LTE')}</MenuItem>
                    <MenuItem value="Wi:Fi at the Venue" classes={{root: classes.root}}>{t('WiFi- Venue')}</MenuItem>
                    <MenuItem value="MI:FI device" classes={{root: classes.root}}>{t('MiFi- Device')}</MenuItem>
                    <MenuItem value="Other" classes={{root: classes.root}}>{t('other')}</MenuItem>
                    <MenuItem value="Ethernet Adapter" classes={{root: classes.root}}>{t('ethernet adapter')}</MenuItem>
                </Select>
            </FormControl>
          </form>
        </div>

        <div className="network_provider" style={styles.flex}>
          <TextField
              id="network_provider"
              label={'*' + t('network provider')}
              style={styles.textField}
              value={this.state.network_provider}
              onChange={this.handleChange}
              inputProps={{
                name: 'network_provider'
              }}
          />

        </div>

        <div className="inside_stadium" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_inside_stadium" className="formControl" style={styles.formControl}>
              <InputLabel data-testid="inside_stadium" htmlFor="venue-comment-simple">*{t('inside venue')}</InputLabel>

                <Select
                    id="venue-comment-simple"
                    value={this.state.inside_stadium}
                    onChange={this.handleChange} 
                    inputProps={{
                      name: 'inside_stadium'
                    }}
                >
                    <MenuItem disabled classes={{root: classes.root}}>{t('please select')}</MenuItem>
                    <MenuItem value="yes" classes={{root: classes.root}}>{t('yes')}</MenuItem>
                    <MenuItem value="no" classes={{root: classes.root}}>{t('no')}</MenuItem>
                </Select>

            </FormControl>
          </form>
        </div>
  
        <div className="confirm_callme_field" style={styles.confirm_callme_field}>
          <CallMe showButton/>

          <Button 
              id="confirm"
              variant="outlined" 
              data-testid="confirm_button"
              disabled={typeof this.state !== 'undefined' && typeof this.state.confirm !== 'undefined' ? !this.state.confirm : true} 
              color="secondary" 
              style={this.state && this.state.confirm ? (this.state.confirm ? styles.confirm : styles.confirm_disabled) : styles.confirm_disabled}
              onClick = {() => {
                  socket.emit('report', {'prematch_step2_confirmed' : 'yes'});
                  window.MCC.sendVerification('steps');
                  this.props.setPage('VerThreeState', {id: this.props.id});
              }}
          >
              {t('confirm')}
          </Button>        

        </div>
        <BottomRow
          setPage={this.props.setPage} 
          left_arrow="none" 
          right_arrow="CommandLogState"
        />
      </div>
    );
  }
}

/*CSS Styles*/

const styles = {
  formControl: {
    //margin: 2,
    width: '60%',
    textTransform: 'capitalize'
  },
  root: {
    width: '100%',
    justifyContent: 'space-around',
    display: 'flex',
  },
  textField: {
    //margin: '1%',
    width: '60%',
    textTransform: 'capitalize'
  },
  flex : {
    display : 'flex',
    justifyContent: 'center',
    //height: '8%',
  },
  mobile_confirm : {
    display : 'flex',
    justifyContent: 'center',
    height: '9%',
  },
  confirm_callme_field: {
    display : 'flex',
    marginTop: '3%',
    height: '16%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flex_column:{
    display : 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  row: {
    display : 'flex',
    width: '100%',
    position:'absolute',
    bottom:0,
  },
  info_box: {
    width : '100%',
    height: '9%',
    textAlign: 'center',
    paddingTop: '0.5%',
  },
  confirm: {
    margin: 2,
    height: 60,
    width: '65%',
    color: 'green',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    borderRadius: 25,
  },
  confirm_disabled: {
    margin: 2,
    height: 60,
    width: '65%',
    color: 'grey',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    borderRadius: 25,
  }, 
}

const overrideStyles = {
  root: {
    textTransform: 'capitalize' 
  } 
}

VerTwoState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(overrideStyles)(VerTwoState));