import React from 'react';
import { socket } from '../../App';
import Button from '@material-ui/core/Button';
import NavBar from '../NavBar';
import { withTranslation } from 'react-i18next';

class DeleteConfirmState extends React.Component {

	state = {
	  	command_name      : window.MCC.command_log[window.MCC.commands[this.props.id].key].text,
	  	command_type      : window.MCC.commands[this.props.id].value && window.MCC.commands[this.props.id].value.type ? window.MCC.commands[this.props.id].value.type : '',
	  	command_id        : window.MCC.commands[this.props.id].id,
	  	command_team      : window.MCC.commands[this.props.id].team === 1 ? ' [H] ' : (window.MCC.commands[this.props.id].team === 2 ? ' [A] ' : ' '),
  		timestamp         : this.props.timestamp,
  		game_time         : this.props.game_time
  	}

	sendCommand = (key, confirmed) => {

	  let cmdObj = {
	    key: key,
	    team: window.MCC.commands[this.props.id].team,
      	confirmed: confirmed,
	    mcc_id: Date.now()
	  }

    if (typeof window.MCC.commands[this.props.id].id !== 'undefined' && confirmed === -1){
      cmdObj.id = window.MCC.commands[this.props.id].id;
    }

	  socket.emit('command', cmdObj);
	  window.MCC.dispatchEvent('nav_bar', 'setOverlay');
	}

	render () {
		const {t} = this.props;
    return ( 
    	<div className='wrapper' style={styles.wrapper}>
			<NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
	    	<div>
				<p style={Object.assign(styles.info_text)}>{t('are you sure you want to delete - ')}</p>
			</div>

			<div>
				<p style={Object.assign(styles.delete_confirm_text)}>
					{this.state.timestamp} #{this.state.command_id} {t(this.state.command_team)} {t(this.state.command_name)} {t(this.state.command_type)}
				</p> 
			</div>
			
			<div style={styles.flex}>
				<Button 
					id="confirm_delete" 
					variant="contained" 
					style={Object.assign({background: '#df2029'}, styles.divs , styles.point)}
					onClick = {() => this.sendCommand(window.MCC.commands[this.props.id].key, -1)}
				>
					{t('confirm delete')}
				</Button>

				<Button 
					id="back" 
					variant="contained" 
					style={Object.assign({background: '#0084ff'}, styles.divs , styles.point)}
					onClick = {() => {
						this.props.setPage(
							this.props.back_state,
							{
								id 	        	: this.props.id,
								back_state      : this.props.back_back_state,
								team			: this.props.team
								}
							);
					}}
				>
					{t('back')}
				</Button>
			</div>

    	</div>
    );
	}
}

const styles = {
	wrapper: {
		position: 'absolute',
	    height: '100%',
	    width: '100%',
	    display : 'flex',
	    flexDirection: 'column',
	},
	point : {
	    color: 'white',
	    fontWeight : 'bold',
	    fontSize: '1.3em',
	},
	divs: {
		flexGrow: 1,
		height: '20%',
		width: '70%',
		borderRadius: '15px',
		margin: 3,
	},
	flex : {
		display : 'flex',
		flexDirection: 'column',
		height: '20%',
		paddingTop: '25%',
		alignItems: 'center',
	},
	delete_confirm_text : {
		textTransform: 'uppercase' , 
		textAlign: 'center' , 
		fontSize: '1.3em', 
		fontWeight: 'bold',
	},
	info_text: {
		textAlign: 'center', 
		fontSize: '1em', 
		fontWeight: 'bold',
		textTransform: 'capitalize'
	}
}	

export default withTranslation()(DeleteConfirmState);