import 'rc-progress/assets/index.css';
import React from 'react';
import { Line } from 'rc-progress';

class VerificationProgress extends React.Component {

  render() {
    return (
      <div style={window.MCC.platform === 'mobile_callcenter' ? styles.root : styles.invisible}>
        <Line data-test="progress_bar" percent={this.props.progress} strokeWidth="1" trailColor="#ff3300" strokeColor="#0084ff" />
      </div>
    );
  }
}

const styles = {
  root: {
    width: '90%',
    marginLeft: '5%',
  },
  invisible: {
  	display: 'none',
  },
  button: {
    marginRight: '3px',
  },
  instructions: {
    marginTop: '3px',
    marginBottom: '3px',
  },
};


export default VerificationProgress;
