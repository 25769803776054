import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_lng from "./translations/en.json";
import cn_lng from "./translations/cn.json";

const pathname = window.location.pathname.split( '/' );

const resources = {
    en: en_lng,
    cn: cn_lng
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: pathname[1] !== '' ? pathname[1] : 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;