import React from 'react';
import { socket } from '../App';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';

class MessageRow extends React.Component {

  state = {
    open: false,
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  sendMessage = (ref_id, confirmed) => {

    let result = typeof window.MCC.stats.running.points !== 'undefined' ? window.MCC.stats.running.points : [0,0];

    let msgObj = {
        key: 'message',
        type: 'answer',
          ref_id: ref_id,
          // Functionality in MT3 is made like this  (it is +1 as other sports start from 1 , not from 0) 
          number: window.MCC.message_settings.answer_number[ref_id] + 1,
          result: result[0] + ' - ' + result[1],
          confirmed: confirmed
      }

    socket.emit('messages', msgObj);

    if (!confirmed) {
      window.MCC.sendVerification('call_sr');
    }

  }

  transformMsgText = (text, translate) => {
    let str = text;
    const pathname = window.location.pathname.split( '/' ); 
    pathname[1] = pathname[1] !== '' ? pathname[1] : 'en';
    
    if (pathname[1] === 'cn') {
      if (text.startsWith('Please delete ')){
        let str1 = str.replace(/(.*Please delete\s+)(.*)(\s+Point.*)/, '$2');
        str = str.match(/\d+/);
        return translate('Please delete ') + ' ' + str + ' ' + translate(str1) + ' ' + translate('Point at ID');
      } else if (text.startsWith('Can you confirm that the current score is HOME')){
        str = str.replace('Can you confirm that the current score is HOME','');
        str = str.replace('AWAY?','');
        return translate('Can you confirm that the current score is HOME') + str + translate('AWAY?');
      } else if (text.startsWith('Can you confirm that we have correct sequence of the last')){
        str = str.replace('Can you confirm that we have correct sequence of the last','');
        str = str.replace('Points?','');
        return translate('Can you confirm that we have correct sequence of the last') + str + translate('Points?');
      } else if (text.startsWith('Can you confirm that we have correct sequence of the points')){
        str = str.replace('Can you confirm that we have correct sequence of the points','');
        str = str.replace('in SET','');
        str = str.replace('?','');
        return translate('Can you confirm that we have correct sequence of the points') + translate(str) + translate('in SET');		
      } else if (text.startsWith('Is the start of the match going to be delayed with')){
        str = str.replace('Is the start of the match going to be delayed with','');
        str = str.replace('minutes?','');
        return translate('Is the start of the match going to be delayed with') + str + translate('minutes?');
      } else if (text.startsWith('Please update a POINT for the')) {
        str = str.replace('Please update a POINT for the','');
        str = str.replace('team.','');
        str = str.trim();
        return translate('Please update a POINT for the') + ' ' + translate(str) + ' ' + translate('team.');
      } else if (text.startsWith('Can you confirm that SET')){
        let str1 = str.replace(/(.*Can you confirm that SET\s+)(.*)(\s+score Home.*)/, '$2');
        str = str.replace(/(.*score Home\s+)(.*)(\s+Away?.*)/, '$2');
        return translate('Can you confirm that SET') + ' ' + str1 + ' ' + translate('score Home') + ' ' + str + ' ' + translate('Away?');
      } else{	
        return translate(text);
      }
    } else {
      return text;
    }
  };  

  render() {    
    const message      = window.MCC.messages[this.props.id-1],
          message_type = window.MCC.messages[message.id-1].type;

    const {t} = this.props;
    const message_text = this.transformMsgText(window.MCC.messages[message.id-1].text, t);   
    return(
      <ListItem className="overlay_messaging_field" key={message.id} 
                button style={Object.assign({background: 'linear-gradient(' + this.props.background + ')'},styles.background)}  
                onClick = {() => {
                  if (window.MCC.platform === 'mobile_callcenter'){
                    this.handleClick();
                  }
                }}>

        <div className="id_and_message" style={styles.id_and_message}>     
          <ListItemText
            disableTypography
            primary={'#' + this.props.message_number+ ' ' + message_text}
            id={message.id} 
            style={styles[this.props.logtype + 'text']}
          />

          <div>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </div>
        </div>

        <Collapse className="confirm_unsure_field" in={this.state.open} timeout="auto" unmountOnExit style={styles.confirm_unsure_field}>

           <List component="div" disablePadding>
            <Button 
                id="message_confirm"
                variant="contained" 
                color="primary" 
                style={styles.buttons}
                onClick = {() => {
                          this.sendMessage(message.id, 1);
                      }}
            >  
                {t('confirm')}
            </Button>

            <Button 
                id="message_no"
                variant="contained" 
                color="secondary"
                style={Object.assign(message_type === 'informative' ? styles.hidden : styles.buttons)}
                onClick = {() => {
                          this.sendMessage(message.id, 0);
                      }}
            >
                {t('no / unsure')}
            </Button>
          </List>

        </Collapse>
      </ListItem> 
    )
  }
}  

const styles = {
    logline: {
      color: 'white',
      border: '1px solid white',
      borderRadius: "20px",
      height: '10%',
      width: '100%',
    },
    text: {
      fontWeight : 'bold',
      fontSize: '2.2vh',
      fontFamily: 'Rubik',
      //overflow:'hidden',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
    },
    short_logline: {
      color: 'white',
      border: '1px solid white',
      borderRadius: "5px",
      height: '33.3%',
      width: '100%',
    },
    short_text: {
      fontWeight : 'bold',
      fontSize: '2.2vh',
      fontFamily: 'Rubik',
      overflowWrap: 'break-word',
    },
    buttons: {
      marginLeft: '2px',
      borderRadius: 5,
    },
    background : {
      margin: 1,
      borderRadius: "20px",
      //maxWidth: '60%',
      display: 'flex',
      //justifyContent: 'center',
      flexDirection: 'column',
      wordWrap: 'break-word',
      //overflow: 'hidden',
    },
    id_and_message:{
      overflowWrap: 'break-word',
      width: '100%',
      display : 'flex',
      justifyContent: 'flex-start',
      //justifyContent: 'space-between',
    },
    confirm_unsure_field: {
      paddingTop: '1%',
    },
    hidden: {
      display: 'none'
    }
};

export default withTranslation()(MessageRow);
