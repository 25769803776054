import React from 'react';
import Button from '@material-ui/core/Button';
import NavBar from '../NavBar';
import { withTranslation } from 'react-i18next';

class DeleteState extends React.Component {

	state = {
	  	command_name      : window.MCC.command_log[window.MCC.commands[this.props.id].key].text,
	  	command_type      : window.MCC.commands[this.props.id].value && window.MCC.commands[this.props.id].value.type ? window.MCC.commands[this.props.id].value.type : '',
	  	command_id        : window.MCC.commands[this.props.id].id,
	  	command_team      : window.MCC.commands[this.props.id].team === 1 ? ' [H] ' : (window.MCC.commands[this.props.id].team === 2 ? ' [A] ' : ' '),
  		timestamp         : this.props.timestamp,
  		game_time         : this.props.game_time
  	}

	render () {
	const {t} = this.props;
    return ( 
    	<div className='wrapper' style={styles.wrapper}>
			<NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
	    	<div style={Object.assign(styles.delete)} >
				<p>{t('delete command') + ' -'}</p>
			</div>

			<div>
				<p style={Object.assign(styles.delete_text)}>
					{this.state.timestamp} #{this.state.command_id} {t(this.state.command_team)} {t(this.state.command_name)} {t(this.state.command_type)}
				</p> 
			</div>
			
			<div style={styles.flex}>
				<Button 
					id="delete" 
					variant="contained" 
					style={Object.assign({background: '#df2029'}, styles.divs , styles.point)}
					onClick = {() => {
							this.props.setPage(
								'DeleteConfirmState', 
								{
											timestamp : this.props.timestamp,
											game_time : this.props.game_time,
													id : this.props.id,
												team : this.state.command_team,
											back_state : 'DeleteState',
									back_back_state : this.props.back_state 
								}
							);
					}}
				>
					{t('delete')}
				</Button>

				<Button 
					id="back" 
					variant="contained" 
					style={Object.assign({background: '#0084ff'}, styles.divs , styles.point)}
					onClick = {() => {
						window.MCC.is_command_log_delete = false;
						this.props.setPage(
							(this.props.back_state ? this.props.back_state : window.MCC.mainPage),
							{
								id 	  	   		: window.MCC.back_id ? window.MCC.back_id : (window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.id : false),
								team 	   		: window.MCC.back_team ? window.MCC.back_team : (window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.team : false) 
							}
						);
					}}
				>
					{t('back')}
				</Button>
			</div>

    	</div>
    );
	}
}

const styles = {
	wrapper: {
		position: 'absolute',
	    height: '100%',
	    width: '100%',
	    display : 'flex',
	    flexDirection: 'column',
	},
	point : {
	    color: 'white',
	    fontWeight : 'bold',
	    fontSize: 25,
	},
	divs: {
		flexGrow: 1,
		height: '25%',
		width: '70%',
		borderRadius: '15px',
		margin: 3,
	},
	flex : {
		display : 'flex',
		flexDirection: 'column',
		height: '25%',
		paddingTop: '20%',
		alignItems: 'center',
	},
	delete: {
		textAlign: 'center',
		fontSize: 25,
		textTransform: 'capitalize'
	},
	delete_text : {
		textTransform: 'uppercase' , 
		textAlign: 'center' , 
		fontSize: 25,
		fontWeight: 'bold',
	}
}	

export default withTranslation()(DeleteState);