import React from 'react';
import Button from '@material-ui/core/Button';
import { socket } from '../App'
import NavigationIcon from '@material-ui/icons/Navigation';
import List from '@material-ui/core/List';
import {flash} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withTranslation } from 'react-i18next';

/*use transition effect for Dialog boxes*/
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class BottomRow extends React.Component {
  
	constructor(props){
	    super(props);

	    this.state = {
	     	open_game : false,
        quick_delete: false,
	    }
	}

  handleOpen = () => {
    this.setState({ quick_delete: true });
  }

  handleClose = () => {
    this.setState({ quick_delete: false });
  }

  sendCommand = (key, confirmed) => {
    
    let cmdObj = {
      key: key,
      confirmed: confirmed,
      team: window.MCC.commands[window.MCC.short_log_ids[0]].team,
      mcc_id: Date.now(),
    }

    if (typeof window.MCC.commands[window.MCC.short_log_ids[0]].id !== 'undefined' && confirmed === -1){
      cmdObj.id = window.MCC.commands[window.MCC.short_log_ids[0]].id;
    }

    socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
  }
        
	render () {
    let log_name, team_name, command_name;
    
    const {t} = this.props;

    if (window.MCC.commands && window.MCC.last_short_log_command_id) {
      team_name    = window.MCC.commands[window.MCC.last_short_log_command_id].team === 1 ? ' [H] ' : (window.MCC.commands[window.MCC.last_short_log_command_id].team === 2 ? ' [A] ' : ' ');
      //timestamp    = window.MCC.commands[window.MCC.last_short_log_command_id].timestamp ? (new Date(window.MCC.commands[window.MCC.last_short_log_command_id].timestamp).toLocaleTimeString('it-IT', { timeZone: 'UTC' }) + ' #' + window.MCC.commands[window.MCC.last_short_log_command_id].id + team_name) : '';
      command_name = t(window.MCC.commands[window.MCC.last_short_log_command_id].key) + ' ' + (window.MCC.commands[window.MCC.last_short_log_command_id].value ? t(window.MCC.commands[window.MCC.last_short_log_command_id].value.type) : '');
      log_name     = ' #' + window.MCC.commands[window.MCC.last_short_log_command_id].id + t(team_name) + command_name.toUpperCase();
    }

		return (
			<div className="row_7" id="bottom_row" style={((typeof window.MCC.settings === 'object' && window.MCC.settings.callcenter_control === 'mobile' && window.MCC.platform === 'mobile_admin') || typeof window.MCC.settings !== 'object') ? styles.invisible : styles.last_row}>
		        <Button 
                id="left_arrow"
                variant="outlined" 
                color="primary" 
                disabled={((window.MCC.connectionLost && window.MCC.current_nocommand_state === 'NoConnectionState') || this.props.left_arrow === 'none' || (typeof window.MCC.settings === 'object' && window.MCC.settings.callcenter_control === 'desktop' && window.MCC.platform === 'mobile_callcenter' && this.props.left_arrow !== 'NoConnectionState')) ? true : false}
                style={styles.arrows} 
                onClick = {() => {
                  let props = {
                      id : this.props.id,
                    team : this.props.team  
                  };
                  if (this.props.left_arrow === 'NoConnectionState' && typeof window.MCC.report.prematch_stepp_confirmed === 'object'){
                    props.left_arrow = 'none';
                  }              
                  window.MCC.current_nocommand_state =  this.props.left_arrow;
                  this.props.setPage(this.props.left_arrow, props)
                }}>
		            <NavigationIcon style={styles.left_arrow}/> 
		        </Button>
	        	
            <div className="shortlog_field" style={styles.shortlog_field}>
	          		<List className="shortlog" style={this.props.no_short_log ? styles.invisible : styles.shortlog}>
	            		{window.MCC.short_log_array}
	          		</List>
	        	</div>

	        	<Button 
                id="shortlog"
                style={this.props.no_short_log ? styles.invisible : styles.overlay_shortlog}
                onClick = { () => {
                    if (window.MCC.last_short_log_command_id !== '' 
                        && typeof window.MCC.commands[window.MCC.last_short_log_command_id] !== 'undefined' 
                        && (window.MCC.commands[window.MCC.last_short_log_command_id].key in window.MCC.command_log) 
                        && window.MCC.commands[window.MCC.last_short_log_command_id].confirmed !== -1
                        && ((window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'desktop') || (window.MCC.platform === 'mobile_callcenter' && window.MCC.settings.callcenter_control === 'mobile' && !window.MCC.periodEnd)))
                    {
                      this.handleOpen()
                    }
                  }
                }
                >
                <p></p>
	        	</Button>

            <Dialog
              open={this.state.quick_delete}
              onClose={this.handleClose}
              TransitionComponent={Transition}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'capitalize'}} id="alert-dialog-slide-title">{t('delete command') + '?'}</DialogTitle>
              <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {log_name}
              </DialogContentText>
              </DialogContent>
              <DialogActions>
              <Button id="shortlog_back" onClick={this.handleClose} color="primary" >
                {t('back')}
              </Button>
              <Button id="shortlog_delete" onClick={() => {
                    this.sendCommand(window.MCC.commands[window.MCC.short_log_ids[0]].key, -1)
                    this.handleClose()
                  }}
                  color="secondary" >
                {t('delete')}
              </Button>
              </DialogActions>
          </Dialog>

              <StyleRoot style={styles.arrows}>
                <div  className="arrow_container" 
                      style={window.MCC.settings.callcenter_control === 'desktop' && window.MCC.platform === 'mobile_callcenter' ? styles.arrows : (window.MCC.uncf_command_ids.length !== 0 ? styles.reactAnimation  : styles.arrows)}>
                  <Button 
                      id="right_arrow"
                      className="right_arrow" variant="outlined" 
                      color="secondary" 
                      disabled={this.props.right_arrow === 'none' ? true : false}
                      style={styles.button_right_arrow}
                      onClick = {() => {
                          window.MCC.current_nocommand_state =  this.props.right_arrow;
                          this.props.setPage(this.props.right_arrow,  {     id: this.props.id,
                                                                          team: this.props.team
                                                                      }); 
                      }}>      
                    <NavigationIcon style={styles.right_arrow}/> 
                  </Button> 
                </div>
              </StyleRoot>
	        </div>
    	);  
	}
}

const styles = {
  button_right_arrow: {
    width: '100%',
    height: '100%',
  },
	last_row: {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100%',
    height: '14%',
    position:'absolute',
    bottom:0,
  },
  overlay_shortlog :{
	  width: '50%',
    height: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    position:'absolute',
    bottom:0,
    left: '25%',
    margin: 2,
    borderRadius: 3,
    flexGrow: 1,
    zIndex: 999,
  },
  shortlog: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    background: '#f2f2f2',
    border: 1,
  },
  arrows: {
    display: 'flex',
    justifyContent: 'center',
    width: '25%',
    borderRadius: 3,
    fontWeight: 'bold',
    flexGrow: 1,
    background: '#f2f2f2',
  },
  left_arrow: {
    transform: 'rotate(-90deg)',
    fontSize: 40,
  },
  right_arrow: {
    transform: 'rotate(90deg)',
    fontSize: 40,
  },
  shortlog_field: {
    width: '50%',
    borderRadius: 3,
    flexGrow: 1,
    border: '1px solid',
    background: '#f2f2f2',
  },
  invisible: {
    display: 'none'
  },
  reactAnimation: {
    animation: 'infinite 2s',
    animationName: Radium.keyframes(flash, 'flash'),
    display: 'flex',
    justifyContent: 'center',
    width: '25%',
    borderRadius: 3,
    fontWeight: 'bold',
    flexGrow: 1,
    //background: '#FFE4B5',
  } 
}

export default withTranslation()(BottomRow);





