import React from 'react';
import { socket } from './App';
import MessageRow from './components/MessageLogRow';
import CommandRow from './components/CommandLogRow';
import OfflineScoringRow from './components/OfflineScoringRow';

/* declare var MCC; */

window.MCC = {
  
  setInitialState : () => {
    let state = '';

    if (typeof window.MCC.report === 'object'){

      if (typeof window.MCC.report.prematch_step1_confirmed === 'undefined'){
          state = 'VerOneState';
      }

      if (typeof window.MCC.report.prematch_step1_confirmed === 'object' && typeof window.MCC.report.prematch_step2_confirmed === 'undefined'){
          state = 'VerTwoState';
      }

      if (typeof window.MCC.report.prematch_step2_confirmed === 'object' && typeof window.MCC.report.prematch_step3_confirmed === 'undefined'){
          state = 'VerThreeState';
      }

      if (typeof window.MCC.report.prematch_step3_confirmed === 'object' && typeof window.MCC.report.prematch_step4_confirmed === 'undefined'){
          state = 'MatchInfoState';
      }

      if (typeof window.MCC.report.prematch_step4_confirmed === 'object' || window.MCC.platform === 'mobile_admin'){
          state = 'MainState';
      }

      if (typeof window.MCC.report.prematch_stepp2_confirmed === 'object' || window.MCC.platform === 'mobile_admin'){
          state = 'NoConnectionState';
      }      
    }

    return state;
  },

  setVerification : () => {
    window.MCC.verification = {'1': 0, '2': 0, '3': 0, '4': 0, 'p': 0}
    
    if (typeof window.MCC.report === 'object'){
      if (typeof window.MCC.report.prematch_step1_confirmed === 'object'){
          window.MCC.verification['1'] = 1;
      }

      if (typeof window.MCC.report.prematch_step2_confirmed === 'object'){
          window.MCC.verification['2'] = 1;
      }

      if (typeof window.MCC.report.prematch_step3_confirmed === 'object'){
          window.MCC.verification['3'] = 1;
      }

      if (typeof window.MCC.report.prematch_step4_confirmed === 'object'){
          window.MCC.verification['4'] = 1;
      }

      if (typeof window.MCC.report.prematch_stepp2_confirmed === 'object'){
          window.MCC.verification['p'] = 1;
      }      
    }

  },  

  sendVerification : changed => {
    setTimeout(() => {
      let cmdObj = {
        steps: {
            1: window.MCC.verification['1'],
            2: window.MCC.verification['2'],
            3: window.MCC.verification['3'],
            4: window.MCC.verification['4'],
            p: window.MCC.verification['p']
        },
        call_sr: changed === 'call_sr' ? true : false,
        mobile_connection: window.MCC.verification.mobile_connection,
        changed: changed,
        timestamp: Date.now()
      };
    
      socket.emit('verification', cmdObj);
    }, 200);
  },

  getState : () => {
    if (window.MCC.current_state !== window.MCC.current_nocommand_state){
      return window.MCC.current_nocommand_state;
    }
    
    return  window.MCC.current_state;
  },

  commandUpdate : (command, setPage, refresh) => {
    if (typeof command !== 'object'){
      return;
    }
    if (!window.MCC.skip_log_commands.includes(command.key) && !window.MCC.skip_short_log_commands.includes(command.key)) {

      let speed = !refresh ? (Date.now() - command.mcc_id)/2 : 0;

      let index = window.MCC.short_log_ids.indexOf(command.id);
      
      if (index !== -1) {
        window.MCC.short_log_array[index] = <CommandRow setPage={setPage}  key={command.id} id={command.id} logtype="short_" speed={speed}/>;
      } else if (window.MCC.short_log_ids.length === 0 || (window.MCC.short_log_ids.length > 0 && command.id > window.MCC.short_log_ids[0])){

        window.MCC.short_log_ids.unshift(command.id);

        window.MCC.short_log_array.unshift(
          <CommandRow setPage={setPage} key={command.id} id={command.id} logtype="short_" speed={speed}/>
        );

        if (window.MCC.short_log_array.length > 3){
          window.MCC.short_log_array.pop();
        }

        if (window.MCC.short_log_ids.length > 3) {
          if (window.MCC.commands[window.MCC.short_log_ids[window.MCC.short_log_ids.length-1]].confirmed === 0) {
            window.MCC.uncf_command_ids.push(window.MCC.short_log_ids[window.MCC.short_log_ids.length-1]);
          }
          window.MCC.short_log_ids.pop();
        }
      }
    }

    if (command.key === 'adjust_score_up'){
      window.MCC.score['status_' + command.status_id][command.team-1]++;  
    }

    if (command.key === 'adjust_score_down'){
      window.MCC.score['status_' + command.status_id][command.team-1]--;  
    }      

    if (command.key === 'point' && window.MCC.active_statuses.includes(command.status_id)){
      /* handle log score changes */
      if (command.confirmed === 1){
        window.MCC.score['status_' + command.status_id][command.team-1]++;
      }
      if ((command.confirmed === -1 && !refresh) || (command.confirmed === 1 && !refresh && command.modified_at)){
        for (let i = 0; i < window.MCC.active_statuses.length; i++){
          window.MCC.score['status_' + window.MCC.active_statuses[i]] = [0,0];
        } 
        for (let prop in window.MCC.commands){
        	
        	if ((window.MCC.commands[prop].key === 'point' && 
              window.MCC.commands[prop].confirmed === 1) ||
              window.MCC.commands[prop].key === 'adjust_score_up'
              ){
            window.MCC.score['status_' + window.MCC.commands[prop].status_id][window.MCC.commands[prop].team-1]++;
          }
          if (window.MCC.commands[prop].key === 'adjust_score_down'){
            window.MCC.score['status_' + window.MCC.commands[prop].status_id][window.MCC.commands[prop].team-1]--;
          }
          let score = window.MCC.score['status_' + window.MCC.commands[prop].status_id] ? window.MCC.score['status_' + window.MCC.commands[prop].status_id].slice(0) : ['',''];
          window.MCC.command_log_array[window.MCC.command_log_ids.indexOf(window.MCC.commands[prop].id)] = {id:window.MCC.commands[prop].id, logtype:"", score_home:score[0], score_away:score[1]}
        }
      }
    }

    if (!(command.confirmed === 1 && !refresh && command.modified_at)){
      if (!(window.MCC.skip_log_commands.includes(command.key) && window.MCC.platform === 'mobile_callcenter')){

        let index = window.MCC.command_log_ids.indexOf(command.id);
        let score = window.MCC.score['status_' + command.status_id] ? window.MCC.score['status_' + command.status_id].slice(0) : ['',''];
        
        if (index === -1) {
          window.MCC.command_log_array.unshift(
            {id:command.id, logtype:"", score_home:score[0], score_away:score[1]}
          );
          window.MCC.command_log_ids.unshift(command.id);
        }else{
          window.MCC.command_log_array[index] = {id:command.id, logtype:"", score_home:score[0], score_away:score[1]}
        }
      }
    }
    
    if (!window.MCC.skip_log_commands.includes(command.key) && command.key !== 'adjust_score_up' && command.key !== 'adjust_score_down'){
      if (typeof command.deleted_at === 'undefined' && typeof command.confirmed_at === 'undefined' && typeof command.modified_at === 'undefined'){
          window.MCC.current_command_id = command.id;
          window.MCC.current_command_team = command.team;
      }
      window.MCC.current_state_props = {
        disabled : false, 
        id       : window.MCC.current_command_id, 
        team     : window.MCC.current_command_team
      };
    }
    
    window.MCC.last_short_log_command_id = typeof window.MCC.short_log_ids[0] !== 'undefined' ? window.MCC.short_log_ids[0] : ''; 
    
    if (command.confirmed !== 0 && window.MCC.uncf_command_ids.includes(command.id)) {
      let index = window.MCC.uncf_command_ids.indexOf(command.id);
      window.MCC.uncf_command_ids.splice(index , 1);
    }

    switch (command.key){
      case 'attendance':
      case 'players':
        window.MCC.match_info[command.key] = command.value;
        window.MCC.current_state = 'MatchInfoState';
        break;  
      case 'shirt_color_1':
        window.MCC.match_info[(command.team === 1 ? 'home' : 'away') + '_color_1'] = command.value;
        window.MCC.current_state = 'MatchInfoState';
        break;  
      case 'shirt_color_2':
        window.MCC.match_info[(command.team === 1 ? 'home' : 'away') + '_color_2'] = command.value; 
        window.MCC.current_state = 'MatchInfoState';
        break;
      case 'timeout':
        if (typeof command.value !== 'object' && command.confirmed === 1){
          window.MCC.current_state = 'TimeoutState';
          window.MCC.back_key = 'timeout';
          window.MCC.back_id = command.id;
          window.MCC.back_team = command.team;
        }
        if ((typeof command.value === 'object' && command.confirmed === 1) || command.confirmed === -1){
          window.MCC.current_state = 'MainState';
          if (command.id === window.MCC.back_id){
            window.MCC.back_key = false;
            window.MCC.back_id = false;
            window.MCC.back_team = false;
          }
        }
        break;
      case 'misconduct':
        if (command.confirmed === 0){
          window.MCC.current_state = 'MisconductTypeState';
          window.MCC.point_state = 'MisconductTypeState';
          window.MCC.back_key = 'misconduct';
          window.MCC.back_id = command.id;
          window.MCC.back_team = command.team;
        }else{
          window.MCC.current_state = 'MainState';
          window.MCC.point_state = 'MainState';
          if (command.id === window.MCC.back_id){
            window.MCC.back_key = false;
            window.MCC.back_id = false;
            window.MCC.back_team = false;
          }
        }
        break;
      case 'subst':
        if (command.confirmed === 0){
          window.MCC.current_state = 'ConfirmState';
          window.MCC.point_state = 'ConfirmState';
          // We set this type as we check in ConfirmState if we need to show futute score or not!
          window.MCC.back_key = 'subst';
          window.MCC.back_id = command.id;
          window.MCC.back_team = command.team;
        }else{
          window.MCC.current_state = 'MainState';
          window.MCC.point_state = 'MainState';
          window.MCC.current_state_props.id = window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.id : window.MCC.current_state_props.id;
          if (command.id === window.MCC.back_id){
            window.MCC.back_key = false;
            window.MCC.back_id = false;
            window.MCC.back_team = false;
          }
        }
        break;  
      case 'point':
        window.MCC.periodEnd = (window.MCC.checkForPeriodEnd(command.team , command.status_id, refresh));
        //state after click home/away point button
        if (command.confirmed === 0){
          if (typeof command.value !== 'object'){  
            window.MCC.current_state = 'PointTypeState';
            window.MCC.point_state = 'PointTypeState';
            window.MCC.current_state_props.confirm = 'disabled';
          }else{
            window.MCC.current_state = 'MainState';
            window.MCC.point_state = 'MainState';
            window.MCC.serve_home = 'disabled';
            window.MCC.serve_away = 'disabled';
            window.MCC.current_state_props.point = 'disabled';
            window.MCC.current_state_props.confirm = 'enabled';

            if (command.team === 1) {
              window.MCC.challenge_away = 'enabled';
            } else if (command.team === 2) {
              window.MCC.challenge_home = 'enabled';
            }
          }
          window.MCC.last_unconfirmed_point = Object.assign(command);
        }else{
          if (window.MCC.last_unconfirmed_point && window.MCC.last_unconfirmed_point.id === command.id){
            window.MCC.last_unconfirmed_point = false;
            window.MCC.challenge_home = '';
            window.MCC.challenge_away = '';
          }
          
          if (command.confirmed === -1){
          	if (window.MCC.periodEnd) {
          		window.MCC.current_state = 'EndOfPeriodState';
          	} else {
              window.MCC.current_state = 'MainState';

              /* This is set as when we are in ConfirmState and detele the point from the EDIT LOG , we need to change the page we will be redirect with left arrow ( to close the Confirm POP up) - SRT-698 */
              window.MCC.mainPage = 'MainState';          
	            window.MCC.serve_home = 'enabled';
              window.MCC.serve_away = 'enabled';
              if (window.MCC.back_key === 'subst'){
                window.MCC.mainPage = 'ConfirmState';
              }
              if (window.MCC.back_key === 'misconduct'){
                window.MCC.mainPage = 'MisconductTypeState';
              }
              if (window.MCC.back_key === 'timeout'){
                window.MCC.mainPage = 'TimeoutState';
              }
              if (!window.MCC.back_key && window.MCC.last_unconfirmed_point && command.id !== window.MCC.last_unconfirmed_point.id){
                if (!window.MCC.last_unconfirmed_point.value){
                  window.MCC.mainPage = 'PointTypeState';
                }else{
                  window.MCC.mainPage = 'MainState';
                }
              }
          	}
          }

          if (command.confirmed === 1){
            if (!window.MCC.active_statuses.includes(window.MCC.game_status.status_id) || window.MCC.periodEnd) {
              window.MCC.is_command_log_delete = false;
            }
            if (window.MCC.periodEnd && window.MCC.game_status.status_id === undefined){
              window.MCC.current_state = 'EndOfPeriodState';  
              window.MCC.mainPage = 'EndOfPeriodState';
            }else{
              window.MCC.current_state = window.MCC.current_state === 'StandByState' ? 'StandByState' : 'MainState';

              /* This is set as when we are in ConfirmState and confirm the point from the EDIT LOG , we need to change the page we will be redirect with left arrow ( to close the Confirm POP up) - SRT-698 */
              window.MCC.mainPage = window.MCC.mainPage === 'StandByState' ? 'StandByState' : 'MainState';
              if (window.MCC.back_key === 'subst'){
                window.MCC.mainPage = 'ConfirmState';
              }
              if (window.MCC.back_key === 'misconduct'){
                window.MCC.mainPage = 'MisconductTypeState';
              }
              if (window.MCC.back_key === 'timeout'){
                window.MCC.mainPage = 'TimeoutState';
              }
              if (!window.MCC.back_key && window.MCC.last_unconfirmed_point && command.id !== window.MCC.last_unconfirmed_point.id){
                if (!window.MCC.last_unconfirmed_point.value){
                  window.MCC.mainPage = 'PointTypeState';
                }else{
                  window.MCC.mainPage = 'MainState';
                }
              }
            }
            if (command.team === 1){
              window.MCC.serve_away = 'disabled';
              window.MCC.serve_home = '';
            }else{
              window.MCC.serve_home = 'disabled';
              window.MCC.serve_away = ''; 
            }
          }
        }                  
        break;        
      case 'serve':
        window.MCC.current_state = 'MainState';
        if (command.confirmed === -1){
          window.MCC.serve_home = 'enabled';
          window.MCC.serve_away = 'enabled';
        }else{
          window.MCC.serve_home = 'disabled';
          window.MCC.serve_away = 'disabled';
        }
        break;
      case 'challenge':
        if (window.MCC.last_unconfirmed_point) {
          if (command.confirmed === 1){
            window.MCC.current_state = 'ChallengeTypeState';
            window.MCC.point_state = 'ChallengeTypeState';
          } else {
            window.MCC.current_state = 'MainState';
            window.MCC.point_state = 'MainState';
            window.MCC.serve_home = 'disabled';
            window.MCC.serve_away = 'disabled';
            window.MCC.current_state_props.point = 'disabled';
            window.MCC.current_state_props.confirm = 'enabled';
          }
        }
        break;
      case 'challenge_won':
        if (command.confirmed === 1 && window.MCC.last_unconfirmed_point) {
          window.MCC.current_state = 'EditConfirmState';
          window.MCC.return_to_main_state = true;

          let current_command = window.MCC.last_unconfirmed_point;

          window.MCC.current_state_props.id = current_command.id ? current_command.id : window.MCC.current_state_props.id;
          window.MCC.current_state_props.time = current_command.timestamp ? new Date(current_command.timestamp).toLocaleTimeString() : '';
          window.MCC.current_state_props.command_name = current_command.key;
          window.MCC.current_state_props.game_time = current_command.game_time ? current_command.game_time : '[0:00]';
          window.MCC.current_state_props.team_text = current_command.team === 1 ? ' [H] ' : (current_command.team === 2 ? ' [A] ' : ' ');
          window.MCC.current_state_props.team = current_command.team === 1 ? 'home' : 'away';
          //since won challenge updated, this should automatically switch the team who scored the point
          window.MCC.current_state_props.changed_team_value = current_command.team === 1 ? 2 : 1;
        }
        if (command.confirmed === -1){
          window.MCC.current_state = 'MainState';
        }
        break;
      case 'challenge_lost':
        if (command.confirmed === 1 && window.MCC.last_unconfirmed_point){
          window.MCC.current_state = 'MainState';
          window.MCC.point_state = 'MainState';
          window.MCC.serve_home = 'disabled';
          window.MCC.serve_away = 'disabled';
          window.MCC.current_state_props.point = 'disabled';
          window.MCC.current_state_props.confirm = 'enabled';
        }
        break;  
      case 'point_replay':
        if (command.confirmed === 1){
          window.MCC.current_state = 'EditDeleteState';
          window.MCC.point_state = 'MainState';
          window.MCC.return_to_main_state = true;

          if (window.MCC.last_unconfirmed_point) {
            let current_command = window.MCC.last_unconfirmed_point;

            window.MCC.current_state_props.back_state = window.MCC.mainPage;
            window.MCC.current_state_props.id = current_command.id ? current_command.id : window.MCC.current_state_props.id;
            window.MCC.current_state_props.timestamp = current_command.timestamp ? new Date(current_command.timestamp).toLocaleTimeString('it-IT') : '';
            window.MCC.current_state_props.command_name = current_command.key;
            window.MCC.current_state_props.game_time = command.game_time ? ('['+Math.floor(current_command.game_time/60)+':'+(current_command.game_time%60 < 10 ? '0'+current_command.game_time%60 : current_command.game_time%60)+']') : '[0:00]';
            window.MCC.current_state_props.team_text = current_command.team === 1 ? ' [H] ' : (current_command.team === 2 ? ' [A] ' : ' ');
            window.MCC.current_state_props.team = current_command.team === 1 ? 'home' : 'away';
          }
        }
        else{
          window.MCC.current_state = 'MainState';
        } 
        break;
      case 'point_review':
        if (command.confirmed === 1) {
          window.MCC.current_state = 'PointReviewTypeState';
        } else {
          window.MCC.current_state = 'MainState';
        }
        break;  
      case 'decision_changed':
        if (command.confirmed === 1) {
          
          /* If we have uncf point then proceed to EditConfirmState */
          if (window.MCC.last_unconfirmed_point) {
            window.MCC.return_to_main_state = true;
            window.MCC.current_state = 'EditConfirmState';

            let current_command = window.MCC.last_unconfirmed_point;

            window.MCC.current_state_props.id = current_command.id ? current_command.id : window.MCC.current_state_props.id;
            window.MCC.current_state_props.time = current_command.timestamp ? new Date(current_command.timestamp).toLocaleTimeString() : '';
            window.MCC.current_state_props.command_name = current_command.key;
            window.MCC.current_state_props.game_time = current_command.game_time ? current_command.game_time : '[0:00]';
            window.MCC.current_state_props.team_text = current_command.team === 1 ? ' [H] ' : (current_command.team === 2 ? ' [A] ' : ' ');
            window.MCC.current_state_props.team = current_command.team === 1 ? 'home' : 'away';
            //since decision changed updated, this should automatically switch the team who scored the point
            window.MCC.current_state_props.changed_team_value = current_command.team === 1 ? 2 : 1;
          }
          /* If we dont have uncf point then redirect user to MainState */
          else{
            window.MCC.current_state = 'MainState';
          }

        }
        /* If decision_changed command is deleted return to MainState */
        else{
          window.MCC.current_state = 'MainState';
        }
        break;
      case 'point_upheld':
          if (command.confirmed === 1) {
            window.MCC.current_state = 'MainState';
          }
        break;
    
      case 'adjust_score_up':   
      window.MCC.periodEnd = (window.MCC.checkForPeriodEnd(command.team , command.status_id, refresh));
        if (window.MCC.periodEnd) {
          window.MCC.current_state = 'EndOfPeriodState';  
        } else{
          window.MCC.current_state = 'CommentState';
          window.MCC.mainPage = 'MainState';  
        }
        break;  
      
      case 'adjust_score_down':
      window.MCC.periodEnd = (window.MCC.checkForPeriodEnd(command.team , command.status_id, refresh));
        if (window.MCC.periodEnd) {
           window.MCC.current_state = 'EndOfPeriodState';
        } else {
          window.MCC.current_nocommand_state = 'CommentState';
          window.MCC.current_state = 'CommentState';
          window.MCC.mainPage = 'MainState'; 
        }
      break;

      case 'setting':
        if (typeof command.value === 'object' && command.value.key === 'callcenter_control'){
          window.MCC.settings.callcenter_control = command.value.value;
          if (window.MCC.platform === 'mobile_admin'){
            window.MCC.current_nocommand_state = 'CommentState';
          }
          if (window.MCC.platform === 'mobile_callcenter' && command.value.value === 'desktop'){
            window.MCC.current_nocommand_state = 'NoConnectionState';
          }
          if (window.MCC.platform === 'mobile_callcenter' && command.value.value === 'mobile'){
            if (window.MCC.setInitialState() === 'MainState'){
              window.MCC.current_nocommand_state = window.MCC.current_state;
            }else{
              window.MCC.current_admin_state = window.MCC.current_state;
              window.MCC.current_nocommand_state = window.MCC.setInitialState();
              if (window.MCC.game_status.status_id === 6){
                window.MCC.current_nocommand_state = 'VerPostState';
              }
              if (typeof window.MCC.report.prematch_stepp2_confirmed === 'object'){
                window.MCC.current_state = 'NoConnectionState';
                window.MCC.current_state_props.left_arrow = 'none';
              }
            }
          }  
        }
        break;    
      case 'status_ok':
       window.MCC.open_game = {open: true, adminlock: false};
        break;
      case 'status_unsure':
        if (command.value === 'adminlock') {
          window.MCC.open_game.adminlock = true;
        }
        window.MCC.open_game.open = false;
        break;
      case 'comm_ok':
        window.MCC.comm = true;
        break;
      case 'comm_lost':
        window.MCC.comm = false;
        break;
      case 'standby':
        window.MCC.current_state = 'StandByState';
        break;
      case 'serving':
        window.MCC.match_info.standby_kickoff_team = command.team === 1 ? 'Home' : 'Away';
        window.MCC.current_state = 'StandByState';

        /*Used for set the home/away serve button when modify serving command team*/
        if (command.modified_at) {
          /* Show both SERVE buttons (ADMIN & MCC) if we did not have set window.MCC.match_info.standby_kickoff_team value (which can be set from STANDBY POP UP */
          window.MCC.serve_home = window.MCC.match_info.standby_kickoff_team === 'Home' || window.MCC.match_info.standby_kickoff_team === '' ? '' : 'disabled';
          window.MCC.serve_away = window.MCC.match_info.standby_kickoff_team === 'Away' || window.MCC.match_info.standby_kickoff_team === '' ? '' : 'disabled';
        }

        break;
      case 'status':
        window.MCC.current_state = command.value === 6 && command.confirmed === 0 ? 'ConfirmFinishedState' : (window.MCC.endOfPeriod_statuses.includes(command.value) ? (window.MCC.stats.running.setswon[0] < 3 && window.MCC.stats.running.setswon[1] < 3 ? 'StandByState' : 'EndOfPeriodState') : window.MCC.current_nocommand_state === 'CommentState' ? 'CommentState' : (command.value === 6 ? (command.confirmed === 0 ? 'ConfirmFinishedState' : (window.MCC.report.prematch_stepp_confirmed && window.MCC.report.prematch_stepp_confirmed.value === 'yes' ? 'VerConnectionFeedback' : 'VerPostState')) : (command.value === 26 ? 'StandByState' : 'MainState')));
        
        /* Show both SERVE buttons (ADMIN & MCC) if we did not have set window.MCC.match_info.standby_kickoff_team value (which can be set from STANDBY POP UP */
        window.MCC.serve_home = window.MCC.match_info.standby_kickoff_team === 'Home' || window.MCC.match_info.standby_kickoff_team === '' ? '' : 'disabled';
        window.MCC.serve_away = window.MCC.match_info.standby_kickoff_team === 'Away' || window.MCC.match_info.standby_kickoff_team === '' ? '' : 'disabled';
        if (command.value === 28 && command.confirmed === 0){window.MCC.current_state = 'ConfirmFinishedAfterGSState'}
        if (command.value === 28 && command.confirmed === 1 && window.MCC.platform === 'mobile_callcenter' ){window.MCC.current_state = 'VerPostState'}
        if (typeof window.MCC.report.prematch_stepp2_confirmed === 'object' && window.MCC.platform === 'mobile_callcenter' ){
          window.MCC.current_state = 'NoConnectionState';
          window.MCC.current_state_props.left_arrow = 'none';
        }
        if ((command.value === 28 || command.value === 6) && command.confirmed !== 0 && window.MCC.platform === 'mobile_admin'){window.MCC.current_state = 'CommentState';} 
        if (!window.MCC.active_statuses.includes(command.value) && !window.MCC.endOfPeriod_statuses.includes(command.value) && command.value !== 6 && command.value !== 26){
            if (window.MCC.platform === 'mobile_admin'){
              window.MCC.current_state = 'CommentState';
            }else{
              window.MCC.current_state = 'MatchInfoState';
            }
        } 

        if (window.MCC.current_state === 'CommentState' && window.MCC.active_statuses.includes(command.value)){
          window.MCC.mainPage = 'MainState';
        }

        if (window.MCC.endOfPeriod_statuses.includes(command.value)){
          window.MCC.periodEnd = false;
        }
        break;
      default:
        break;
    }

    if (typeof window.MCC.is_command_log_delete !== 'undefined' && window.MCC.is_command_log_delete) {
      if ((command.key !== 'point' && command.key !== 'adjust_score_up' && command.key !== 'adjust_score_down') || (command.key === 'point' && !window.MCC.periodEnd)) {
        window.MCC.is_command_log_delete = false;
        window.MCC.current_state = 'CommandLogState';
        if (!window.MCC.back_key){
          window.MCC.mainPage = 'MainState';
          if (window.MCC.last_unconfirmed_point && !window.MCC.last_unconfirmed_point.value){
            window.MCC.mainPage = 'PointTypeState';
          }
        }
      }
    }

    if (command.key !== 'status_ok' && 
        command.key !== 'status_unsure' && 
        command.key !== 'comm_ok' && 
        command.key !== 'comm_lost' && 
        command.key !== 'setting' &&
        command.key !== 'comment' &&
        typeof window.MCC.current_state !== 'undefined'
    ) {
      window.MCC.current_nocommand_state = window.MCC.current_state;
    }
    if (window.MCC.current_state !== 'StandByState' && window.MCC.current_state !== 'MatchInfoState' && 
        window.MCC.current_state !== 'CommentState' && window.MCC.current_state !== 'CommandLogState' &&
        window.MCC.current_state !== 'EditDeleteState' && window.MCC.current_state !== 'EndOfPeriodState') {
        window.MCC.mainPage = window.MCC.current_state;
    }
    if (window.MCC.platform === 'mobile_callcenter' && window.MCC.settings.callcenter_control === 'desktop') {
      window.MCC.current_nocommand_state = 'NoConnectionState';
    }

    /* After goes through all the commands on Refresh it will check if we need to open EndOfPeriod pop up */
    if (window.MCC.periodEnd) {
      window.MCC.current_state = 'EndOfPeriodState';
    }

    //functionality to save the current score in the localstorage which is used for offline scoring
    if (command.confirmed !== 0 && (command.key === 'point' || command.key === 'adjust_score_down' || command.key === 'adjust_score_up' || command.key === 'status')&& typeof window.MCC.game_status.status_id !== 'undefined') {
      if (typeof window.MCC.score['status_' + window.MCC.game_status.status_id] !== 'undefined') {
        localStorage.setItem('volleyball_current_score', JSON.stringify(window.MCC.score['status_' + window.MCC.game_status.status_id]));
      } else {
        localStorage.setItem('volleyball_current_score', JSON.stringify([0,0]));
      }
    }
  
  },

  messageUpdate : (message, setPage) => {
    if (typeof message !== 'object'){
      return;
    }

    if (message.type !== 'answer') {  
      window.MCC.message_settings.message_counter++;
      window.MCC.message_settings.message_position[message.id] = window.MCC.message_settings.message_counter;
      window.MCC.message_settings.answer_number[message.id] = 0;
      window.MCC.message_log_array.unshift(
       <MessageRow setPage={setPage} key={message.id} id={message.id} background="#90a4ae, #90a4ae" message_number={window.MCC.message_settings.message_position[message.id]} logtype="" />
      );
    } else {
      window.MCC.message_settings.answer_number[message.ref_id]++;

      let background = message.confirmed === 1 ? '#00c853, #0ae063' : '#dd2c00, #dd2c00'; // green and red colors
      window.MCC.message_log_array[window.MCC.message_log_array.length - window.MCC.message_settings.message_position[message.ref_id]] = <MessageRow setPage={setPage} key={message.id} id={message.ref_id} background={background} message_number={window.MCC.message_settings.message_position[message.ref_id]} logtype="" />
    }

    window.MCC.message_settings.unanswered_messages = 0;
    for (let i = 0; i < window.MCC.message_settings.answer_number.length; i++){
      if (window.MCC.message_settings.answer_number[i] === 0){
        window.MCC.message_settings.unanswered_messages++;
      }
    }
    // In verifications window.MCC.getState() == undefined so we need window.MCC.setInitialState(). This is related to receiving messages in Verification steps!
    setPage(window.MCC.getState() || window.MCC.setInitialState(), {...window.MCC.current_state_props});
  },

  checkForPeriodEnd : (team ,status_id, refresh) => {
      if (typeof window.MCC.stats.running.points === 'undefined' || (!refresh && status_id !== window.MCC.game_status.status_id)){
        return false;
      }

      team = team - 1;//current team
      let opposite_team = team === 1 ? 0 : 1;//opposite team

      if ((status_id >= 7 && status_id <= 10) 																		&&
      		(window.MCC.score['status_' + status_id][team] >= 25 || window.MCC.score['status_' + status_id][opposite_team] >= 25) && 
      		Math.abs(window.MCC.score['status_' + status_id][team] - window.MCC.score['status_' + status_id][opposite_team]) >= 2
      	 ) 
      {
      	return true;
      } 
      else if ((status_id === 11 || status_id === 27) 																 &&
      				 (window.MCC.score['status_' + status_id][team] >= 15 || window.MCC.score['status_' + status_id][opposite_team] >= 15) &&
      				 Math.abs(window.MCC.score['status_' + status_id][team] - window.MCC.score['status_' + status_id][opposite_team]) >= 2
      				)
      {
      	return true;
      }
      else {
      	return false;
      }
  },

  dispatchEvent : (id, event) => {
      let element = document.getElementById(id);
      if (element !== null){
        element.dispatchEvent(window.MCC.events[event]);
      } 
  },

  setOfflineScoringData : () => {
      window.MCC.offline_scoring_data = JSON.parse(localStorage.getItem('volleyball_offline_scoring')) || [];
      window.MCC.offline_score = typeof localStorage.getItem('volleyball_current_score') !== 'undefined' && localStorage.getItem('volleyball_current_score') !== null ? JSON.parse(localStorage.getItem('volleyball_current_score')) : [0,0];
      
      if (window.MCC.offline_scoring_data !== null){
        for (let i = 0; i < window.MCC.offline_scoring_data.length; i++) {
          if (window.MCC.offline_scoring_data[i].key === 'POINT'){
            if (window.MCC.offline_scoring_data[i].team === 'HOME'){
              window.MCC.offline_score[0]++;
            }else{
              window.MCC.offline_score[1]++;
            }
            window.MCC.offline_scoring_array[window.MCC.offline_scoring_data.length - i - 1] = <OfflineScoringRow key={i} id={i} point={window.MCC.offline_scoring_data[i].team === 'HOME' ? window.MCC.offline_score[0] : window.MCC.offline_score[1]}/>
          } else {
            window.MCC.offline_scoring_array[window.MCC.offline_scoring_data.length - i - 1] = <OfflineScoringRow key={i} id={i} />
          } 
        } 
      }  
  },

  setMobileStats : data => {
      const now = Date.now();
      const obj = {k : data.key, t : data.team, c : data.confirmed, i : data.id, s : data.status_id};
      const arr = [];
      if (typeof data.game_time !== 'undefined'){
        obj.g = data.game_time;
      }
      if (typeof data.value !== 'undefined'){
        obj.v = data.value;
      }
      arr.push({c : 'c', t : data.mcc_id, o : {k : data.key, t : data.team}});
      arr.push({c : 's', t : now, e : now - data.mcc_id, o : obj});
      socket.emit('mobile_stats', arr);   
  },

  events :  {
    updateStatus    : new Event('updateStatus'),
     updateStats    : new Event('updateStats'),
      setOverlay    : new Event('setOverlay'),
   removeOverlay    : new Event('removeOverlay')
  },

  command_log: {
      point: {color: 'green', text: 'point'},
      serve: {color: 'blue', text: 'serve'},
      challenge: {color: '#333735', text: 'chl'},
      challenge_won: {color: '#333735', text: 'chl won'},
      challenge_lost: {color: '#333735', text: 'chl lost'},
      point_review: {color: '#333735', text: 'point review'},
      point_replay: {color: '#333735', text: 'point replay'},
      point_upheld: {color: '#333735', text: 'point upheld'},
      decision_changed: {color: '#333735', text: 'decision changed'},
      timeout: {color: '#F9A825', text: 'timeout'},
      serving: {color: 'grey', text: 'serving'},
      standby: {color: 'grey', text: 'standby'},
      misconduct: {color: 'red', text: 'misconduct'},
      status_unsure: {color: 'black', text: 'close game'},
      status_ok: {color: 'black', text: 'open game'},
      comm_lost: {color: 'black', text: 'comm lost'},
      comm_ok: {color: 'black', text: 'comm ok'},
      callcenter_lost: {color: 'black', text: 'cc lost'},
      callcenter_ok: {color: 'black', text: 'cc ok'},
      comment: {color: 'orange', text: 'comment'},
      setting: {color: 'orange', text: 'setting'},
      attendance: {color: 'orange', text: 'attendance'},
      players: {color: 'orange', text: 'players'},
      shirt_color_1: {color: 'orange', text: 'shirt col 1'},
      shirt_color_2: {color: 'orange', text: 'shirt col 2'},
      pants_color: {color: 'orange', text: 'pants col'},
      socks_color: {color: 'orange', text: 'socks col'},
      shirt_design: {color: 'orange', text: 'shirt design'},
      neutral_ground: {color: 'orange', text: 'neutral ground'},
      subst: {color: 'grey', text: 'sub'},
      status: {color: 'black', text: 'status'},
      adjust_score_up: {color: 'orange', text: '1 point'},
      adjust_score_down: {color: 'orange', text: '-1 point'}
  },

  skip_log_commands: [
      'status_unsure',
      'status_ok',
      'attendance',
      'players',
      'shirt_color_1',
      'shirt_color_2',
      'comm_lost',
      'comm_ok',
      'callcenter_ok',
      'callcenter_lost',
      'comment',
      'neutral_ground',
      'setting',
      'adjust_score_down',
      'adjust_score_up'
  ],

  skip_short_log_commands: ['status', 'serving'],

  all_edit_commands: ['point'],
  all_only_edit_commands: ['serving'],
  all_delete_commands : [
      'serve',
      'misconduct',
      'forfeit',
      'challenge',
      'challenge_won',
      'challenge_lost',
      'timeout',
      'subst',
      'standby',
      'point_review',
      'point_replay',
      'point_upheld',
      'decision_changed'
  ],

  periodEnd: false,
  open_game   : {open: true, adminlock: false},
  comm        : false,
  return_to_main_state: false,
  initFlag : false,
  connectionLost: false,
  mainPage: false,
  prematchPage: false,
  timerOneID: false,
  serve_home : '',
  serve_away : '',
  game_status : {},
  match_info : {standby_kickoff_team : ''},
  command_log_array : [],
  command_log_ids : [],
  short_log_array : [],
  short_log_ids   : [],
  uncf_command_ids: [],
  current_state_props : {},
  message_log_array : [],
  offline_scoring_array: [],
  offline_scoring_data: [],
  offline_score: [0,0],
  settings: {},
  report: {},
  
  message_settings : {
    answer_number     : [],
    message_counter   : 0,
    message_position   : [],
    unanswered_messages : 0
  },

  active_statuses : [7,8,9,10,11,27],
  endOfPeriod_statuses : [20,21,22,23,24,28],

  score: {
    status_7 : [0,0],
    status_8 : [0,0],
    status_9 : [0,0],
    status_10: [0,0],
    status_11: [0,0],
    status_27: [0,0]
  }
};


export default window.MCC;