import React from 'react';
import Button from '@material-ui/core/Button';
import { socket } from '../../App'
import BottomRow from '../BottomRow';
import NavBar from '../NavBar';
import ResultStatsField from '../ResultStatsField';
import OpenCloseGame from '../OpenCloseGame';
import { withTranslation } from 'react-i18next';

class MisconductTypeState extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      points  : typeof window.MCC.stats.running.points !== 'undefined' ? window.MCC.stats.running.points : [0,0],
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0]
    };
  }

  componentDidMount = () => {
    window.MCC.mainPage = 'MisconductTypeState';
  }  

  sendCommand = (key, confirmed, value) => {

	  let cmdObj = {
	    key: key,
	    team: this.props.team,
      confirmed: confirmed,
	    mcc_id: Date.now()
    }

    if (typeof this.props.id !== 'undefined' && cmdObj.key !== 'status_unsure' && cmdObj.key !== 'status_ok'){
      cmdObj.id = window.MCC.back_id ? window.MCC.back_id : this.props.id;
    }

    if (typeof this.props.team !== 'undefined' && cmdObj.key !== 'status_unsure' && cmdObj.key !== 'status_ok'){
      cmdObj.team = window.MCC.back_team ? window.MCC.back_team : this.props.team;
    }

    if (typeof value !== 'undefined'){
      cmdObj.value = {type: value};
    }        

	  socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
	}

	render () {
    const {t} = this.props;
		return (
		<div className="wrapper" id="MisconductTypeState" style={styles.wrapper}>
          <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>

		     	<div className="result_field" style={styles.result_field_flex}>
		        	<ResultStatsField current_page='MisconductTypeState' current_team="0" mirror={true}/>

		        	<Button 
                  id="misconduct_type"
                  variant="contained" 
                  disabled 
                  style={styles.misconduct}
              >
			            {t('misconduct type')}
			        </Button>

		        	<ResultStatsField current_page='MisconductTypeState' current_team="1" mirror={false}/>
		      	</div>

		  		<div className="button_types" style={styles.point_types_field}> 
		  			<div className="first_row" style={styles.flex}>
						  <Button 
                  id="expulsion"
                  variant="contained" 
                  onClick = {() => this.sendCommand('misconduct', 1, 'expulsion')}
                  style={styles.button}
              >
	          		  {t('expulsion')}
			        </Button>

			        <Button 
                  id="disqualification"
                  variant="contained" 
                  onClick = {() => this.sendCommand('misconduct', 1, 'disqualification')} 
                  style={styles.button}
              >
                  {t('disqualif.')}
			        </Button>
		  			</div>

              <Button 
                  id="cancel"
                  variant="outlined" 
                  style={styles.cancel_button} 
                  onClick = {() => this.sendCommand('misconduct', -1)}>
                  {t('cancel')}
              </Button>

		  		</div>
		      	
		    	<div className="close_open_game" style={styles.close_open_game_field}>
		     		<OpenCloseGame showButton="true"/>
		     	</div> 

			    <BottomRow 
            setPage={this.props.setPage} 
            left_arrow="CommentState" 
            right_arrow="CommandLogState"
            id={this.props.id}
            team={this.props.team}            
          />
    		</div> 
		);
	}
}	 

const styles = {
	wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },  
  nav_bar: {
    height : '4%',
    width : '100vw',
    background: 'linear-gradient(#00FF00, #8d6e63)',
    textAlign : 'center' ,
  },
  flex: {
	  display : 'flex',
	  height: '29%',
	  justifyContent:'center',

  },
  result_field_flex: {
    display : 'flex',
    width: '100%',
    height: '13%',
  },
  point_types_field: {
	  display: 'flex',
	  flexDirection: 'column',
	  height: '50%',
	},
  result: {
    margin: 1,
    width: 400,
    borderRadius: 5,
    flexGrow: 1,
    fontSize: '2.5vh',
  },
  button: {
	  margin: 1,
	  width: '33.3%',
    borderRadius: 5,
    background: '#4CAF50',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.5vh',
    flexGrow: 1,
  },
  cancel_button: {
	  margin: 2,
	  height: '25%',
    borderRadius: 15,
    color: 'red',
    fontWeight : 'bold',
    fontSize: '2.5vh',
    marginTop: '1%',
  },
  misconduct: {
    margin: 1,
    width: '35%',
    borderRadius: 5,
    background: '#FB8C00',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.8vh',
  },
  close_open_game_field: {
  	display: 'flex',
  	justifyContent: 'center',
	  width: '100%',
	  height: '13%',
	  marginTop: '0.6%',
    position:'absolute',
    bottom:'14%',
  }
};

export default withTranslation()(MisconductTypeState);