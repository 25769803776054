import React from 'react';
import { List } from "react-virtualized"; // We use List instead of Material UI as it render visible elements only - https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md 
import BottomRow from './BottomRow';
import NavBar from './NavBar';
import CommandRow from './CommandLogRow';


class CommandLogState extends React.Component {
  //
  renderRow({ index, parent, key, style }) {
    return (
      <div key={key} style={style}>
        <CommandRow setPage={parent.props.setPage} key={key} id={window.MCC.command_log_array[index].id} logtype="" score_home={window.MCC.command_log_array[index].score_home} score_away={window.MCC.command_log_array[index].score_away}/>
      </div>
    );
  }

  constructor() {
    super();
    this.state = {
      listHeight: window.innerHeight * 0.81,
      rowHeight:window.innerHeight * 0.81*0.086
    }
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions () {
    this.setState({
      listHeight: window.innerHeight * 0.81,
      rowHeight: window.innerHeight * 0.81*0.086
    })
  }

  componentDidMount () {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount () {
      window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    
    return (
      <div className="wrapper" style={styles.wrapper}>
        {/* This is 5% height of the page */}
        <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/> 
        
        {/* This is 81% height of the page */}
        <List
          setPage={this.props.setPage}
          width={window.innerWidth} //This multiplication by "10" is to set max-width for the GRID div which is inside the LIST, as we have style prop in the LIST element which overwrite the height & width values
          height={this.state.listHeight}
          rowHeight={this.state.rowHeight}
          rowRenderer={this.renderRow}
          rowCount={window.MCC.command_log_array.length} 
          style={styles.overlay_list} // HACK:  We have this as height and width props are required for List element
        />
       
       {/* This is 14% height of the page */}
        <BottomRow 
          setPage={this.props.setPage} 
          left_arrow={!window.MCC.active_statuses.includes(window.MCC.game_status.status_id) && window.MCC.platform === 'mobile_admin' ? 
                      (window.MCC.status_types[window.MCC.game_status.status_id] && window.MCC.status_types[window.MCC.game_status.status_id].next ? 'StandByState' : 'CommentState') 
                      : /* Checking if we have added verifications already or update direct status from ADMIN UI and to know where to proceed with left arrow */
                      window.MCC.platform === 'mobile_callcenter' && window.MCC.verification[4] === 1 ? window.MCC.mainPage || window.MCC.prematchPage : !window.MCC.prematchPage ? window.MCC.mainPage : window.MCC.prematchPage} 
          right_arrow="NoConnectionState" 
          id={this.props.id}
          team={this.props.team}   
        />
      </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'absolute',
    height: '100%',
    display : 'flex',
    width : '100%',
    flexDirection: 'column',
  },
  overlay_list: {
    height: '81%',
    width: '100%',
    maxWidth: "100%",
  },
  cmd_log: {
    width: '100%',
    height: '95%',
    overflowY: 'scroll'
  },
}


export default CommandLogState;