import React from 'react';

class ResultStatsField extends React.Component {
	
	constructor(props) {
    super(props);
	    this.state = { 
	      points  : window.MCC.score['status_' + window.MCC.game_status.status_id] || ['',''],
	      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0],
	      set1_points : (typeof window.MCC.stats.status_7 !== 'undefined' && typeof window.MCC.stats.status_7.points !== 'undefined') ? window.MCC.stats.status_7.points : ['',''],
	      set2_points : (typeof window.MCC.stats.status_8 !== 'undefined' && typeof window.MCC.stats.status_8.points !== 'undefined') ? window.MCC.stats.status_8.points : ['',''],
	      set3_points : (typeof window.MCC.stats.status_9 !== 'undefined' && typeof window.MCC.stats.status_9.points !== 'undefined') ? window.MCC.stats.status_9.points : ['',''],
	      set4_points : (typeof window.MCC.stats.status_10 !== 'undefined' && typeof window.MCC.stats.status_10.points !== 'undefined') ? window.MCC.stats.status_10.points : ['',''],
	      set5_points : (typeof window.MCC.stats.status_11 !== 'undefined' && typeof window.MCC.stats.status_11.points !== 'undefined') ? window.MCC.stats.status_11.points : ['',''],
	      setGS_points : (typeof window.MCC.stats.status_27 !== 'undefined' && typeof window.MCC.stats.status_27.points !== 'undefined') ? window.MCC.stats.status_27.points : ['','']      
		    };
	}

  componentDidMount = () => {
    let element = document.getElementById('ResultStatsField');
    element.addEventListener('updateStats', this.eventListener);
  }

  componentWillUnMount = () => {
    let element = document.getElementById('ResultStatsField');
    element.removeEventListener(
      'updateStats',
      false
    );
  }; 

  eventListener = e => {
    this.setState({
      points  : window.MCC.score['status_' + window.MCC.game_status.status_id] || ['',''],
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0],
      set1_points : (typeof window.MCC.stats.status_7 !== 'undefined' && typeof window.MCC.stats.status_7.points !== 'undefined') ? window.MCC.stats.status_7.points : ['',''],
      set2_points : (typeof window.MCC.stats.status_8 !== 'undefined' && typeof window.MCC.stats.status_8.points !== 'undefined') ? window.MCC.stats.status_8.points : ['',''],
      set3_points : (typeof window.MCC.stats.status_9 !== 'undefined' && typeof window.MCC.stats.status_9.points !== 'undefined') ? window.MCC.stats.status_9.points : ['',''],
      set4_points : (typeof window.MCC.stats.status_10 !== 'undefined' && typeof window.MCC.stats.status_10.points !== 'undefined') ? window.MCC.stats.status_10.points : ['',''],
      set5_points : (typeof window.MCC.stats.status_11 !== 'undefined' && typeof window.MCC.stats.status_11.points !== 'undefined') ? window.MCC.stats.status_11.points : ['',''],
      setGS_points : (typeof window.MCC.stats.status_27 !== 'undefined' && typeof window.MCC.stats.status_27.points !== 'undefined') ? window.MCC.stats.status_27.points : ['','']      
    })
	};  
  
 	render() {
  		return (
  			<div variant="contained" id="ResultStatsField" disabled style={styles.result}>
          
          {/* This was implemented as we need mirror looks of this component in MainState */}
          {this.props.mirror === true ? 
          <div className="first_row" style={styles.first_row}>
            <div className="team_name">{window.MCC.event_info[this.props.current_team === '0' ? 'team_home_short_name' : 'team_away_short_name']}
              <div style={styles.team_color_field}>
                <div style={Object.assign({background: window.MCC.match_info.home_color_1}, styles.team_colors)}></div>
                <div style={Object.assign({background: window.MCC.match_info.home_color_2}, styles.team_colors)}></div>
              </div>
            </div>
            
            <div className="set_score" style={Object.assign({color: 'black'})}>{this.state.setswon[this.props.current_team]}</div> 
          </div>
          : 
          <div className="first_row" style={styles.first_row}>
            <div className="set_score" style={Object.assign({color: 'black'})}>{this.state.setswon[this.props.current_team]}</div> 
            
            <div className="team_name">{window.MCC.event_info[this.props.current_team === '0' ? 'team_home_short_name' : 'team_away_short_name']}
              <div style={styles.team_color_field}>
                <div style={Object.assign({background: window.MCC.match_info.away_color_1}, styles.team_colors)}></div>
                <div style={Object.assign({background: window.MCC.match_info.away_color_2}, styles.team_colors)}></div>
              </div>
            </div>
          </div>
          }
            

          <div className="current_score_point" style={styles.current_score_point}>{this.state.points[this.props.current_team]}</div>
          <div className="all_set_scores" style={styles.all_set_scores}>{this.state.set1_points[this.props.current_team]}
               {this.state.set1_points[this.props.current_team] === '' ? '' : ' | '}
               {this.state.set2_points[this.props.current_team]}
               {this.state.set2_points[this.props.current_team] === '' ? '' : ' | '}
               {this.state.set3_points[this.props.current_team]}
               {this.state.set3_points[this.props.current_team] === '' ? '' : ' | '}
               {this.state.set4_points[this.props.current_team]}
               {this.state.set4_points[this.props.current_team] === '' ? '' : ' | '}
               {this.state.set5_points[this.props.current_team]}
               {this.state.set5_points[this.props.current_team] === '' ? '' : ' | '}
               {this.state.setGS_points[this.props.current_team]}
          </div>
        </div>
  	)
  }
}

const styles = {
	result: {
		display : 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
    margin: 1,
    width: '70%',
    borderRadius: 3,
    border: '1px solid rgba(63, 81, 181, 0.5)',
		color: 'white',
		background: '#78909c',
  },
  current_score_point:{
    fontSize: '4vh',
  	fontWeight: 'bold',
    fontFamily: 'sans-serif',
    height: '33.3%',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  all_set_scores: {
  	fontSize: '1.5vh',
  	fontWeight: 'bold',
    fontFamily: 'sans-serif',
    height: '33.3%',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  first_row: {
		fontSize: '2.5vh',
  	fontWeight: 'bold',
    fontFamily: 'sans-serif',
    height: '33.3%',
    display: 'flex',
    width: '95%',
    justifyContent: 'space-between'
  },
  team_color_field: {
    display: 'flex',
    justifyContent: 'center',
  },
  team_colors: {
    padding:3,
    margin:2,
    display:"inline-block",
    borderRadius: "60%",
    borderStyle: 'double',
    borderWidth: 'thin',
    width:8,
    height:8,
  }
}

export default ResultStatsField;