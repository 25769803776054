import React from 'react';
import Button from '@material-ui/core/Button';
import { socket } from '../../App'
import BottomRow from '../BottomRow';
import NavBar from '../NavBar';
import ResultStatsField from '../ResultStatsField';
import OpenCloseGame from '../OpenCloseGame';
import { withTranslation } from 'react-i18next';

class ConfirmState extends React.Component {

  commandKey = () => {
    if (!this.props.disabled) {
       let id = window.MCC.back_id ? window.MCC.back_id : (window.MCC.last_unconfirmed_point && window.MCC.last_unconfirmed_point.id ? window.MCC.last_unconfirmed_point.id : this.props.id);
       if (window.MCC.commands[id]) {
        return window.MCC.commands[id].key;
       }
    }

    return '';
  };

  sendCommand = (key , team , confirmed) => {

    let cmdObj = {
      key: key,
      team: team,
      confirmed: confirmed,
      mcc_id: Date.now()
    }
    
    if (typeof this.props.id !== 'undefined' && cmdObj.key !== 'status_unsure' && cmdObj.key !== 'status_ok' && cmdObj.key !== 'timeout'){
      cmdObj.id = window.MCC.back_id ? window.MCC.back_id : (window.MCC.last_unconfirmed_point && window.MCC.last_unconfirmed_point.id ? window.MCC.last_unconfirmed_point.id : this.props.id);
    }

    if (key === 'point' && window.MCC.last_unconfirmed_point &&  window.MCC.last_unconfirmed_point.team){
      cmdObj.team = window.MCC.last_unconfirmed_point.team;
    }

    socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
  };

  setProperties = () => {
    switch(this.commandKey()) {
      case 'point':
        window.MCC.serve_home = 'disabled';
        window.MCC.serve_away = 'disabled';
        window.MCC.current_state_props.point = 'disabled';
        window.MCC.current_state_props.confirm = 'enabled';
        window.MCC.current_state_props.challenge = 'enabled';
        break;
      default:
        break;  
    }
  }

  constructor(props){
      super(props);
      this.state = {
        open_game : false,
        points  : window.MCC.score['status_' + window.MCC.game_status.status_id],
        point_team : window.MCC.back_team ? window.MCC.back_team : (window.MCC.last_unconfirmed_point &&  window.MCC.last_unconfirmed_point.team ? window.MCC.last_unconfirmed_point.team : this.props.team)
      }
  }

  componentDidMount = () => {
    window.MCC.mainPage = 'ConfirmState';
    /* Set this to False as we need when we are in uncf SUBS or CHALLENGE WON state and goes BACK to be redirected to CommandLogState / MainState */
    window.MCC.return_to_main_state = false;
  }  

	render () {
    const {t} = this.props;
    return (
			<div className="wrapper" id="ConfirmState" style={styles.wrapper}>
          <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
		     	<div className="result_field" style={styles.result_field}>
		        	<ResultStatsField current_page='ConfirmState' current_team="0" mirror={true}/>

            {/* We are adding Timeout button in Point Confirm State and hide it in Subst State */}
            {window.MCC.back_key === 'subst' ? 
            <Button 
                id="subs_type"
                variant="contained" 
                disabled 
                style={styles.subs_type}
            >
			          {t('subs type')}
			      </Button>  
            : 
            <Button 
                id="timeout"
                variant="contained" 
                onClick = {() => {
                  this.sendCommand('timeout', 0)
                  }} 
                style={styles.timeout}
            >
                {t('timeout')}
            </Button> }
            
            <ResultStatsField current_page='ConfirmState' current_team="1" mirror={false}/>
		      </div>

		      	<div className="content" style={styles.content}>

              <div className='info_field' disabled style={styles.info_field}>
              
                <Button
                    id="info_field_1" 
                    disabled 
                    style={styles.div_info_field}
                >
                  <span style={{'margin' : '1.5%' , 'color' : 'black' , 'fontSize' : '3vh'}}>{t('confirm')} {t(this.state.point_team === 1 ? 'home' : 'away')} {t(this.commandKey())}{'?'}</span>
                </Button> 

                {/* Checking if we are in Subs pop up to not show future score as we show in Confirm Point state */}
                {window.MCC.back_key === 'subst' ? '' :
                  <Button 
                      id="info_field_2" 
                      disabled 
                      style={styles.div_info_field}
                  >
                      <span style={{'color' : 'black' , 'fontSize' : '3vh'}}>{t('score changes to - ')}</span>
                      <span style={{'marginLeft' : '1%' ,'color' : 'white' , 'fontSize' : '5vh'}}>{this.state.point_team === 1 ? 
                      (this.state.points[0] + 1 + ':' + this.state.points[1]) 
                      : 
                      (this.state.points[0] + ':' + (this.state.points[1] + 1))}
                    </span>
                  </Button>}            
              </div>

              <div style={styles.main_field}>
                  <Button
                      id="yes" 
                      variant="contained" 
                      color="primary" 
                      style={styles.yes_button} 
                      onClick = {() => this.sendCommand(this.commandKey() , this.props.team , 1)}
                  >
                      {t('yes')}
                  </Button>

                  <Button 
                      id="no"
                      variant="contained" 
                      color="secondary" 
                      style={styles.no_button} 
                      onClick = {() => {
                        this.setProperties();
                        if (this.commandKey() === 'subst') this.sendCommand(this.commandKey() , this.props.team , -1);
                        window.MCC.current_nocommand_state = window.MCC.current_state;
                        window.MCC.point_state = 'MainState';
                        if (this.commandKey() !== 'subst') this.props.setPage('MainState', window.MCC.current_state_props);
                      }}
                  >
                      {t('no')}
                  </Button>
              </div>
		      	</div>    
		      	
		    	<div className="close_open_game" style={styles.close_open_game_field}>
		     		<OpenCloseGame showButton="true"/>
		     	</div> 

			    <BottomRow 
            setPage={this.props.setPage} 
            left_arrow="CommentState" 
            right_arrow="CommandLogState" 
            id={this.props.id}
            team={this.props.team}
          />
    		</div>

		);
	}
}

const styles = {
	wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },
  result_field: {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '13%',
    justifyContent:'center',
  },
  content : {
	  display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '27%',
    flexDirection: 'column',
  },
  yes_button : {
  	margin: 1,
	  height: '100%',
    borderRadius: 5,
    background: '#4CAF50',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
    textTransform: 'capitalize'
  },
  no_button : {
  	margin: 1,
	  height: '100%',
    borderRadius: 5,
    background: '#df2029',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
    textTransform: 'capitalize'
  },
  confirm_command: {
    margin: 1,
    width: '100%',
    height: '100%',
    borderRadius: 5,
    background: '#FB8C00',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
  },
  close_open_game_field: {
  	display: 'flex',
  	justifyContent: 'center',
	  width: '100%',
	  height: '13%',
	  marginTop: '0.6%',
    position:'absolute',
    bottom:'14%',
  },
  timeout: {
    margin: 1,
    width: '35%',
    borderRadius: 5,
    background: '#df2029',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    flexGrow: 1,
  },
  info_field: {
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    margin: 1,
    borderRadius: 5,
    background: '#FB8C00',
    color: 'white', 
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize'
  },
  div_info_field: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '3vh',
    textTransform: 'capitalize'
  },
  main_field: {
    height: '50%',
    display: 'flex',
  },
  subs_type: {
    margin: 1,
    width: '35%',
    borderRadius: 5,
    background: '#FB8C00',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    textTransform: 'capitalize'
  },
};


export default withTranslation()(ConfirmState);