import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { socket} from '../../App';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

let classes={};
class PlayersFieldSelector extends React.Component {

    constructor(props) {
      super(props);
      classes = Object.assign(props.classes);
    }

    sendCommand = (key, team, value) => {

        let cmdObj = {
          key: key,
          team: team,
          value: value
        }
    
        if (socket) { 
          socket.emit('command', cmdObj);
          window.MCC.dispatchEvent('nav_bar', 'setOverlay');
        }
    }

    handleChange = (event) => {
        
        let key = event.target.name;
        let team = 0;

        if (event.target.value === 'players on the court' || (window.MCC.match_info.players === 'players on the court' && event.target.name !== 'players')){
          setTimeout(() => {
            socket.emit('report', {'prematch_step4_confirmed' : 'yes'});
          }, 100);
          window.MCC.sendVerification('steps');
          this.setState({progress: 100});
        }
        if (event.target.name === 'home_color_1' || event.target.name === 'away_color_1'){
          key = 'shirt_color_1';
        }
        if (event.target.name === 'home_color_2' || event.target.name === 'away_color_2'){
          key = 'shirt_color_2';
        }
        if (event.target.name === 'home_color_1' || event.target.name === 'home_color_2'){
          team = 1;
        }
        if (event.target.name === 'away_color_1' || event.target.name === 'away_color_2'){
          team = 2;
        }           
        this.sendCommand(key, team, event.target.value);
      };

    render() {
        const {t} = this.props;
        return (
        <Select
            data-test="players_field"
            value={typeof window.MCC.match_info !== 'undefined' && typeof window.MCC.match_info.players !== 'undefined' ? window.MCC.match_info.players : ''}
            onChange={this.handleChange}
            inputProps={{
              name: 'players'
            }}
        >
            <MenuItem disabled classes={{root: classes.root}}>{t('please select')}</MenuItem>
            <MenuItem value="warming up" classes={{root: classes.root}}>{t('warming up')}</MenuItem>
            <MenuItem value="players not on the court" classes={{root: classes.root}}>{t('players not on the court')}</MenuItem>
            <MenuItem value="players still not on the court" classes={{root: classes.root}}>{t('players still not on the court')}</MenuItem>
            <MenuItem value="players on the court" classes={{root: classes.root}}>{t('players on the court')}</MenuItem>
        </Select>
    )}
}

const overrideStyles = {
  root: {
    textTransform: 'capitalize' 
  } 
}

PlayersFieldSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(overrideStyles)(PlayersFieldSelector));


