import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import BottomRow from './../BottomRow';
import NavBar from '../NavBar';
import CallMe from '../CallMe';
import { socket } from '../../App';
import OpenCloseGame from './../OpenCloseGame';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


/*use transition effect for Dialog boxes*/
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

/* Make global variables and will initialize them later */
let classes={},
    pop_up;

class CommentState extends React.Component {
  
  constructor(props) {
    super(props);
    classes = Object.assign(props.classes);
    this.state = { 
      status_change: typeof window.MCC.game_status.status_id !== 'undefined' ? window.MCC.game_status.status_id.toString() : '',
      premade_comment: '',
      client_comment: '',
      custom_comment: '',
      disabled: this.props.disabled,
      reset_dialog: false,
      status_dialog: false,
      points  : window.MCC.score['status_' + window.MCC.game_status.status_id] || ['',''],
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0],
      set1_points : (typeof window.MCC.stats.status_7 !== 'undefined' && typeof window.MCC.stats.status_7.points !== 'undefined') ? window.MCC.stats.status_7.points : [0,0],
      set2_points : (typeof window.MCC.stats.status_8 !== 'undefined' && typeof window.MCC.stats.status_8.points !== 'undefined') ? window.MCC.stats.status_8.points : [0,0],
      set3_points : (typeof window.MCC.stats.status_9 !== 'undefined' && typeof window.MCC.stats.status_9.points !== 'undefined') ? window.MCC.stats.status_9.points : [0,0],
      set4_points : (typeof window.MCC.stats.status_10 !== 'undefined' && typeof window.MCC.stats.status_10.points !== 'undefined') ? window.MCC.stats.status_10.points : [0,0],
      set5_points : (typeof window.MCC.stats.status_11 !== 'undefined' && typeof window.MCC.stats.status_11.points !== 'undefined') ? window.MCC.stats.status_11.points : [0,0],
      setGS_points : (typeof window.MCC.stats.status_27 !== 'undefined' && typeof window.MCC.stats.status_27.points !== 'undefined') ? window.MCC.stats.status_27.points : [0,0]
    };
  }  

  eventListener = e => {
    this.setState({
      disabled: false,
      status_change: typeof window.MCC.game_status.status_id !== 'undefined' ? window.MCC.game_status.status_id.toString() : '',
      points  : window.MCC.score['status_' + window.MCC.game_status.status_id] || ['',''],
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0],
      set1_points : (typeof window.MCC.stats.status_7 !== 'undefined' && typeof window.MCC.stats.status_7.points !== 'undefined') ? window.MCC.stats.status_7.points : [0,0],
      set2_points : (typeof window.MCC.stats.status_8 !== 'undefined' && typeof window.MCC.stats.status_8.points !== 'undefined') ? window.MCC.stats.status_8.points : [0,0],
      set3_points : (typeof window.MCC.stats.status_9 !== 'undefined' && typeof window.MCC.stats.status_9.points !== 'undefined') ? window.MCC.stats.status_9.points : [0,0],
      set4_points : (typeof window.MCC.stats.status_10 !== 'undefined' && typeof window.MCC.stats.status_10.points !== 'undefined') ? window.MCC.stats.status_10.points : [0,0],
      set5_points : (typeof window.MCC.stats.status_11 !== 'undefined' && typeof window.MCC.stats.status_11.points !== 'undefined') ? window.MCC.stats.status_11.points : [0,0],
      setGS_points : (typeof window.MCC.stats.status_27 !== 'undefined' && typeof window.MCC.stats.status_27.points !== 'undefined') ? window.MCC.stats.status_27.points : [0,0]      
    })
  }

  componentDidMount = () => {
    let element = document.getElementById('CommentState');
    element.addEventListener('updateStats', this.eventListener);
    element.addEventListener('updateStatus', this.eventListener);
    
  }

  componentWillUnMount = () => {
    let element = document.getElementById('CommentState');
    element.removeEventListener(
      'updateStats',
      false
    );
    element.removeEventListener(
      'updateStatus',
      false
    );    
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }


  handleOpen = (value) => {
    /* Keep the value of the pop which we open ( in this case can be 'status' or 'reset' as we will use it later) */
    pop_up = value;
    if (value === 'reset') {
      this.setState({ reset_dialog: true });
    } else if (value === 'status') {
      this.setState({ status_dialog: true });
    }
  };

  handleClose = (value) => {
    if (value === 'reset') {
      this.setState({ reset_dialog: false });
    } else if (value === 'status') {
      this.setState({ status_dialog: false });
    }
    
  };

  /*Emit command object*/
  sendCommand = (key, team , value) => {

    let cmdObj = {
      key: key,
      team: team,
      mcc_id: Date.now()
    }

    if (typeof value !== 'undefined'){
      if (cmdObj.key === 'setting' || cmdObj.key === 'status' || cmdObj.key === 'comment'){
        cmdObj.value = value;
      }else{
        cmdObj.value = {type: value};
      }
    }      

    if (cmdObj.value !== '') {
      socket.emit('command', cmdObj);
      window.MCC.dispatchEvent('nav_bar', 'setOverlay');
    }

  }

  render() {
    const {t} = this.props;
    let popup_type = pop_up === 'status' ? 'status' : 'reset'; /* This variable will define which popup we wants to open */
    let opened_popup = pop_up === 'status' ? this.state.status_dialog : this.state.reset_dialog; /* This variable will take value (true/false) of the respective popup we wants to open */
    return (
      <div className="dropdowns" id="CommentState" style={styles.flex_column}>
        <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
        <div className="info_box" style={window.MCC.platform === 'mobile_callcenter' ? styles.info_box_mobile : styles.info_box}>
          <div className="sport_section">
            <p style={window.MCC.platform === 'mobile_admin' ? Object.assign({margin: '1%' , width: '100%' , fontSize: '2vh'}) : styles.invisible}>{window.MCC.event_info.country_name} / {window.MCC.event_info.stage} / {window.MCC.event_info.tournament}</p>
          </div>

          <div className="date_section">
            <p style={window.MCC.platform === 'mobile_admin' ? Object.assign({margin: '1%' , width: '100%' , fontSize: '2vh'}) : styles.invisible}>{window.MCC.event_info.startdate} UTC</p>
          </div>  
          
          <div className="result_section">
            <p style={window.MCC.platform === 'mobile_admin' ? Object.assign({margin: '1%' , width: '100%' , fontSize: '2vh'}) : styles.invisible}>{window.MCC.event_info.team_home} <span style={styles.current_score}>{this.state.points[0]} - {this.state.points[1]}</span> {window.MCC.event_info.team_away}</p>
          </div>          
          <div className="set_result_section">
            <p style={window.MCC.platform === 'mobile_admin' ? Object.assign({margin: '1%' , width: '100%' , fontSize: '1.8vh'}) : styles.invisible}>
              <span style={styles.set_number}>{t('S1')}</span>: <span style={styles.set_score}>{this.state.set1_points[0]}-{this.state.set1_points[1]}</span> {' | '}
              <span style={styles.set_number}>{t('S2')}</span>: <span style={styles.set_score}>{this.state.set2_points[0]}-{this.state.set2_points[1]}</span> {' | '}
              <span style={styles.set_number}>{t('S3')}</span>: <span style={styles.set_score}>{this.state.set3_points[0]}-{this.state.set3_points[1]}</span> {' | '}
              <span style={styles.set_number}>{t('S4')}</span>: <span style={styles.set_score}>{this.state.set4_points[0]}-{this.state.set4_points[1]}</span> {' | '}
              <span style={styles.set_number}>{t('S5')}</span>: <span style={styles.set_score}>{this.state.set5_points[0]}-{this.state.set5_points[1]}</span> {' | '}
              <span style={styles.set_number}>{t('GS')}</span>: <span style={styles.set_score}>{this.state.setGS_points[0]}-{this.state.setGS_points[1]}</span> {' | '}
              <span style={styles.set_number}>{t('FT')}</span>: <span style={styles.set_score}>{this.state.setswon[0]}-{this.state.setswon[1]}</span>
            </p>
          </div>
        </div>

        <div className="set_status" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_status_change" className="formControl" style={window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'desktop' ? styles.formControl : styles.invisible}>
              <InputLabel htmlFor="comment-simple">{t('*set status')}</InputLabel>
              <Select
                  value={this.state.status_change}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'status_change'
                  }}
              >
                <MenuItem disabled value="" classes={{root: classes.root}}>
                  <em>{t('none')}</em>
                </MenuItem>
                  <MenuItem value="1" data-id="1" classes={{root: classes.root}}>{t('not started')}</MenuItem>
                  <MenuItem value="2" classes={{root: classes.root}}>{t('warmup')}</MenuItem>
                  <MenuItem value="5" classes={{root: classes.root}}>{t('postponed')}</MenuItem>
                  <MenuItem value="6" classes={{root: classes.root}}>{t('finished')}</MenuItem>
                  <MenuItem value="7" classes={{root: classes.root}}>{t('1st set')}</MenuItem>
                  <MenuItem value="8" classes={{root: classes.root}}>{t('2nd set')}</MenuItem>
                  <MenuItem value="9" classes={{root: classes.root}}>{t('3rd set')}</MenuItem>
                  <MenuItem value="10" classes={{root: classes.root}}>{t('4th set')}</MenuItem>
                  <MenuItem value="11" classes={{root: classes.root}}>{t('5th set')}</MenuItem>
                  <MenuItem value="12" classes={{root: classes.root}}>{t('interrupted')}</MenuItem>
                  <MenuItem value="15" classes={{root: classes.root}}>{t('coverage stopped')}</MenuItem>
                  <MenuItem value="17" classes={{root: classes.root}}>{t('abandoned')}</MenuItem>
                  <MenuItem value="18" classes={{root: classes.root}}>{t('cancelled')}</MenuItem>
                  <MenuItem value="19" classes={{root: classes.root}}>{t('to finish')}</MenuItem>
                  <MenuItem value="20" classes={{root: classes.root}}>{t('end of 1st set')}</MenuItem>
                  <MenuItem value="21" classes={{root: classes.root}}>{t('end of 2nd set')}</MenuItem>
                  <MenuItem value="22" classes={{root: classes.root}}>{t('end of 3rd set')}</MenuItem>
                  <MenuItem value="23" classes={{root: classes.root}}>{t('end of 4th set')}</MenuItem>
                  <MenuItem value="24" classes={{root: classes.root}}>{t('end of 5th set')}</MenuItem>
                  <MenuItem value="25" classes={{root: classes.root}}>{t('kickoff delayed')}</MenuItem>
                  <MenuItem value="26" classes={{root: classes.root}}>{t('waiting for golden set')}</MenuItem>
                  <MenuItem value="27" classes={{root: classes.root}}>{t('golden set')}</MenuItem>
                  <MenuItem value="28" classes={{root: classes.root}}>{t('finished after golden set')}</MenuItem>
              </Select>
            </FormControl>
          </form>
            <Button 
                id="send_status"
                variant="outlined" 
                color="primary"
                size="medium"
                style={window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'desktop' ? styles.send_button : styles.invisible} 
                className="set_status_btn" 
                onClick = {() => 
                {
                  window.MCC.current_nocommand_state = 'CommentState';
                  //send To Finish only after Abandoned
                  if (window.MCC.game_status.status_id !== 17 && this.state.status_change * 1 === 19) {
                    this.handleOpen('status')
                  } else if (this.state.status_change !== '' && window.MCC.game_status.status_id !== this.state.status_change * 1) {
                    //Disable sending empty or duplicate status commands
                    this.sendCommand('status', 0 ,this.state.status_change * 1); 
                  }
                }
                }
            >
                {t('send')} 
                <Icon style={{paddingLeft: 5}}>send</Icon>
            </Button>
      </div>

        <div className="predefined_field" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_premade_comment" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">{t('*predefined comment')}</InputLabel>
              <Select
                  value={this.state.premade_comment}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'premade_comment'
                  }}
              >
                <MenuItem value="" classes={{root: classes.root}}>
                  <em>{t('none')}</em>
                </MenuItem>
                  <MenuItem value="Home team Player injured" classes={{root: classes.root}}>{t('home team player injured')}</MenuItem>
                  <MenuItem value="Away team Player injured" classes={{root: classes.root}}>{t('away team player injured')}</MenuItem>
                  <MenuItem value="Water drinking break" classes={{root: classes.root}}>{t('water drinking break')}</MenuItem>
                  <MenuItem value="Play stopped due to serious injury" classes={{root: classes.root}}>{t('play stopped due to serious injury')}</MenuItem>
                  <MenuItem value="Game interrupted due to electricity/light problems" classes={{root: classes.root}}>{t('game interrupted due to electricity/light problems')}</MenuItem>
                  <MenuItem value="Game interrupted due to violence between the fans" classes={{root: classes.root}}>{t('game interrupted due to violence between the fans')}</MenuItem>
                  <MenuItem value="Violence between fans and players" classes={{root: classes.root}}>{t('violence between fans and players')}</MenuItem>
                  <MenuItem value="Violence between players" classes={{root: classes.root}}>{t('violence between players')}</MenuItem>
                  <MenuItem value="Violence between fans" classes={{root: classes.root}}>{t('violence between fans')}</MenuItem>
                  <MenuItem value="Terrorist act" classes={{root: classes.root}}>{t('terrorist act')}</MenuItem>
                  <MenuItem value="Yellow card for home team bench player" classes={{root: classes.root}}>{t('yellow card for home team bench player')}</MenuItem>
                  <MenuItem value="Yellow card for away team bench player" classes={{root: classes.root}}>{t('yellow card for away team bench player')}</MenuItem>
                  <MenuItem value="Red card for home team bench player" classes={{root: classes.root}}>{t('red card for home team bench player')}</MenuItem>
                  <MenuItem value="Red card for away team bench player" classes={{root: classes.root}}>{t('red card for away team bench player')}</MenuItem>
                  <MenuItem value="Home team coach sent off" classes={{root: classes.root}}>{t('home team coach sent off')}</MenuItem>
                  <MenuItem value="Away team coach sent off" classes={{root: classes.root}}>{t('away team coach sent off')}</MenuItem>
                  <MenuItem value="The last serve is retaken due to referee decision" classes={{root: classes.root}}>{t('the last serve is retaken due to referee decision')}</MenuItem>
                </Select>
            </FormControl>
          </form>
          <Button 
              id="send_premade_comment"
              variant="outlined" 
              color="primary"
              size="medium"
              style={styles.send_button} 
              className="send_premade_comment" 
              onClick = {() => 
                this.sendCommand('comment', 0 , this.state.premade_comment)
              }
          >
              {t('send')}
              <Icon style={{paddingLeft: 5}}>send</Icon>
          </Button>
        </div>

        <div className="client_text" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_client_comment" className="formControl" style={window.MCC.platform === 'mobile_admin' ? styles.formControl : styles.invisible}>
              <InputLabel htmlFor="comment-simple">{t('*client comment')}</InputLabel>
              <Select
                  value={this.state.client_comment}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'client_comment'
                  }}
              >
                <MenuItem value=""  classes={{root: classes.root}}>
                  <em>{t('none')}</em>
                </MenuItem>
                  <MenuItem value="Please be advised that the match will not be covered" classes={{root: classes.root}}>{t('please be advised that the match will not be covered')}</MenuItem>
                  <MenuItem value="Please be advised that due to security issues at the stadium the match will not be covered" classes={{root: classes.root}}>{t('please be advised that due to security issues at the stadium the match will not be covered')}</MenuItem>
              </Select>
            </FormControl>
          </form>
            <Button 
                id="send_client_comment"
                variant="outlined" 
                color="primary"
                size="medium"
                style={window.MCC.platform === 'mobile_admin' ? styles.send_button : styles.invisible} 
                className="send_client_comment" 
                onClick = {() => 
                  this.sendCommand('comment', 0 , this.state.client_comment)
                }
            >
                {t('send')}
              <Icon style={{paddingLeft: 5}}>send</Icon>
            </Button>
        </div>
        <div className="custom_text" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
          <FormControl id="input_custom_comment" className="formControl" style={window.MCC.platform === 'mobile_admin' ? styles.formControl : styles.invisible}>
          <TextField
              label={t('*custom comment')}
              style={styles.textField}
              value={this.state.custom_comment}
              onChange={this.handleChange}
              inputProps={{
                name: 'custom_comment'
              }}
          />
          </FormControl>
          </form>
          <Button 
              id="send_custom_comment"
              variant="outlined" 
              color="primary"
              size="medium"
              style={window.MCC.platform === 'mobile_admin' ? styles.send_button : styles.invisible} 
              className="send_custom_comment" 
              onClick = {() => 
                this.sendCommand('comment', 0 , this.state.custom_comment)
              }
          >
              {t('send')}
            <Icon style={{paddingLeft: 5}}>send</Icon>
          </Button>

        </div>

     
        <div className="connection_buttons" style={styles.connection_buttons}>
          
          <OpenCloseGame showButton={window.MCC.platform === 'mobile_admin' ? true : false}/>

          <Button 
              id="comm_lost_comm_ok"
              variant="contained"
              disabled={(window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control !== 'mobile') ? false : true} 
              onClick = {() => this.sendCommand(window.MCC.comm ? 'comm_lost' : 'comm_ok' , 0 )} 
              style={window.MCC.platform === 'mobile_admin' ? (window.MCC.settings.callcenter_control !== 'mobile' ? (window.MCC.comm ? styles.open_game : styles.close_game) : styles.open_disabled) : styles.invisible}
          >
              {window.MCC.comm ? t('comm lost') : t('comm ok')}
          </Button>
          <Button 
              id="lock_mobile_unlock_mobile"
              variant={window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'mobile' ? 'contained' : 'outlined'}
              color="secondary"
              onClick = {() => this.sendCommand('setting', 0 ,{event_id : window.MCC.event_info.event_id, key: 'callcenter_control', value: (window.MCC.settings.callcenter_control === 'mobile' ? 'desktop' : 'mobile')})} 
              style={window.MCC.platform === 'mobile_admin' ? styles.lock_button : styles.invisible}
          >
              {window.MCC.settings.callcenter_control === 'mobile' ? t('lock mobile') : t('unlock mobile')}
          </Button>
        </div>

          <div style={window.MCC.platform === 'mobile_callcenter' ? styles.call_me_field_mobile : styles.call_me_field}>
            <CallMe showButton={window.MCC.platform === 'mobile_callcenter' ? true : false} />
          </div>
      
          <div className="adjust_field" style={(window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control !== 'mobile') ? styles.adjust_field : styles.invisible}>

            <div className="adjust_buttons adjust_home" style={styles.adjust_buttons}>
              <Button 
                  id="adjust_score_up_home"
                  className="adjust_smaller_buttons adjust_smaller_buttons_home" 
                  variant="outlined" 
                  color="primary"                    
                  style={Object.assign({color: 'green'},styles.adjust_smaller_buttons)}
                  onClick = {() => {
                      if (window.MCC.active_statuses.includes(window.MCC.game_status.status_id) && (!window.MCC.periodEnd || window.MCC.score['status_' + window.MCC.game_status.status_id][0] < window.MCC.score['status_' + window.MCC.game_status.status_id][1])) {
                        this.sendCommand('adjust_score_up', 1 );
                      }
                  }} 
              >
                  +
              </Button>
              <div 
                  id="points_home"
                  className="adjust_smaller_buttons adjust_smaller_buttons_home" 
                  style={styles.adjust_smaller_buttons}>{this.state.points[0]}
              </div>
              <Button 
                  id="adjust_score_down_home"
                  className="adjust_smaller_buttons adjust_smaller_buttons_home" 
                  variant="outlined" 
                  color="secondary"
                  style={Object.assign({color: 'red'},styles.adjust_smaller_buttons)}
                  onClick = {() => { 
                      if (window.MCC.active_statuses.includes(window.MCC.game_status.status_id) && 
                          window.MCC.score['status_' + window.MCC.game_status.status_id][0] > 0  &&
                          !(window.MCC.score['status_' + window.MCC.game_status.status_id][1] - window.MCC.score['status_' + window.MCC.game_status.status_id][0] === 2 && 
                            ((window.MCC.game_status.status_id >= 7 && window.MCC.game_status.status_id <= 10 && window.MCC.score['status_' + window.MCC.game_status.status_id][1] > 25) ||
                              ((window.MCC.game_status.status_id === 11 || window.MCC.game_status.status_id === 27) && window.MCC.score['status_' + window.MCC.game_status.status_id][1] > 15)
                            )
                          )
                      ) {
                        this.sendCommand('adjust_score_down', 1 );
                      }
                  }} 
              >
                  -
              </Button>
            </div>
              
            <div className="adjust_buttons adjust_away" style={styles.adjust_buttons}>
              <Button 
                  id="adjust_score_up_away"
                  className="adjust_smaller_buttons adjust_smaller_buttons_away" 
                  variant="outlined" 
                  color="primary"
                  style={Object.assign({color: 'green'},styles.adjust_smaller_buttons)}
                  onClick = {() => { 
                      if (window.MCC.active_statuses.includes(window.MCC.game_status.status_id) && (!window.MCC.periodEnd || window.MCC.score['status_' + window.MCC.game_status.status_id][1] < window.MCC.score['status_' + window.MCC.game_status.status_id][0])) {                        
                        this.sendCommand('adjust_score_up', 2 );
                      }
                  }}
              >
                  +
              </Button>

              <div 
                  id="points_away"
                  className="adjust_smaller_buttons adjust_smaller_buttons_away" 
                  style={styles.adjust_smaller_buttons}>{this.state.points[1]}
              </div>

              <Button 
                  id="adjust_score_down_away"
                  className="adjust_smaller_buttons adjust_smaller_buttons_away" 
                  variant="outlined" 
                  color="secondary"
                  style={Object.assign({color: 'red'},styles.adjust_smaller_buttons)}
                  onClick = {() => {
                      if (window.MCC.active_statuses.includes(window.MCC.game_status.status_id) && 
                          window.MCC.score['status_' + window.MCC.game_status.status_id][1] > 0 &&
                          !(window.MCC.score['status_' + window.MCC.game_status.status_id][0] - window.MCC.score['status_' + window.MCC.game_status.status_id][1] === 2 && 
                            ((window.MCC.game_status.status_id >= 7 && window.MCC.game_status.status_id <= 10 && window.MCC.score['status_' + window.MCC.game_status.status_id][0] > 25) ||
                              ((window.MCC.game_status.status_id === 11 || window.MCC.game_status.status_id === 27) && window.MCC.score['status_' + window.MCC.game_status.status_id][0] > 15)
                            )
                          )
                      ) {
                        this.sendCommand('adjust_score_down', 2 );
                      }
                  }}
              >
                  -
              </Button> 
            </div>

          </div>

          <Button 
              id="reset"
              variant="outlined"
              disabled={((window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control !== 'mobile')  ||
                        (window.MCC.platform === 'mobile_callcenter' && window.MCC.settings.callcenter_control === 'mobile')) ? false : true} 
              onClick = {() => this.handleOpen('reset')}
              style={
                window.MCC.platform === 'mobile_admin' ? (window.MCC.settings.callcenter_control === 'mobile' ? styles.reset_disabled : styles.reset) : 
              // For any environment && Agent/Reporter group we will show the RESET button except for the 'PRODUCTION' where only mobile_admin will have RESET button available!!!
              (window.params.environment && window.params.environment !== 'production' && (window.params.groups.includes('agent') || window.params.groups.includes('reporters')) ? styles.reset : styles.invisible)
              }
          >
              {t('reset')}
          </Button>

        <Dialog
          open={opened_popup} /* This can be true or false depend on the popup_type we wants to open */
          onClose={() => {this.handleClose(popup_type)}} /* Close the respective popup (status or reset Dialog) */
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{t(popup_type === 'reset' ? 'Reset Event' : 'Status Alert')}</DialogTitle> {/* Title will be different depending on the popup we open */}
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"> {/* Description will be different depending on the popup we open */}
              {t(popup_type === 'reset' ? 'Once the event is reset, all match data will be lost!' : '"To Finish" can be called only after status "Abandoned"!')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
                id="cansel_close" 
                onClick={() => this.handleClose(popup_type)} color="primary" 
            > {/* Close the respective popup (status or reset Dialog) */}
                {t(popup_type === 'reset' ? 'cancel' : 'close')}
            </Button> {/* Hide RESET button if we are in 'status pop up and show it if we are in 'reset' popup  */}
            <Button 
                id="reset"
                style={popup_type === 'reset' ? styles.default : styles.overlay_none} 
                onClick={() => socket.emit('reset')} 
                color="secondary" 
            >
                {t('reset')}
            </Button>
          </DialogActions>
        </Dialog>

        <BottomRow 
          setPage={this.props.setPage}
          left_arrow="MatchInfoState"
          right_arrow={!window.MCC.active_statuses.includes(window.MCC.game_status.status_id) ? 
            (window.MCC.status_types[window.MCC.game_status.status_id] && window.MCC.status_types[window.MCC.game_status.status_id].next ? 'StandByState' : 'NoConnectionState') 
            : 
          window.MCC.periodEnd ? 'EndOfPeriodState' : window.MCC.mainPage}
          id={this.props.id}
          team={this.props.team}
        />
      </div>
    );
  }
}

/*CSS Styles*/

const styles = {
  root: {
    width: '70%',
    justifyContent: 'space-around',
    display: 'flex',
    alignItems: 'center',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  adjust_field: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '1%',
    marginBottom: '1%',
    width: '100%',
    height: '8%',
  },
  adjust_buttons:{
    display: 'flex',
    border: '1',
    borderRadius: '5%',
    justifyContent: 'space-between',
    width: '40%',
  },
  adjust_smaller_buttons: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5%',
    border: '1',
    display: 'flex',
    height: '100%',
    width: '33.3%',
    fontWeight: 'bold',
    fontSize: '4.5vh',
  },
  overlay_none:{
    display: 'none'
  },      
  formControl: {
    margin: 2,
    width: '90%',
    maxWidth: 500,
    textTransform: 'capitalize'
  },
  textField: {
    margin: 2,
    width: '100%',
    maxWidth: 500,
  },
  flex : {
    height: '6.5%',
    width: '100%',
    display : 'flex',
    marginTop: '0.5%' ,
  },
  flex_column:{
    display : 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'absolute',
    overflow: 'hidden',
  },
  connection_buttons: {
    justifyContent: 'center',
    display : 'flex',
    marginTop: '3%',
    width: '100%',
    height: '9%',
  },
  call_me_field: {
    display: 'flex',
    height: '6%',
    justifyContent: 'center',
  },
  call_me_field_mobile: {
    paddingBottom: '10%',
    display: 'flex',
    height: '6%',
    justifyContent: 'center',
  },
  callme: {
    margin: 2,
    //height: '15%',
    width: '65%',
    color: 'red',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    borderRadius: 25,
  },
  reset: {
    height: '7%',
    width: '100%',
    borderRadius: 25,
    color: 'red',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    //marginTop:'1%',
  },
  reset_disabled: {
    height: '7%',
    borderRadius: 25,
    width: '100%',
    color: 'grey',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    //marginTop:'1%',
  },
  row: {
    display : 'flex',
    width: '100%',
    position:'absolute',
    bottom:0,
  },
  open_disabled: {
    margin: 1,
    width: '50%',
    borderRadius: 15,
    background: '#EEEEEE',
    color: '#918e8e',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    flexGrow: 1,
    opacity: 0.2,
  },
  info_box: {
    width : '100%',
    height: '13%',
    textAlign: 'center',
    //marginTop: '1%',
  },
  info_box_mobile: {
    display: 'none',
  },
  invisible: {
    display: 'none'
  },
  send_button: {
    borderRadius: 15,
    marginTop: 10,
  },
  close_game: {
    margin: 1,
    width: '50%',
    borderRadius: 15,
    background: '#80DEEA',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    flexGrow: 1,
  },
  open_game: {
    margin: 1,
    width: '50%',
    borderRadius: 15,
    background: '#0050ef',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    flexGrow: 1,
  },
  lock_button: {
    margin: 1,
    width: '50%',
    borderRadius: 15,
    fontWeight: 'bold',
    fontSize: '2.5vh',
    flexGrow: 1,
  },
  set_number: {
    color: '#78909c'
  },
  set_score: {
    color: '#0050ef',
    fontWeight: 'bold'
  },
  current_score: {
    color: '#00c853',
    fontWeight: 'bold',
    fontSize: 'larger'
  },
  default:{
    /* This is empty as it was required to have style */
  },
}

const overrideStyles = {
  root: {
    textTransform: 'capitalize' 
  } 
}

CommentState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(overrideStyles)(CommentState));