import React from 'react';
import Badge from '@material-ui/core/Badge';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import "material-design-icons/iconfont/material-icons.css";
import Button from '@material-ui/core/Button';
import {rubberBand} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { withTranslation } from 'react-i18next';

class NavBar extends React.Component {

  setTitle = () => {
    let str = (typeof window.MCC.status_types !== 'undefined' && 
      typeof window.MCC.game_status.status_id !== 'undefined' && 
      typeof window.MCC.status_types[window.MCC.game_status.status_id] !== 'undefined' 
      ? window.MCC.status_types[window.MCC.game_status.status_id].title 
      : 'Waiting for server status...');
    
    if (window.MCC.current_nocommand_state === 'NoConnectionState'){
      if (window.MCC.settings.callcenter_control === 'desktop' && window.MCC.platform === 'mobile_callcenter'){
        str = 'Mobile Locked';
      }
    
      if (window.MCC.connectionLost){
        str = 'Lost Connection';     
      }
    }  

    return str; 
  }

  state = { 
      status  : this.setTitle(),
      open: false,
      left: false,
      overlay_type : 'hide',
      connection_msg: '',
      mobile_control: window.MCC.settings.callcenter_control === 'mobile'  && window.MCC.platform === 'mobile_callcenter' ? true : false,
      admin_control : window.MCC.settings.callcenter_control === 'desktop' && window.MCC.platform === 'mobile_admin'      ? true : false
  }

  eventListener = e => {
    this.setState({
      status  : this.setTitle(),
      mobile_control: window.MCC.settings.callcenter_control === 'mobile'  && window.MCC.platform === 'mobile_callcenter' ? true : false,
      admin_control : window.MCC.settings.callcenter_control === 'desktop' && window.MCC.platform === 'mobile_admin'      ? true : false
    })
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  }

  /*handleDrawerOpen = () => {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    this.setState({ open: false });
  }*/

  setOverlayListener = e => {
    this.setState({
      overlay_type: 'overlay_invisible',
      status : (this.state.status === 'Waiting for server status...' ? 'Waiting for server status...' : (this.state.status === 'Mobile Locked' ? 'Mobile Locked' : 'Sending command...'))
    });

    window.MCC.timerOneID = setTimeout( () => {
      this.setState({
        overlay_type: 'overlay',
        connection_msg: 'Sending unsuccessful - Site will REFRESH after clicking OK!',
      });

      /*this.timerTwo = setTimeout( () => {
        this.setState({
        connection_msg: 'Call the Mobile hotline if issue continues without MO contact!',
      });
       
      }, 5000)*/
    }, 4000);
  }

  removeOverlayListener = e => {
    this.setState({
      overlay_type: 'hide',
      status  : this.setTitle()
    });

    clearTimeout(window.MCC.timerOneID);
    window.MCC.timerOneID = false;
  } 

  componentDidMount = () => {
    let element = document.getElementById('nav_bar');

    if (element != null) { 
      element.addEventListener('updateStatus', this.eventListener);
      element.addEventListener('setOverlay', this.setOverlayListener);
      element.addEventListener('removeOverlay', this.removeOverlayListener);
    }
  }

  componentWillUnMount = () => {
    let element = document.getElementById('nav_bar');

    element.removeEventListener(
      'updateStatus',
      false
    );

    element.removeEventListener(
      'setOverlay',
      false
    );

    element.removeEventListener(
      'removeOverlay',
      false
    );        
  };    

  render() {

  const {t} = this.props;

  const { open } = this.state;

  const sideList = (
      <div id="drawer_wrapper">
        <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.toggleDrawer('left', false)}
              className={classNames(styles.menuButton, open)}
            >
          <MenuIcon />
        </IconButton>
        <div id="nav_options" style={styles.nav_field}>
          <List>
            <ListItem button key="Match Info" 
                      style={window.MCC.periodEnd ? styles.div_disable : (this.state.mobile_control && window.MCC.report.prematch_step3_confirmed ? styles.show : (this.state.admin_control ? styles.show : styles.div_disable))}
                      onClick = {() => {
                        this.props.setPage('MatchInfoState', {id: this.props.id, team: this.props.team})
                        window.MCC.current_nocommand_state = 'MatchInfoState'
                      }}>
                      <i className="material-icons">info</i>
                      <ListItemText primary={t('match info')} />
            </ListItem>

            <ListItem button key="Comments"
                      style={window.MCC.periodEnd ? styles.div_disable : (this.state.mobile_control && window.MCC.report.prematch_step3_confirmed ? styles.show : (this.state.admin_control ? styles.show : styles.div_disable))}
                        onClick = {() => {
                          this.props.setPage('CommentState', {id: this.props.id, team: this.props.team})
                          window.MCC.current_nocommand_state = 'CommentState'
                        }}>
                      <i className="material-icons">sort</i>
                      <ListItemText primary={t('comments')} />
            </ListItem>
            <ListItem button key="Main Page"
                      style={window.MCC.periodEnd ? styles.div_disable : (window.MCC.active_statuses.includes(window.MCC.game_status.status_id) ? styles.show : styles.div_disable)}
                      onClick = {() => {
                        this.props.setPage(window.MCC.mainPage, {id: this.props.id, team: this.props.team})
                        window.MCC.current_nocommand_state = window.MCC.mainPage;
                      }}>
                      <i className="material-icons">home</i>
                      <ListItemText primary={t('main page')} />
            </ListItem>
            <ListItem button key="Full Log"
                      style={window.MCC.periodEnd ? styles.div_disable : (this.state.mobile_control  ? styles.show : (this.state.admin_control ? styles.show : styles.div_disable))}
                      onClick = {() => {
                        this.props.setPage('CommandLogState', {id: this.props.id, team: this.props.team})
                        window.MCC.current_nocommand_state = 'CommandLogState'
                      }}>
                      <i className="material-icons">assignment</i>
                      <ListItemText primary={t('full log')} />
            </ListItem>
        </List>
        <Divider />
        </div>
        
        <div id="message_field" style={styles.msg_log}>
            {/*<ListItem button key="Messages">
              <i className="material-icons">forum</i>
              <ListItemText primary="Messages" />
            </ListItem>*/}
            <List className="message_field_in" component="nav" style={{paddingTop: 0}}>
              {window.MCC.message_log_array}
            </List>
        </div>
      </div>
    );

    return (
		  <div className="nav_bar" id="nav_bar" style={(this.state.status === 'Waiting for server status...' || this.state.status === 'Lost Connection' || this.state.status === 'Mobile Locked') ? styles.nav_bar_offline : styles.nav_bar}>
      <div id="drawer" style={this.state.overlay_type === 'overlay'                   || 
                                this.state.status === 'Mobile Locked'                 || 
                                this.state.status === 'Lost Connection'               ||
                                this.state.status === 'Waiting for server status...'  || 
                                (window.MCC.settings.callcenter_control === 'mobile'  && window.MCC.platform === 'mobile_admin') || 
                                (window.MCC.settings.callcenter_control === 'desktop' && window.MCC.platform === 'mobile_callcenter')
                                ? styles.drawer_offline : styles.drawer
                              }>
          <div id="toolbar">

            <Toolbar disableGutters={!open}>
              <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.toggleDrawer('left', true)}
                  className={classNames(styles.menuButton, open)}
                >
                <MenuIcon />
              </IconButton>
            </Toolbar>

            <SwipeableDrawer
              open={this.state.left}
              onClose={this.toggleDrawer('left', false)}
              onOpen={this.toggleDrawer('left', true)}
              swipeAreaWidth={5}
            >
              <div tabIndex={0} role="button"  
                    onKeyDown={this.toggleDrawer('left', false)}>
                {sideList}
              </div>
            </SwipeableDrawer> 
            </div>

          <div id="badge">
            <Badge color="secondary" badgeContent={window.MCC.message_settings.unanswered_messages} style={window.MCC.message_settings.unanswered_messages === 0 ? styles.hide : styles.show}>
             <span></span>
            </Badge>
          </div>
        </div>

        <div id="info_text" style={styles.info_text}>
          {this.state.status === 'Waiting for server status...' ? 
            <StyleRoot><div style={styles.reactAnimation}>{t('Waiting for server status...')}</div></StyleRoot> : (this.state.status === 'Mobile Locked' ? <StyleRoot><div style={styles.reactAnimation}>{t('Mobile Locked')}</div></StyleRoot> : t(this.state.status.toLowerCase()))}
        </div>
        
        <div style={styles[this.state.overlay_type]}>
          
          <div style={styles.connection_msg_field}>
            {t(this.state.connection_msg)}
          </div>

          <Button variant="contained" color="primary" 
                  style={styles.button_ok}
                  onClick={ 
                    ()=> {this.setState({
                            overlay_type: 'hide',
                            status  : this.setTitle()
                          });
                          // Manually refresh after OK is clicked! - SRT - 937
                          clearTimeout(window.MCC.timerOneID);
                          window.MCC.timerOneID = false;
                          window.location.reload();
                          //clearTimeout(this.timerTwo)
                        }}
            >{t('ok')}
          </Button>
        </div>
      </div>
	  );
  }
}

const styles = {
   wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  overlay:{
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    opacity: 0.8,
    background: '#000000',
    zIndex: 1,
    flexDirection: 'column'
  },
   overlay_invisible:{
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    background: '#000000',
    zIndex: 1,
    flexDirection: 'column'
  },
  button_ok:{
    width: '50%',
    height: '10%',
    borderRadius: 17,
    fontSize: '5vh',
    fontWeight: 'bold',
  },
	nav_bar: { 
  	height : '4%',
  	width : '100%',
  	background: '#00C853',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    color: 'white',
    fontSize: '3vh',
    fontWeight: 'bold',
  },
  nav_bar_offline: {
    height : '4%',
    width : '100%',
    background: '#d50000',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    color: 'white',
    fontSize: '2.5vh',
    fontWeight: 'bold',
  },
  drawer : {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    zIndex: 3,
  },
  drawer_offline: {
    display: 'none',
    justifyContent: 'start',
    alignItems: 'center',
    zIndex: 0,
  },
  info_text : {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    textTransform: 'capitalize'
  },
  icon: {
    position: 'absolute',
    top: '0%',
    right: '0%',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'flex',
  },
  msg_log: {
    //maxWidth: '45vh',
    //height: '50%',
    //overflowX: 'hidden',
  },
  message_title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  div_disable: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  connection_msg_field: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: '10%',
    fontFamily: 'Rubik',
    fontSize: '5vh',
  },
  reactAnimation: {
    animation: 'x 2.5s',
    animationName: Radium.keyframes(rubberBand, 'rubberBand'),
    textTransform: 'capitalize'
  }
}

export default withTranslation()(NavBar);
