import React from 'react';
import { socket } from '../App';
import Button from '@material-ui/core/Button';
import Radium, {StyleRoot} from 'radium';
import {flash} from 'react-animations';
import { withTranslation } from 'react-i18next';

class OpenCloseGame extends React.Component {

  /*Emit command object*/
  sendCommand = (key, team, confirmed) => {
    
    let cmdObj = {
      key: key,
      confirmed: confirmed,
      team: team,
      mcc_id: Date.now(),
    }

    if (key === 'status_unsure' && window.MCC.platform === 'mobile_admin') {
      cmdObj.value = 'adminlock';
    }
   
    socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
  }
        
	render () {
    const {t} = this.props;
		return (
      <StyleRoot style={window.MCC.open_game.open === false ? styles.close_open_field_blink : styles.close_open_field}>
        <Button 
            id="open_close_game"
            variant={window.MCC.open_game.adminlock ? 'outlined' : 'contained'}
            onClick = {() => this.sendCommand(window.MCC.open_game.open ? 'status_unsure' : 'status_ok', 0, 1)} 
            disabled={(window.MCC.platform === 'mobile_callcenter' && window.MCC.open_game.adminlock === true) ? true : false}
            style={this.props.showButton ? ((window.MCC.platform === 'mobile_callcenter' && window.MCC.open_game.adminlock === true) ? styles.disabled : (window.MCC.open_game.open ? styles.open_game : styles.close_game)) : styles.invisible}
        >
            {window.MCC.open_game.open ? t('close game') : t('open game')}
        </Button>
      </StyleRoot>
			);
	}
}

const styles = {
  close_open_field: {
    display: 'flex',
    margin: 1,
    width: '50%',
    borderRadius: 10,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh',
  },
  close_open_field_blink: {
    display: 'flex',
    margin: 1,
    width: '50%',
    borderRadius: 10,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh',
    animation: 'infinite 2s',
    animationName: Radium.keyframes(flash, 'flash'),
  },
  close_game: {
    margin: 1,
    width: '100%',
    borderRadius: 10,
    background: '#80DEEA',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh',
  },
  open_game: {
    margin: 1,
    width: '100%',
    borderRadius: 10,
    background: '#0050ef',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh'
  },
  invisible: {
    display: 'none'
  },
  disabled: {
    margin: 1,
    width: '100%',
    borderRadius: 10,
    fontWeight: 'bold',
    fontSize: '2.8vh'
  }
}

export default withTranslation()(OpenCloseGame);