import React from 'react';
import Button from '@material-ui/core/Button';
import { socket } from '../../App'
import BottomRow from '../BottomRow';
import NavBar from '../NavBar';
import ResultStatsField from '../ResultStatsField';
import OpenCloseGame from '../OpenCloseGame';
import { withTranslation } from 'react-i18next';


class ChallengeTypeState extends React.Component {

	constructor(props) {
    super(props);
    this.state = { 
      points  : typeof window.MCC.stats.running.points !== 'undefined' ? window.MCC.stats.running.points : [0,0],
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0]
    };
  }

  sendCommand = (key, confirmed, value) => {

	  let cmdObj = {
	    key: key,
	    team: this.props.team,
      confirmed: confirmed,
	    mcc_id: Date.now()
    }

    if (typeof this.props.id !== 'undefined' && confirmed === -1){
      cmdObj.id = this.props.id;
    }    

	  socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
	}

	render () {
    const {t} = this.props;
		return (
		<div className="wrapper" id="ChallengeTypeState" style={styles.wrapper}>
          <NavBar setPage={this.props.setPage} disabled={this.props.disabled}/>
		     	<div className="result_field" style={styles.result_field_flex}>
	        	<ResultStatsField current_page='ChallengeTypeState' current_team="0" mirror={true}/>

	        	<Button 
                id="challenge_type"
                variant="contained" 
                disabled 
                style={styles.challenge}
            >
		            {t('challenge type')}
		        </Button>

	        	<ResultStatsField current_page='ChallengeTypeState' current_team="1" mirror={false}/>
		      </div>

		  		<div className="button_types" style={styles.point_types_field}> 
		  			<div className="first_row" style={styles.flex}>
  						<Button 
                  id="won"
                  variant="contained" 
                  onClick = {() => this.sendCommand('challenge_won', 1)} 
                  style={styles.button}
              >
                  {t('won')}
		        	</Button>
              <Button 
                  id="point_replay"
                  variant="contained" 
                  onClick = {() => this.sendCommand('point_replay', 1)} 
                  style={styles.button}
              >
                  {t('point replay')}
              </Button>
		        	<Button 
                  id="lost"
                  variant="contained" 
                  onClick = {() => this.sendCommand('challenge_lost', 1)} 
                  style={styles.button}
              >
                  {t('lost')}
		        	</Button>
		  			</div>
            <div style={Object.assign({marginTop: '1%'},styles.cancel_field)}>
              <Button 
                  id="cancel"
                  variant="outlined" 
                  style={styles.cancel_button} 
                  onClick = {() => this.sendCommand('challenge', -1)}
              >
                  {t('cancel')}
              </Button>
            </div>  
		  		</div>
		      	
		    	<div className="close_open_game" style={styles.close_open_game_field}>
		     	  <OpenCloseGame showButton="true"/>
		     	</div> 

			    <BottomRow 
            setPage={this.props.setPage} 
            left_arrow="CommentState" 
            right_arrow="CommandLogState"
            id={this.props.id}
            team={this.props.team}            
          />
    		</div> 
		);
	}
}	 

const styles = {
	wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },  
  nav_bar: {
    height : '4%',
    width : '100vw',
    background: 'linear-gradient(#00FF00, #8d6e63)',
    textAlign : 'center' ,
  },
  flex: {
  	display : 'flex',
  	height: '50%',
  	justifyContent:'center',
  },
  cancel_field:{
    display : 'flex',
    height: '40%',
    justifyContent:'center',
  },
  result_field_flex: {
    display : 'flex',
    width: '100%',
    height: '13%',
  },
  point_types_field: {
  	display: 'flex',
  	flexDirection: 'column',
  	height: '30%',
  },
  result: {
    margin: 1,
    width: '33.3%',
    borderRadius: 5,
    flexGrow: 1,
    fontSize: '2.8vh',
  },
  button: {
	  margin: 1,
	  width: '33.3%',
    borderRadius: 5,
    background: '#4CAF50',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  cancel_button: {
  	margin: 2,
    borderRadius: 15,
    color: 'red',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  challenge: {
    margin: 1,
    width: '35%',
    borderRadius: 5,
    background: '#FB8C00',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    textTransform: 'capitalize'
  },
  close_open_game_field: {
  	display: 'flex',
  	justifyContent: 'center',
	  width: '100%',
	  height: '13%',
	  marginTop: '0.6%',
    position:'absolute',
    bottom:'14%',
  },
  last_row: {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '14%',
    position:'absolute',
    bottom:0,
  }
};

export default withTranslation()(ChallengeTypeState);