import React from 'react';
import Button from '@material-ui/core/Button';
import { socket } from '../../App';


class ConfirmButton extends React.Component{

    render() {  
        return (
            <Button 
                variant="outlined" 
                data-testid="confirm_button"
                id="confirm"
                disabled={typeof this.props.state !== 'undefined' && typeof this.props.state.confirm !== 'undefined' ? !this.props.state.confirm : true} 
                color="secondary" 
                style={this.props.state && this.props.state.confirm ? (this.props.state.confirm ? styles.confirm : styles.confirm_disabled) : styles.confirm_disabled}
                onClick = {() => {
                    if (this.props.reportType === 'prematch_step1_confirmed' && socket) {
                        socket.emit('report', {'prematch_step1_confirmed' : 'yes'});
                    }
                    else if (this.props.reportType === 'prematch_step2_confirmed' && socket) {
                        socket.emit('report', {'prematch_step2_confirmed' : 'yes'});
                    }
                    else if (this.props.reportType === 'prematch_step3_confirmed' && socket) {
                        socket.emit('report', {'prematch_step3_confirmed' : 'yes'});
                    }

                    window.MCC.sendVerification('steps');
                    
                    // can not use prop 'preMatch' direct in one line code !!! 
                    if (this.props.preMatch === 'VerOneState' && this.props.setPage) {
                        this.props.setPage('VerTwoState', {id: this.props.id});
                    }
                    else if (this.props.preMatch === 'VerTwoState' && this.props.setPage) {
                        this.props.setPage('VerThreeState', {id: this.props.id});
                    }
                    else if (this.props.preMatch === 'VerThreeState' && this.props.setPage) {
                        this.props.setPage('MatchInfoState', {id: this.props.id});
                    }
                }}> {this.props.text}
            </Button>
        )
    }
}

const styles = {
    confirm: {
        margin: 2,
        height: 60,
        width: '65%',
        color: 'green',
        fontWeight: 'bold',
        fontSize: '3.3vh',
        borderRadius: 25,
      },
      confirm_disabled: {
        margin: 2,
        height: 60,
        width: '65%',
        color: 'grey',
        fontWeight: 'bold',
        fontSize: '3.3vh',
        borderRadius: 25,
      },
}


export default ConfirmButton;
