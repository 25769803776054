import React from 'react';
import Button from '@material-ui/core/Button';
import { socket } from '../../App'
import BottomRow from '../BottomRow';
import NavBar from '../NavBar';
import ResultStatsField from '../ResultStatsField';
import OpenCloseGame from '../OpenCloseGame';
import { withTranslation } from 'react-i18next';

class PointTypeState extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      points  : typeof window.MCC.stats.running.points !== 'undefined' ? window.MCC.stats.running.points : [0,0],
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0]
    };
  }

  sendCommand = (key, confirmed, team, value) => {

    let cmdObj = {
      key: key,
	    team: team !== 0 && typeof team !== 'undefined' ? team : window.MCC.last_unconfirmed_point.team,
	    confirmed: confirmed,
	    mcc_id: Date.now()
	  }

    if (typeof this.props.id !== 'undefined' && cmdObj.key !== 'status_unsure' && cmdObj.key !== 'status_ok'){
      cmdObj.id = this.props.id;
    }

    if (typeof this.props.id === 'undefined' && cmdObj.key !== 'status_unsure' && cmdObj.key !== 'status_ok'){
      cmdObj.id = window.MCC.last_unconfirmed_point.id;
    }

    if (typeof value !== 'undefined'){
      cmdObj.value = {type: value};
    }   	  
    
	  socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
	}

	render () {
    const {t} = this.props;
		return (
		<div className="wrapper" id="PointTypeState" style={styles.wrapper}>
      		<NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>

		     	<div className="result_field" style={styles.result_field_flex}>
		        	<ResultStatsField current_page='PointTypeState' current_team="0" mirror={true}/>

		        	<Button 
                  variant="contained" 
                  disabled 
                  style={styles.point}
              >
			          {t('point type')}
			        </Button>

		        	<ResultStatsField current_page='PointTypeState' current_team="1" mirror={false}/>
		      	</div>

		  		<div className="button_types" style={styles.point_types_field}> 
		  			<div className="first_row" style={styles.flex}>
						    <Button 
                    id="block"
                    variant="contained" 
                    onClick = {() => this.sendCommand('point', 0, this.props.team, 'block')} 
                    style={styles.button}
                >
                    {t('block')}
			        	</Button>
			        	<Button 
                    id="kill"
                    variant="contained" 
                    onClick = {() => this.sendCommand('point', 0, this.props.team, 'kill')} 
                    style={styles.button_contrast}
                >
                    {t('kill')}
			        	</Button>
			        	<Button 
                    id="out"
                    variant="contained" 
                    onClick = {() => this.sendCommand('point', 0, this.props.team, 'out')} 
                    style={styles.button}
                >
                    {t('out')}
			        	</Button>

		  			</div>
		  			<div className="second_row" style={styles.flex}>
						    <Button 
                    id="block_out"
                    variant="contained" 
                    onClick = {() => this.sendCommand('point', 0, this.props.team, 'block_out')} 
                    style={styles.button_contrast}
                >
                    {t('block out')}
			        	</Button>
			        	<Button 
                    id="ace"
                    variant="contained" 
                    onClick = {() => this.sendCommand('point', 0, this.props.team, 'ace')} 
                    style={styles.button}
                >
                    {t('ace')}
			        	</Button>
			        	<Button 
                    id="serve_error"
                    variant="contained" 
                    onClick = {() => this.sendCommand('point', 0, this.props.team, 'serve_error')} 
                    style={styles.button_contrast}
                >
                    {t('serve error')}
			        	</Button>
		  			</div>
		  			<div className="third_row" style={styles.flex}>
						    <Button 
                    id="error"
                    variant="contained" 
                    onClick = {() => this.sendCommand('point', 0, this.props.team, 'error')} 
                    style={styles.button}
                >
                    {t('error')}
			        	</Button>
			        	<Button 
                    id="penalty"
                    variant="contained" 
                    onClick = {() => this.sendCommand('point', 0, this.props.team, 'penalty')} 
                    style={styles.button_contrast}
                >
                    {t('penalty')}
			        	</Button>
			        	<Button 
                    id="cancel"
                    variant="outlined" 
                    style={styles.cancel_button} 
                    onClick = {() => this.sendCommand('point' , -1, this.props.team)}
                >
                    {t('cancel')}
					      </Button>
		  			</div>
		  		</div>
		      	
		    	<div className="close_open_game" style={styles.close_open_game_field}>
		     		<OpenCloseGame showButton="true"/>
		     	</div> 

			    <BottomRow 
            setPage={this.props.setPage} 
            left_arrow="CommentState" 
            right_arrow="CommandLogState" 
            id={this.props.id}
            team={this.props.team}              
          />
    		</div> 
		);
	}
}	 

const styles = {
	wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },  
  nav_bar: {
    height : '4%',
    width : '100vw',
    background: 'linear-gradient(#00FF00, #8d6e63)',
    textAlign : 'center' ,
  },
  flex: {
	  display : 'flex',
	  height: '33.3%',
	  justifyContent:'center',
  },
  result_field_flex: {
    display : 'flex',
    width: '100%',
    height: '13%',
  },
  point_types_field: {
	  display: 'flex',
	  flexDirection: 'column',
	  height: '50%',
	},
  button: {
	  margin: 1,
	  width: '33.3%',
    borderRadius: 5,
    background: '#4CAF50',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  button_contrast: {
    margin: 1,
    width: '33.3%',
    borderRadius: 5,
    background: '#66BB6A',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  cancel_button: {
	  margin: 1,
	  width: '33.3%',
    borderRadius: 5,
    color: 'red',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  point: {
    margin: 1,
    width: '35%',
    borderRadius: 5,
    background: '#FB8C00',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
  },
  close_open_game_field: {
  	display: 'flex',
  	justifyContent: 'center',
	  width: '100%',
	  height: '13%',
	  marginTop: '0.6%',
    position:'absolute',
    bottom:'14%',
  }
};

export default withTranslation()(PointTypeState);