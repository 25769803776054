import React from 'react';
import Button from '@material-ui/core/Button';
import NavBar from '../NavBar';
import { withTranslation } from 'react-i18next';

class EditDeleteState extends React.Component {

  state = {
  	command_name : window.MCC.commands[this.props.id].key,
  	command_type : window.MCC.commands[this.props.id].value && window.MCC.commands[this.props.id].value.type ? window.MCC.commands[this.props.id].value.type : '',
  	team_text    : window.MCC.commands[this.props.id].team === 1 ? ' [H] ' : (window.MCC.commands[this.props.id].team === 2 ? ' [A] ' : ' '),
  	team         : window.MCC.commands[this.props.id].team === 1 ? 'home' : 'away',
  	timestamp    : this.props.timestamp
  }

  	render () {
	const {t} = this.props;
    return ( 
    	<div className='wrapper' style={styles.wrapper}>
				<NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
	    		<div style={styles.info_text} >
					<p>{t('command -')}</p>
				</div>

				<div>
					<p style={Object.assign({textTransform: 'uppercase' , textAlign: 'center' , fontSize: '1.3em' , fontWeight: 'bold'})} >
					{this.props.timestamp} #{this.props.id} {t(this.state.team_text)} {t(this.state.command_name)} {t(this.state.command_type)}</p>
				</div>
				
				<div style={styles.flex}>
					<Button 
						id="edit"
						variant="contained" 
						style={Object.assign({background: '#4CAF50'}, styles.divs , styles.point)}
						onClick = {() => {
							this.props.setPage('EditConfirmState' , {timestamp: this.state.timestamp , game_time: this.state.game_time , id: this.props.id , team_text: this.state.team_text , command_name: this.state.command_name, command_type: this.state.command_type , team : this.props.team});
	                	}}
					>
						{t('edit')}
					</Button>

					<Button 
						id="delete"
						variant="contained" 
						style={window.MCC.platform === 'mobile_callcenter' && window.MCC.commands[this.props.id].status_id !== window.MCC.game_status.status_id ? Object.assign({background: '#EEEEEE'}, styles.divs , styles.point) : Object.assign({background: '#df2029'}, styles.divs , styles.point)}
						disabled={window.MCC.platform === 'mobile_callcenter' && window.MCC.commands[this.props.id].status_id !== window.MCC.game_status.status_id ? true : false}
						onClick = {() => {
							this.props.setPage(
								'DeleteConfirmState',{
										timestamp 		: this.props.timestamp,
										game_time 		: this.props.game_time,
										id 		        : this.props.id,
										back_state 		: 'EditDeleteState',
										back_back_state : this.props.back_state,
										team 			: this.props.team
								}
							);
						}}
					>
						{t('delete')}
					</Button> 

					<Button 
						id="back"
						variant="contained" 
						style={Object.assign({background: '#0084ff'}, styles.divs , styles.point)}
						onClick = {() => {
							//window.MCC.return_to_main_state ? this.props.setPage('MainState', {id: this.props.id} ) : this.props.setPage('CommandLogState', {id: this.props.id});
							window.MCC.return_to_main_state ? 
							this.props.setPage('MainState', {id: window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.id : window.MCC.back_id , team: window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.team : window.MCC.back_team}) 
							: 
							this.props.setPage('CommandLogState', {id: window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.id : window.MCC.back_id , team: window.MCC.last_unconfirmed_point ? window.MCC.last_unconfirmed_point.team : window.MCC.back_team});
							window.MCC.is_command_log_delete = false;
						}}
					>
						{t('back')}
					</Button>
				</div>

    	</div>
    );
	}
}

const styles = {
	wrapper: {
		position: 'absolute',
	    height: '100%',
	    width: '100%',
	    display : 'flex',
	    flexDirection: 'column',
	},
	nav_bar: {
	    height : '4%',
	    width : '100%',
	    background: 'linear-gradient(#00FF00, #8d6e63)',
	    textAlign : 'center' ,
	},
	point : {
	    color: 'white',
	    fontWeight : 'bold',
	    fontSize: '1.3em',
	},
	divs: {
		flexGrow: 1,
		height: '25%',
		width: '70%',
		borderRadius: '15px',
		margin: 3,
	},
	flex : {
		display : 'flex',
		flexDirection: 'column',
		height: '30%',
		paddingTop: '20%',
		alignItems: 'center',
	},
	info_text: {
		textAlign: 'center', 
		fontSize: '1em', 
		fontWeight: 'bold',
		textTransform: 'capitalize'
	}
}	

export default withTranslation()(EditDeleteState);