import React from 'react';
import Button from '@material-ui/core/Button';
import NavBar from '../NavBar';
import { socket } from '../../App';
import { withTranslation } from 'react-i18next';

class EndOfPeriodState extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      points  : typeof window.MCC.game_status.status_id !== 'undefined' ? 
      (window.MCC.active_statuses.indexOf(window.MCC.game_status.status_id) !== -1 ? 
      window.MCC.game_status.status_id :
      window.MCC.active_statuses[window.MCC.endOfPeriod_statuses.indexOf(window.MCC.game_status.status_id)]) : [0,0],
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0],
      next_status_id : typeof window.MCC.stats.running.setswon !== 'undefined' && (window.MCC.stats.running.setswon[0] >= 3 || window.MCC.stats.running.setswon[1] >= 3) ? (window.MCC.endOfPeriod_statuses.includes(window.MCC.commands[this.props.id].status_id) ? 6 : window.MCC.status_types[window.MCC.commands[this.props.id].status_id].next) : (window.MCC.game_status.next_status_id ? window.MCC.game_status.next_status_id : window.MCC.status_types[window.MCC.commands[this.props.id].status_id].next),
      current_status_id : window.MCC.commands[this.props.id].status_id
    };
  }  

  sendCommand = (key, team) => {

    let cmdObj = {
      key: key,
      mcc_id: Date.now()
    }

    if (key === 'status') {
      cmdObj.value = this.state.next_status_id;

      if (this.state.next_status_id === 28){
        cmdObj.confirmed = 0;
      }
    }

    if (key === 'status_unsure') {
      cmdObj.team = team;
      cmdObj.confirmed = 1;
      cmdObj.value = 'adminlock';
    }

    socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
  };

  eventListener = e => {
    const status = window.MCC.active_statuses.indexOf(window.MCC.game_status.status_id) !== -1 ? 
                    window.MCC.game_status.status_id :
                    window.MCC.active_statuses[window.MCC.endOfPeriod_statuses.indexOf(window.MCC.game_status.status_id)];
    this.setState({
      points  : window.MCC.score['status_' + status].slice(0),
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0]
    })
  };

  componentDidMount = () => {
    let element = document.getElementById('EndOfPeriodState');
    element.addEventListener('updateStats', this.eventListener);
  }

  componentWillUnMount = () => {
    let element = document.getElementById('EndOfPeriodState');
    element.removeEventListener(
      'updateStats',
      false
    );
  };        

  render () {
    const {t} = this.props;
    
    return (
			<div className="wrapper" id="EndOfPeriodState" style={styles.wrapper}>
            <NavBar setPage={this.props.setPage} disabled={this.props.disabled}/>
            <div className="status_and_score_field" style={styles.status_and_score_field}>
              <div className="field" style={styles.field}>{t('confirm changing status to - ')}</div>
              <div className="field" style={Object.assign({fontWeight: 'bold'},styles.field)}>{t(window.MCC.status_types[this.state.next_status_id].title.toLowerCase())}</div>
              <div className="field" style={styles.field}>{t(window.MCC.status_types[this.state.current_status_id].title.toLowerCase())} {t('score')}: {this.state.points[0]} - {this.state.points[1]}</div>
  		     	 	<div className="field" style={styles.match_score}>{t('match score - ')} {this.state.setswon[0]} - {this.state.setswon[1]}</div>
            </div>

		      		<div style={styles.flex_column}>
                <div style={styles.confirm_back_buttons}>
  		      			<Button 
                      id="yes_confirm"
                      variant="contained" 
                      color="primary" 
                      style={styles.yes_button} 
                      onClick = {() => {
                        this.sendCommand('status');
                        if ((this.state.setswon[0] >= 3 || this.state.setswon[1] >= 3) && this.state.current_status_id !== 27){
                            this.setState({next_status_id: 6});
                        }
                      }}
                  >
  			          		{t('yes - confirm')}
  			        	</Button>

			        	<Button 
                    id="no_back"
                    variant="contained" 
                    color="secondary" 
                    style={styles.no_button} 
                    onClick = {() => {
                      if (window.MCC.open_game.open) {
                        this.sendCommand('status_unsure', 0);
                      }

                      if (this.state.next_status_id !== 6){
                        window.MCC.current_nocommand_state = 'CommandLogState';
                        window.MCC.mainPage = window.MCC.platform === 'mobile_admin' ? 'CommentState'  :  'EndOfPeriodState';
                        //window.MCC.current_state = window.MCC.mainPage;
                        this.props.setPage('CommandLogState', {id: this.props.id, team: this.props.team});
                      }else{
                        this.props.setPage('StandByState', {finished: true, id: this.props.id, team: this.props.team});    
                      }  
                    }}
                >
			          		{t('no - back')}
			        	</Button>
		      		</div>
		      	</div>
		      	
    		</div>

		);
	}
}

const styles = {
  wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },
  confirm_back_buttons : {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '60%',
    marginTop: '10%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flex_column : {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '45%',
    flexDirection: 'column',
  },
  button: {
    margin: 2,
    height: '15%',
    borderRadius: 3,
    background: 'linear-gradient(#00c853, #0ae063)',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  yes_button : {
    margin: '1%',
    height: '100%',
    borderRadius: 15,
    background: '#4CAF50',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
    width: '60%',
  },
  no_button : {
    margin: '1%',
    height: '100%',
    borderRadius: 15,
    background: '#df2029',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
    width: '60%',
  },
  status_and_score_field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '55%',
  },
  field: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '3.7vh',
    textTransform: 'capitalize'
  },
  match_score: {
    display : 'flex', 
    justifyContent : 'center', 
    alignItems : 'center',
    fontSize: '3.7vh',
    textTransform: 'capitalize'
  }

};


export default withTranslation()(EndOfPeriodState);


