import React from 'react';
import Button from '@material-ui/core/Button';
import NavBar from '../NavBar';
import { socket } from '../../App';
import { withTranslation } from 'react-i18next';

class ConfirmFinishedState extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      confirmed  : false,
      next_status_id : 6
    };
  }

  sendCommand = (value) => {

    let cmdObj = {
      key: 'status',
      mcc_id: Date.now(),
      value: value
    }

    if (value === 6){
      cmdObj.id = this.props.id;
      cmdObj.confirmed = 1;
    }    

    socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
  };    

	render () {
    const {t} = this.props;
    return (
			<div className="wrapper" id="ConfirmState" style={styles.wrapper}>
          <NavBar setPage={this.props.setPage} disabled={this.props.disabled}/>
          <div className="result_field" style={styles.flex}>
		        	{t(this.state.confirmed ? (this.state.next_status_id === 6 ? 'Confirm the match is finished?' : 'Confirm the match is going to Golden Set?') : 'Is the match really finished?')}
			    </div>

		      	<div className="content" style={styles.flex_column}>

		      		<div style={styles.buttons_field}>
		      			<Button 
                    id={this.state.confirmed ? "yes_confirm" : "yes_finished"}
                    variant="contained" 
                    color="primary" 
                    style={styles.yes_button} 
                    onClick = {() => {
                      if (this.state.confirmed){
                        this.sendCommand(this.state.next_status_id);
                      }else{
                        this.setState({confirmed: true, next_status_id: 6});
                      } 
                    }}
                >
			          		{this.state.confirmed ? t('yes - confirm') : t('yes - finished')}
			        	</Button>

			        	<Button 
                    id={this.state.confirmed ? "no_go_back" : "golden_set"}
                    variant="contained" 
                    color="secondary" 
                    style={styles.no_button} 
                    onClick = {() => {
                      if (this.state.confirmed){
                        this.setState({confirmed: false});
                      }else{
                        this.setState({confirmed: true, next_status_id: 26});
                      }                           
                    }}
                >
			          		{this.state.confirmed ? t('no - go back') : t('golden set')}
			        	</Button>
		      		</div>
		      	</div>
    		</div>

		);
	}
}

const styles = {
	wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },
  flex: {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '13%',
    justifyContent:'center',
    alignItems: 'center',
    fontSize: '3vh',
    fontWeight: 'bold',
  },
  buttons_field : {
	  display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '40%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flex_column : {
	  display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '50%',
    flexDirection: 'column',
    marginTop: '5%',
  },
  yes_button : {
  	margin: 2,
	  height: '100%',
    borderRadius: 15,
    background: '#4CAF50',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    justifyContent: 'center',
    width: '70%',

  },
  no_button : {
  	margin: 2,
	  height: '100%',
    borderRadius: 15,
    background: '#df2029',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    justifyContent: 'center',
    width: '70%',
    marginTop: '5%',
  },
  confirm_command: {
    margin: 2,
    width: '30%',
    borderRadius: 3,
    background: 'linear-gradient(#ff9100, #ffa733)',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh',
  }
};


export default withTranslation()(ConfirmFinishedState);