import React from 'react';
import BottomRow from './BottomRow';
import Button from '@material-ui/core/Button';
import OfflineScoringRow from './OfflineScoringRow';
import NavBar from './NavBar';
import CallMe from './CallMe';
import {tada} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { withTranslation } from 'react-i18next';

class NoConnectionState extends React.Component {

	setLastCommand = () => {
		if (typeof this.props !== 'undefined') {
			const t = this.props.t;	
			let str = typeof window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1] === 'undefined' ? '' :
					((window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].team ? t(window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].team.toLowerCase()).toUpperCase() : '') + ': ' +
					(window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].key  ? t(window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].key.toLowerCase()).toUpperCase() : '') + ' ' +
					(window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].key === 'POINT' ? (window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].team === 'HOME' ? window.MCC.offline_score[0] : window.MCC.offline_score[1]) : '') + ' at ' +
					(window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].time ? window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].time : '') + ' ' +
					(window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].key === 'POINT' ? window.MCC.offline_score[0] : '') +
					(window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].key === 'POINT' ? ' - ' : '') +
					(window.MCC.offline_scoring_data[window.MCC.offline_scoring_data.length - 1].key === 'POINT' ? window.MCC.offline_score[1] : ''))
			
			return str;
		}
	}

	constructor() {
		super();
		window.MCC.setOfflineScoringData();
		this.state = {
			last_command: this.setLastCommand(),
			show: false,
		}
	}

	setItem = (key, team) => {
		let time = new Date().toTimeString().split(' ')[0];
		window.MCC.offline_scoring_data.push({key: key, team: team, time: time});
		if (key === 'POINT'){
			if (team === 'HOME'){
                window.MCC.offline_score[0]++;
            }else{
                window.MCC.offline_score[1]++;
            }
			window.MCC.offline_scoring_array.unshift(<OfflineScoringRow key={window.MCC.offline_scoring_data.length-1} id={window.MCC.offline_scoring_data.length-1} point={team === 'HOME' ? window.MCC.offline_score[0] : window.MCC.offline_score[1]}/>);
		}else{
			window.MCC.offline_scoring_array.unshift(<OfflineScoringRow key={window.MCC.offline_scoring_data.length-1} id={window.MCC.offline_scoring_data.length-1} />);
		}
		localStorage.setItem('volleyball_offline_scoring', JSON.stringify(window.MCC.offline_scoring_data));
		this.setState({last_command: this.setLastCommand()});
	}

	removeItem = () => {
		window.MCC.offline_scoring_data = [];
		window.MCC.offline_scoring_array = [];
		window.MCC.offline_score = typeof localStorage.getItem('volleyball_current_score') !== 'undefined' && localStorage.getItem('volleyball_current_score') !== null ? JSON.parse(localStorage.getItem('volleyball_current_score')) : [0,0];
		localStorage.removeItem('volleyball_offline_scoring');
		this.setState({last_command: this.setLastCommand()});	
	}

	render () {
		const {t} = this.props;
	    return(
	        <div id="wrapper" style={styles.wrapper}>
				<NavBar setPage={this.props.setPage}/>
				<div className="first_row" style={styles.row}>
					<Button 
						id="point_home"
						variant="contained" 
						style={Object.assign({},styles.buttons , styles.points_offline)}
						onClick={() => {this.setItem('POINT', 'HOME');}}
					>
						{t('home point')}
					</Button>
					<Button
						id="clear_log" 
						variant="contained" 
						style={Object.assign({},styles.buttons , styles.clear_log)}
						onClick={() => {this.removeItem();}}
					>
						{t('clear log')}
					</Button>
					<Button 
						id="point_away"
						variant="contained" 
						style={Object.assign({},styles.buttons , styles.points_offline)}
						onClick={() => {this.setItem('POINT', 'AWAY');}}
					>
						{t('away point')}
					</Button>
				</div>
				<div className="second_row" style={styles.row}>
					<Button 
						id="misconduct_home"
						variant="contained" 
						style={Object.assign({},styles.buttons , styles.misconduct_offline)}
						onClick={() => {this.setItem('MISCONDUCT', 'HOME');}}
					>
						{t('home misconduct')}
					</Button>
					<CallMe showButton NoConnectionState={true}/>
					<Button 
						id="misconduct_away"
						variant="contained" 
						style={Object.assign({},styles.buttons , styles.misconduct_offline)}
						onClick={() => {this.setItem('MISCONDUCT', 'AWAY');}}
					>
						{t('away misconduct')}
					</Button>
				</div>

				<div className="offline_text_field" style={styles.offline_text_field}>
					<StyleRoot>
						<div style={styles.reactAnimation}>
							{t('offline scoring')}
				          	<br></br>
				          	{t('full log on next page')}
						</div>
					</StyleRoot>
			  </div>

				<div className="point_text_field" style={styles.point_text_field}>		
						<div className="point_text" style={styles.point_text}>
							{t('last added offline command')}:
						</div>
						
						<div style={styles.status_text}>
							{this.state.last_command}
						</div>
				</div>	

	        	<BottomRow 
	        		setPage={this.props.setPage}  
	        		left_arrow={this.props.left_arrow || "CommandLogState"} 
	        		right_arrow="OfflineScoringState" 
	        		id={this.props.id}
	                team={this.props.team} 
	                no_short_log={true}   
	        	/>
	        </div>
	    );
	};
}

const styles = {
	wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  row: {
  	height: '17%',
  	display: 'flex',
  	justifyContent: 'center',

  },
  buttons: {
  	width: '33.3%',
  	borderRadius: 10,
  	border:'1px solid',
  	color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh',
  },
  clear_log: {
		background: '#455a64',
		textTransform: 'capitalize'
  },
  callme: {
  	width: '33.3%',
    color: 'red',
    fontWeight: 'bold',
    fontSize: '2.8vh',
    borderRadius: 10,
  },
  black_buttons: {
  	background: 'linear-gradient(rgb(117, 117, 117), rgb(245, 245, 245))',
  	width: '100%',
  },
  points_offline: {
  	background: '#4CAF50',
		opacity: 0.7,
		textTransform: 'capitalize'
  },
  misconduct_offline: {
  	background: '#df2029',
		opacity: 0.7,
		textTransform: 'capitalize'
  },
  status_text: {
  	display: 'flex',
  	justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '2.6vh',
    borderRadius: 10,
    color: '#263962',
  },
  point_text_field: {
  	height: '8%',
  	borderRadius: 10,
  	display: 'flex',
  	justifyContent: 'space-between',
  	flexDirection: 'column',
  	alignItems: 'center',
  	color: '#3b5999',
    fontWeight: 'bold',
    background: 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)',
  	paddingTop: '1%',
  },
  offline_text_field: {
  	borderRadius: 10,
  	color: '#3b5999',
    fontWeight: 'bold',
    fontSize: '4.8vh',
    height: '36%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  point_text: {
	display: 'flex',
	justifyContent: 'center',
	fontSize: '2.5vh',
	fontWeight: 'bold',
  },
  reactAnimation: {
    animation: 'x 1s',
		animationName: Radium.keyframes(tada, 'tada'),
		textTransform: 'capitalize'
  },
};

export default  withTranslation()(NoConnectionState);