import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import socketIOClient from "socket.io-client";
import './App.css';
import {} from './MCC';
import MainState from './components/MainState';
import CommentState from './components/prematch/CommentsState';
import OfflineScoringState from './components/OfflineScoringState';
import NoConnectionState from './components/NoConnectionState';
import MatchInfoState from './components/prematch/MatchInfoState';
import VerOneState from './components/prematch/VerificationOne';
import VerTwoState from './components/prematch/VerificationTwo';
import VerThreeState from './components/prematch/VerificationThree';
import VerPostState from './components/prematch/PostMatchVerification';
import VerConnectionFeedback from './components/prematch/PostMatchConnectionFeedback';
import TimeoutState from './components/command_states/TimeoutState';
import PointTypeState from './components/command_states/PointTypes';
import CommandLogState from './components/CommandLogState';
import MisconductTypeState from './components/command_states/MisconductTypes';
import ConfirmState from './components/command_states/ConfirmState';
import ConfirmFinishedState from './components/command_states/ConfirmFinishedState';
import ConfirmFinishedAfterGSState from './components/command_states/ConfirmFinishedAfterGSState';
import EditDeleteState from './components/command_states/EditDeleteState';
import EditConfirmState from './components/command_states/EditConfirmState';
import ChallengeTypeState from './components/command_states/ChallengeTypes';
import StandByState from './components/command_states/StandByState';
import EndOfPeriodState from './components/command_states/EndOfPeriodState';
import PointReviewTypeState from './components/command_states/PointReviewTypes';
import DeleteConfirmState from './components/command_states/DeleteConfirmState';
import DeleteState from './components/command_states/DeleteState'; 
import EditOnlyState from './components/command_states/EditOnlyState'; 
import OpenCloseGame from './components/OpenCloseGame';
import MessageRow from './components/MessageLogRow';
import CacheBuster from './CacheBuster';

const fetch_url  = window.location.pathname + '/true';
const server_url = window.location.host;

export let socket;

/*Set font-family of the entire app, taken from rel="stylesheet" in index.js*/
const theme = createMuiTheme({
  typography: {
    fontFamily: "Rubik",
  },
});

class App extends Component {
  
  constructor() {
    super();

    this.setPage = this.setPage.bind(this);

    this.state = {
      currentPage : <NoConnectionState />
    };

  }

  componentDidMount() {
    /*Call our fetch function below once the component mounts*/
    this.fetchData()
      .then(res => {
        const params = Object.assign(res);

        this.socketConnect(params);
        window.MCC.platform = params.page;
        window.params = params; //access params in browser
      })
      .catch(err => console.log(err));
  }
    
  /*Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js*/
  fetchData = async () => {
    const response = await fetch(fetch_url);
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message) 
    }
    return body;
  }

  pages = {
    'MainState'                  : MainState,
    'NoConnectionState'          : NoConnectionState,
    'OfflineScoringState'        : OfflineScoringState,
    'MatchInfoState'             : MatchInfoState,
    'CommentState'               : CommentState,
    'VerOneState'                : VerOneState,
    'VerTwoState'                : VerTwoState,
    'VerThreeState'              : VerThreeState,
    'VerPostState'               : VerPostState,
    'VerConnectionFeedback'      : VerConnectionFeedback,
    'TimeoutState'               : TimeoutState,
    'PointTypeState'             : PointTypeState,
    'CommandLogState'            : CommandLogState,
    'MisconductTypeState'        : MisconductTypeState,
    'ConfirmState'               : ConfirmState,
    'ChallengeTypeState'         : ChallengeTypeState,
    'EditDeleteState'            : EditDeleteState,
    'EditConfirmState'           : EditConfirmState,
    'PointReviewTypeState'       : PointReviewTypeState,
    'EndOfPeriodState'           : EndOfPeriodState,
    'StandByState'               : StandByState,
    'DeleteConfirmState'         : DeleteConfirmState,
    'DeleteState'                : DeleteState,
    'ConfirmFinishedState'       : ConfirmFinishedState,
    'ConfirmFinishedAfterGSState': ConfirmFinishedAfterGSState,
    'EditOnlyState'              : EditOnlyState,
    'OpenCloseGame'              : OpenCloseGame, 
    'MessageRow'                 : MessageRow 
  }  

  setPage(page, props) {
    if (typeof page === 'undefined') {
      return;
    }

    const Page = this.pages[page];
    const Props = {...props, ...{setPage: this.setPage}};
    this.setState({currentPage: <Page {...Props}/>});
    if (!window.MCC.connectionLost && this.loadingStateTimer){
      clearTimeout(this.loadingStateTimer);
      this.loadingStateTimer = false;
    }
  }

  loadingStateTimer = false
  
  socketConnect = params => {
    socket = socketIOClient(server_url);

    socket.on('connect', () => {
      console.log('SOCKET IO CONNECTED!!!');
      window.MCC.connectionLost = false;
      
      socket.emit('join', {
        event_ids: [params.event_id],
        sport    : params.sport,
        client   : params.client,
        user     : params.user,
        token    : params.token,
        platform : params.platform,
        platform_type : params.platform_type,
        page     : '"'+ params.platform +'" socket connection',
        priority : 1
      }, (error) => {
        if (error) {
          socket.emit('mobile_log', 'Permission problem with the user' + params.event_id);
          socket.emit('mobile_log', 'Permission error: ' + error);
          console.error('Current user "'+ params.user +'" doesn\'t have permissions for Event with ID "'+ params.event_id +'"\n'+ error);
        }
      });  
      
      this.loadingStateTimer = setTimeout(() => {
        window.location.reload();
      }
      , 4000);
    
    });

    socket.on('disconnect', () => {
      /* Set window.MCC.initFlag to false as we need it in socket.on('client_count'... to proceed in the if and input the latest changes/commands - SRT - 794 */
      window.MCC.initFlag = false;

      window.MCC.connectionLost = true;
      window.MCC.current_nocommand_state = 'NoConnectionState';
      this.setPage('NoConnectionState');
      window.MCC.dispatchEvent('nav_bar', 'updateStatus');
      window.MCC.dispatchEvent('nav_bar' , 'removeOverlay');

      // try connecting again
	    // socket.connect();
    });

    socket.on("connect_error", (err) => {
      if (err && err.message) {
        socket.emit('mobile_log', 'connect_error due to ' + err.message);
      }
    });

    socket.on('reconnect_failed', function (data) {
      socket.emit('mobile_log', 'Reconnect failed');
    });

    socket.on('game_status', (data) => {
      window.MCC.game_status = Object.assign(data);
      if ((window.MCC.current_state === 'MatchInfoState' || window.MCC.current_state === 'StandByState' || window.MCC.current_state === 'EndOfPeriodState') && 
         ((window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'desktop') || 
         (window.MCC.platform === 'mobile_callcenter' && window.MCC.settings.callcenter_control === 'mobile'))
      ) {
          this.setPage(window.MCC.getState(), window.MCC.current_state_props); 
      } 

      window.MCC.dispatchEvent('nav_bar', 'updateStatus');
      window.MCC.dispatchEvent('CommentState', 'updateStatus');
      window.MCC.dispatchEvent('nav_bar' , 'removeOverlay');
      window.MCC.dispatchEvent('EndOfPeriodState', 'updateStats');
      window.MCC.dispatchEvent('ResultStatsField', 'updateStats');
    });

    socket.on('command', (data) => {
      if (typeof data !== 'object'){
        return;
      }
      
      window.MCC.commands[data.id] = data;
      window.MCC.commandUpdate(data, this.setPage, false);
     
      if ((window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'desktop') || 
          (window.MCC.platform === 'mobile_callcenter' && window.MCC.settings.callcenter_control === 'mobile') || 
          (data.key === 'setting' && typeof data.value === 'object' && data.value.key === 'callcenter_control'))
      {
          this.setPage(window.MCC.getState(), window.MCC.current_state_props);
          window.MCC.dispatchEvent('nav_bar', 'updateStatus');
      }

      if ((data.key === 'status_ok' || data.key === 'status_unsure') && 
          (window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'mobile')){
          this.setPage('CommentState', window.MCC.current_state_props);
      }
      window.MCC.dispatchEvent('nav_bar', 'removeOverlay');

      if (typeof data.mcc_id !== 'undefined' && window.MCC.platform === 'mobile_callcenter' && window.MCC.settings.callcenter_control === 'mobile'){
          window.MCC.setMobileStats(data);
      }
    });

    socket.on('stats', (data) => {
      if (typeof data !== 'object'){
        return;
      }     

      window.MCC.stats = Object.assign(data);

      window.MCC.dispatchEvent('MainState', 'updateStats');
      window.MCC.dispatchEvent('EndOfPeriodState', 'updateStats');
      window.MCC.dispatchEvent('CommentState', 'updateStats'); 
      window.MCC.dispatchEvent('ResultStatsField', 'updateStats');       
    });

    socket.on('messages', (data) => {
      if (typeof data !== 'object'){
        return;
      }
      
      window.MCC.messages[data.id-1] = data;

      window.MCC.messageUpdate(data, this.setPage);
    });

    socket.on('action', (data) => {
      if (data === 'reload'){
        window.location.reload();
      }
    });

    socket.on('client_count', (data) => {

      if (typeof data.initial_data === 'object' && !window.MCC.initFlag){
        window.MCC.setOfflineScoringData();
        window.MCC.initFlag = true;
        window.MCC = {...window.MCC, ...data.initial_data};
        window.MCC.setVerification();
        window.MCC.mainPage = window.MCC.current_state;
        window.MCC.current_nocommand_state = window.MCC.current_state;
        if (window.MCC.platform === 'mobile_admin'){
          window.MCC.current_nocommand_state = 'CommentState';
          window.MCC.current_state_props.right_arrow = 'MainState';
        }
        window.MCC.settings.callcenter_control = 'mobile';
        for (let msg_prop in window.MCC.messages){
          window.MCC.messageUpdate(window.MCC.messages[msg_prop], this.setPage);
        }

        //reset MCC.score for every status since https://geniussports.atlassian.net/browse/SRT-938
        for (let status in window.MCC.active_statuses){
          window.MCC.score['status_' + window.MCC.active_statuses[status]] = [0,0];
        }
        
        //MCC.score is generated here
        for (let prop in window.MCC.commands){
          window.MCC.commandUpdate(window.MCC.commands[prop], this.setPage, true);
        }
        if (window.MCC.getState() === 'EditDeleteState'){
          window.MCC.current_nocommand_state = 'MainState';
        }
        if (window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'desktop'){
          if (window.MCC.getState() !== 'CommentState'){
            this.setPage(window.MCC.getState(), window.MCC.current_state_props);
          }else{
            this.setPage(window.MCC.getState(), {...window.MCC.current_state_props, disabled: true});
          }
        }
        if (window.MCC.platform === 'mobile_callcenter' && window.MCC.settings.callcenter_control === 'mobile'){
          if (window.MCC.setInitialState() === 'MainState'){
            this.setPage(window.MCC.getState(), window.MCC.current_state_props);
          }else{
            this.setPage(window.MCC.setInitialState(), window.MCC.current_state_props); 
          }
        }        
        if (window.MCC.platform === 'mobile_admin' && window.MCC.settings.callcenter_control === 'mobile'){
          window.MCC.current_nocommand_state = 'CommentState';
          this.setPage('CommentState');
        }
        if (window.MCC.platform === 'mobile_callcenter' && window.MCC.settings.callcenter_control === 'desktop'){
          window.MCC.current_nocommand_state = 'NoConnectionState';
          this.setPage('NoConnectionState'); 
        }
        
        window.MCC.dispatchEvent('nav_bar' , 'setOverlay');
      }      
    });

    socket.on('report_log', (data) => {
      if (typeof data !== 'object'){
        return;
      }

      window.MCC.report[data[0].key] = {value: data[0].value, log: data[0].log};
      window.MCC.setVerification();
    });          

  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }    
          return (
            <div>
              <MuiThemeProvider theme={theme}>
                {this.state.currentPage}
              </MuiThemeProvider>
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
