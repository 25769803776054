import React from 'react';
import Button from '@material-ui/core/Button';
import { socket } from '../../App';
import BottomRow from '../BottomRow';
import NavBar from '../NavBar';
import ResultStatsField from '../ResultStatsField';
import OpenCloseGame from '../OpenCloseGame';
import { withTranslation } from 'react-i18next';

class TimeoutState extends React.Component {
	
  constructor(props) {
    super(props);
    this.state = { 
      points  : typeof window.MCC.stats.running.points !== 'undefined' ? window.MCC.stats.running.points : [0,0],
      setswon : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0]
    };
  }

  componentDidMount = () => {
    window.MCC.mainPage = 'TimeoutState';
  }   	

  sendCommand = (key, team, confirmed, value) => {

	  let cmdObj = {
	    key: key,
	    team: team,
      confirmed: confirmed,
      mcc_id: Date.now()
    }

    if (typeof this.props.id !== 'undefined' && cmdObj.key !== 'status_unsure' && cmdObj.key !== 'status_ok'){
      cmdObj.id = window.MCC.back_id ? window.MCC.back_id : this.props.id;
    }

    if (typeof value !== 'undefined'){
      cmdObj.value = {type: value};
    }       

	  socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
	}

	render () {
    const {t} = this.props;
    return (
			<div className="wrapper" id="TimeoutState" style={styles.wrapper}>
		      <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>

		     	<div className="result_field" style={styles.result_field}>
		        	<ResultStatsField current_page='TimeoutState' current_team="0" mirror={true}/>

		        	<Button 
                  id="timeout"
                  variant="contained" 
                  disabled 
                  style={styles.timeout}
              >
			          	{t('timeout')}
			        </Button>

		        	<ResultStatsField current_page='TimeoutState' current_team="1" mirror={false}/>
		      	</div>

		      	<div className="content" style={styles.flex_column}>

		      		<div className="team_buttons" style={styles.team_buttons}>
		      			<Button 
                    id="home_team"
                    variant="contained" 
                    color="primary" 
                    style={styles.home_team_buttons} 
                    onClick = {() => this.sendCommand('timeout' , 1, 1, 'team')}
                >
			          		{t('home team')}
			        	</Button>
			        	<Button 
                    id="away_team"
                    variant="contained" 
                    color="secondary" 
                    style={styles.away_team_buttons} 
                    onClick = {() => this.sendCommand('timeout' , 2, 1, 'team')}
                >
			          		{t('away team')}
			        	</Button>
		      		</div>
		      		
		        	<Button 
                  id="technical_timeout"
                  variant="contained" 
                  style={styles.button} 
                  onClick = {() => this.sendCommand('timeout' , 0, 1, 'technical')}
              >
		          		{t('technical timeout')}
		        	</Button>

		        	<Button 
                  id="cancel"
                  variant="outlined" 
                  style={styles.cancel_button} 
                  onClick = {() => this.sendCommand('timeout' , 0, -1)}
              >
						      {t('cancel')}
					    </Button>
		      	</div>
		      	
		    	<div className="close_open_game" style={styles.close_open_game_field}>
		     		  <OpenCloseGame showButton="true"/>
		     	</div> 
  
          <BottomRow 
            setPage={this.props.setPage} 
            left_arrow="CommentState" 
            right_arrow="CommandLogState"
            id={this.props.id}
            team={this.props.team}             
          />
			  </div>

		);
	}
}

const styles = {
	wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },
  nav_bar: {
    height : '4%',
    width : '100vw',
    background: 'linear-gradient(#00FF00, #8d6e63)',
    textAlign : 'center' ,
  },
  result_field: {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '13%',
    justifyContent:'center',
  },
  team_buttons : {
	  display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '33.3%',
    marginBottom: 2,
  },
  flex_column : {
	  display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '40%',
    flexDirection: 'column',
  },
  cancel_button: {
  	height: '15%',
	  margin: 2,
    borderRadius: 10,
    color: 'red',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
    marginTop: '1%',
  },
  button: {
	  margin: 1,
	  height: '15%',
    borderRadius: 5,
    background: '#4CAF50',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  home_team_buttons : {
  	margin: 1,
	  height: '100%',
    borderRadius: 5,
    background: 'linear-gradient(#0064c8, #0024c8)',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  away_team_buttons : {
  	margin: 1,
	  height: '100%',
    borderRadius: 5,
    background: 'linear-gradient(#c80000, #c83500)',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    flexGrow: 1,
  },
  timeout: {
    margin: 1,
    width: '35%',
    borderRadius: 5,
    background: '#FB8C00',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
  },
  close_open_game_field: {
  	display: 'flex',
  	justifyContent: 'center',
	  width: '100%',
	  height: '13%',
	  marginTop: '0.6%',
    position:'absolute',
    bottom:'14%',
  }
};


export default withTranslation()(TimeoutState);