import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import BottomRow from '../BottomRow';
import { socket } from '../../App';
import OpenCloseGame from '../OpenCloseGame';
import NavBar from '../NavBar';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

let classes={};

class StandByState extends React.Component {

  constructor(props) {
    super(props);
    classes = Object.assign(props.classes);
  }

  state = {
    serving_team : (window.MCC.match_info && window.MCC.match_info.standby_kickoff_team) ? window.MCC.match_info.standby_kickoff_team : '',
    confirm      : (window.MCC.match_info && window.MCC.match_info.standby_kickoff_team !== 'Home' && window.MCC.match_info.standby_kickoff_team !== 'Away') ? false : true
  };

  componentDidMount = () => {
    window.MCC.mainPage = 'StandByState';
  }

  handleChange = event => {

    /* Sanity check for adding serving team as option - SRT-1142 */
    if (event.target.value && event.target.value === 'serving team') {
      return;
    }

    if (event.target.value === 'Home' || event.target.value === 'Away'){
      this.sendCommand('serving', event.target.value === 'Home' ? 1 : 2);
    }
    this.setState({ [event.target.name]: event.target.value, confirm: (event.target.value === 'Home' || event.target.value === 'Away' ? true : false)});
  };

  /*Emit command object*/
  sendCommand = (key, team) => {

    let cmdObj = {
      key: key,
      team: team,
      mcc_id: Date.now()
    }

    if (key === 'status'){
      cmdObj.value = (window.MCC.stats.running.setswon[0] >= 3 || window.MCC.stats.running.setswon[1] >= 3) && window.MCC.commands[this.props.id].status_id !== 26 ? 6 : window.MCC.game_status.next_status_id;
    }

    if (cmdObj.value !== '') {
      socket.emit('command', cmdObj);
      window.MCC.dispatchEvent('nav_bar', 'setOverlay');
    }

  }

  render() {
    const {t} = this.props;
    return (
      <div className="StandByState" style={styles.flex_column}>
      <NavBar setPage={this.props.setPage} disabled={this.props.disabled}/>
      <div style={styles.flex}>
        <div style={styles.flex_field}>
        <Button 
            id="standby"
            variant="contained" 
            style={styles.standby_btn}
            onClick = {() => this.sendCommand('standby', 0)}
        >
            {t('standby')}
        </Button>
      </div>

      <div className="serving_team" style={styles.flex_field}>
        <form className="root" autoComplete="off" style={styles.root}>
          <FormControl id="select_serving_team" className="formControl" style={styles.formControl}>
            <InputLabel htmlFor="comment-simple">*{t('serving team')}</InputLabel>
            <Select
                value={this.state.serving_team}
                onChange={this.handleChange}
                inputProps={{
                  name: 'serving_team'
                }}
            >
                <MenuItem disabled value="serving team" classes={{root: classes.root}}>*{t('serving team')}</MenuItem>
                <MenuItem value="Home" classes={{root: classes.root}}>{t('home')}</MenuItem>
                <MenuItem value="Away" classes={{root: classes.root}}>{t('away')}</MenuItem>
              </Select>
            </FormControl>
          </form>
      </div>

      <div style={styles.flex_field}>
        <Button 
            id="status"
            variant="contained" 
            disabled={((!this.state.confirm || typeof window.MCC.game_status.status_id === 'undefined') && (window.MCC.stats.running.setswon[0] < 3 && window.MCC.stats.running.setswon[1] < 3)) ? true : false} 
            style={(this.state.serving_team === 'Home' || this.state.serving_team === 'Away') && typeof window.MCC.game_status.status_id !== 'undefined' ? styles.confirm : styles.confirm_disabled}
            onClick = {() => {
                if ((window.MCC.stats.running.setswon[0] >= 3 || window.MCC.stats.running.setswon[1] >= 3) && window.MCC.commands[this.props.id].status_id !== 26){
                    this.props.setPage('EndOfPeriodState', {id: this.props.id, team: this.props.team});
                } else {   
                    this.sendCommand('status', 0);
                }
            }}
        >
            {typeof window.MCC.status_types[window.MCC.game_status.status_id] !== 'undefined' && typeof window.MCC.status_types[window.MCC.status_types[window.MCC.game_status.status_id].next] !== 'undefined' ? ((window.MCC.stats.running.setswon[0] >= 3 || window.MCC.stats.running.setswon[1] >= 3) && window.MCC.status_types[window.MCC.game_status.status_id].next !== 27 ? t('finished') : t(window.MCC.status_types[window.MCC.status_types[window.MCC.game_status.status_id].next].title.toLowerCase())) : ''}
        </Button>
      </div>  
      </div>
      

      <div className="connection_buttons" style={styles.connection_buttons}>

        <div className="close_open_game" style={styles.close_open_game_field}>
            <OpenCloseGame showButton="true"/>
          </div> 

        <BottomRow 
          setPage={this.props.setPage}
          left_arrow="CommentState"
          right_arrow="CommandLogState"
          id={this.props.id}
          team={this.props.team}
        />
      </div>
      </div>
    );
  }
}

/*CSS Styles*/

const styles = {
  root: {
    width: '70%',
    justifyContent: 'space-around',
    display: 'flex',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },  
  formControl: {
    margin: 2,
    width: '70%',
    textTransform: 'capitalize'
  },
  flex : {
    width: '100%',
    display : 'flex',
    marginTop: '1%' ,
    flexDirection: 'column',
    justifyContent: 'center',
    height: '50%',
  },
  flex_field:{
    width: '100%',
    display : 'flex',
    marginTop: '5%' ,
    justifyContent: 'center',
    height: '18%',
  },
  flex_column:{
    display : 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  connection_buttons: {
    display : 'flex',
    marginTop: '3%',
    width: '100%',
    height: '15%',
  },
  standby_btn: {
    background: '#4CAF50',
    width : '60%',
    borderRadius: 15,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh',
  },
  row: {
    display : 'flex',
    width: '100%',
    position:'absolute',
    bottom:0,
  },
  confirm: {
    margin: 2,
    width: '60%',
    color: 'green',
    fontWeight: 'bold',
    fontSize: '3.0vh',
    borderRadius: 15,
  },  
  confirm_disabled: {
    margin: 2,
    width: '60%',
    color: 'grey',
    fontWeight: 'bold',
    fontSize: '3.0vh',
    borderRadius: 15,
  },
  close_open_game_field: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '13%',
    marginTop: '0.6%',
    position:'absolute',
    bottom:'14%',
  },
  open_disabled: {
    margin: 2,
    width: '30%',
    borderRadius: 10,
    background: 'grey',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh',
  }
}

const overrideStyles = {
  root: {
    textTransform: 'capitalize' 
  } 
}

StandByState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(overrideStyles)(StandByState));