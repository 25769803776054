import React from 'react';
import NavBar from '../NavBar';
import { socket } from '../../App';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
//import PropTypes from 'prop-types';
import CallMe from '../CallMe';

//let classes={};

class VerConnectionFeedback extends React.Component {

    constructor(props) {
        super(props);
        console.log();
        //classes = Object.assign(props.classes);
    }
    state = {
        sr_connection_type : window.MCC.report.sr_connection_type ? window.MCC.report.sr_connection_type.value : [] ,
        sr_rate : window.MCC.report.sr_rate ? window.MCC.report.sr_rate.value : '' ,
        sr_network_provider : window.MCC.report.sr_network_provider ? window.MCC.report.sr_network_provider.value : '',
        sr_connection_comment : window.MCC.report.sr_connection_comment ? window.MCC.report.sr_connection_comment.value : '',
        confirm : (window.MCC.report.sr_rate && window.MCC.report.sr_rate.value !== '' && 
                   window.MCC.report.sr_network_provider && window.MCC.report.sr_network_provider.value.length >= 2 &&
                   window.MCC.report.sr_connection_comment && window.MCC.report.sr_connection_comment.value.length >= 5 &&
                   window.MCC.report.sr_connection_type.value.length > 0) ? true : false 
    }

    handleChange = event => {
        let confirm = true;

            socket.emit('report', {[event.target.name]: event.target.value});

            if (typeof event.target.value === 'object' || event.target.value.replace(/\s+/g, '')  !== '') {
            for (var prop in this.state){

                if (prop !== event.target.name) {
                    
                if (prop === 'sr_connection_type' && this.state[prop].length === 0) {
                    confirm = false;
                    break;
                }         
                if (prop === 'sr_network_provider' && this.state[prop].length < 2) {
                    confirm = false;
                    break;
                }
                if (prop === 'sr_rate' && this.state[prop].value === '') {
                    confirm = false;
                    break;
                }

                if (prop === 'sr_connection_comment' && this.state[prop].length < 5) {
                    confirm = false;
                    break;
                }
                }

                else{
                if ((prop === 'sr_network_provider' && event.target.value.length < 2) ||
                    (prop === 'sr_connection_comment' && event.target.value.length < 5) ||
                    (prop === 'sr_connection_type' && event.target.value.length < 1)) {
                    confirm = false;
                }

                }
            }
            }else{
                confirm = false;
            }
            this.setState({ [event.target.name]: event.target.value, confirm: confirm });
    };

    render() {
        const {t} = this.props;

        return ( 
            <div className="wrapper" style={styles.wrapper}>
                <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>

                    <div className="info_box" style={styles.info_box}>
                        <div className="sport_section">
                            <p>{t('post match connection feedback')}</p>
                        </div>
                    </div>
     
                    <div className="connection_type_field" style={styles.rate_connevtion_field}>
                        <form className="root" autoComplete="off" style={styles.root}>
                            <FormControl id="sr_connection_type" className="formControl" style={styles.formControlFull}>
                            <InputLabel htmlFor="comment-simple">{t('connection type')}</InputLabel>
                            <Select
                                multiple={true} 
                                autoWidth={true}
                                value={this.state.sr_connection_type}
                                onChange={this.handleChange}
                                inputProps={{
                                    id: 'sr_connection_type_id',
                                    name: 'sr_connection_type'
                                }}
                            >
                                <MenuItem value="" disabled>
                                <em>{t('please select')}</em>
                                </MenuItem>
                                <MenuItem value={'2G'} >{t('2G')}</MenuItem>
                                <MenuItem value={'3G'} >{t('3G')}</MenuItem>
                                <MenuItem value={'4G'} >{t('4G')}</MenuItem>
                                <MenuItem value={'4G/LTE'} >{t('4G/LTE')}</MenuItem>
                                <MenuItem value={'Wi:Fi at the Venue'} >{t('WiFi- Venue')}</MenuItem>
                                <MenuItem value={'MI:FI device'} >{t('MiFi- Device')}</MenuItem>
                                <MenuItem value={'Other'} >{t('other')}</MenuItem>
                                <MenuItem value={'Ethernet Adapter'} >{t('ethernet adapter')}</MenuItem>
                            </Select>
                            </FormControl>
                        </form>
                    </div>

                    <div className="network_provider_field" style={styles.network_provider_field}>
                        <TextField
                            id="sr_network_provider"
                            label={t('network provider')}
                            value={this.state.sr_network_provider}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'sr_network_provider'
                            }}
                            style={styles.formControlFull}
                        />
                    </div>

                    <div className="rate_connetion_field" style={styles.rate_connevtion_field}>
                        <form className="root" autoComplete="off" style={styles.root}>
                            <FormControl id="sr_rate" className="formControl" style={styles.formControlFull}>
                            <InputLabel htmlFor="comment-simple">{t('rate your connection *1=awful,5=perfect')}</InputLabel>
                            <Select
                                //multiple={true} 
                                autoWidth={true}
                                value={this.state.sr_rate}
                                onChange={this.handleChange}
                                inputProps={{
                                    id: 'sr_rate_id',
                                    name: 'sr_rate'
                                }}
                            >
                                <MenuItem value="" disabled>
                                <em>{t('please select')}</em>
                                </MenuItem>
                                <MenuItem value={'5'} >{(5)}</MenuItem>
                                <MenuItem value={'4'} >{(4)}</MenuItem>
                                <MenuItem value={'3'} >{(3)}</MenuItem>
                                <MenuItem value={'2'} >{(2)}</MenuItem>
                                <MenuItem value={'1'} >{(1)}</MenuItem>
                            </Select>
                            </FormControl>
                        </form>
                    </div>


                    <div className="connection_info_field" style={styles.connection_info_field}>
                        {t('if your connection was not up to standart or you had to change your internet source, please describe below in details what exactly has happened.')}
                    </div>

                    <div className="col-1-1 row-1-8 settings-box" >
                        <textarea id="sr_connection_comment" 
                                  name="sr_connection_comment"
                                  placeholder={t('Enter your comment here')}
                                  value={this.state.sr_connection_comment}
                                  onChange={this.handleChange}
                                //   inputProps={{
                                //     name: 'sr_connection_comment'
                                //   }}
                                  style={styles.connection_info_text}>
                        </textarea>
                    </div>

                    <div style={styles.confirm_callme_field}>
                        <CallMe showButton/>
                        <Button variant="outlined" 
                            id="close_reporting"
                            disabled={!this.state.confirm} 
                            color="secondary" 
                            style={this.state.confirm ? styles.confirm : styles.confirm_disabled}
                            onClick = {() => {
                                socket.emit('report', {'prematch_stepp2_confirmed' : 'yes'});
                                window.MCC.sendVerification('steps');
                                this.props.setPage('NoConnectionState', {left_arrow: 'none'});
                            }}
                        >
                            {t('close reporting')}
                        </Button>
                        
                        {/* <Button 
                            id="btn_reset" 
                            disabled={true} 
                            //disabled={!this.state.confirm} 
                            color="secondary" 
                            style={styles.confirm_disabled}>
                            <span>'RESET'</span> 
                        </Button>   */}  
                    </div>
            </div>
        )
    }
}

const styles = {
    wrapper: {
        display : 'flex',
        flexDirection: 'column',
        height: '99%',
        width: '99%',
        position: 'absolute',
    },
    formControl: {
        margin: 1,
        width: '50%',
        textTransform: 'capitalize'
    },  
    formControlFull: {
        //margin: 1,
        width: '99%',
        //height: '50px',
        textTransform: 'capitalize'
    },  
    root: {
        width: '99%',
        //height: '50px',
        //justifyContent: 'space-around',
        display: 'flex',
    },
    connection_type_text: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    rate_connevtion_field : {
        display : 'flex',
        margin: '1%' ,
        justifyContent: 'center'
    },
    info_box: {
        width : '99%',
        height: '17%',
        textAlign: 'center',
        marginTop: '1%',
        textTransform: 'capitalize'
    },
    connection_type_field: {
        justifyContent: 'space-between',
        display: 'flex',
        width: '90%',
        height: '20%',
        margin: '1%'
    },
    network_provider_field : {
        //display : 'flex',
        //justifyContent: 'space-between',
        width: '99%',
        margin: '1%'
    },
    connection_info_field: {
        width : '99%',
        //height: '17%',
        textAlign: 'center',
        marginTop: '7%',
    },
    connection_info_text : {
        width : '90%',
        margin: '2%',
        height: '50px'
    },
    confirm_callme_field: {
        display : 'flex',
        marginTop: '5%',
        height: '40%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    confirm: {
        margin: 2,
        //height: '20%',
        width: '65%',
        color: 'green',
        fontWeight: 'bold',
        borderRadius: 15,
        fontSize: '3vh',
      },
    confirm_disabled: {
        margin: 2,
        //height: '20%',
        width: '65%',
        color: 'grey',
        fontWeight: 'bold',
        borderRadius: 15, 
        fontSize: '3vh',
    }
}

const overrideStyles = {
    root: {
      textTransform: 'capitalize' 
    } 
  }

VerConnectionFeedback.propTypes = {
    //classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(overrideStyles)(VerConnectionFeedback));