import React from 'react';
import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';
import BottomRow from '../BottomRow';
//import { socket } from '../../App';
import NavBar from '../NavBar';
import CallMe from '../CallMe';
import VerificationProgress from './VerificationProgress';
import ConfirmButton from './ConfirmButton';
import { withTranslation } from 'react-i18next';


class VerThreeState extends React.Component {
  state = {
    start_date  : (window.MCC.event_info && window.MCC.event_info.startdate ? window.MCC.event_info.startdate : ''),
    confirm: true
  };

  componentDidMount = () => {
    window.MCC.prematchPage = 'VerThreeState';
  }   

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {

    const {t} = this.props;

    return (
      <div className="dropdowns" style={styles.flex_column}>
        <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
        <div className="info_box" style={styles.info_box}>
        <VerificationProgress progress={50} data-test="ver_three_progress"/>
          <div className="sport_section">
            <p data-testid="verification_step_3" style={{fontWeight : 'bold', textTransform: 'capitalize'}}>{t('verification 3 - 30 min.')}</p>
          </div>
        </div>

        <div className="start_date" style={styles.flex}>
          <TextField
              id="start_date_test"    
              disabled
              label={'*' + t('event start date')}
              style={styles.textField}
              value={this.state.start_date}
              onChange={this.handleChange}
              inputProps={{
                name: 'start_date'
              }}
          />

        </div>
        
        <div style={styles.confirm_callme_field}>
            <CallMe showButton/>
            <ConfirmButton 
            id="confirm_button"
            data-testid="confirm_button"
            state={this.state} 
            reportType='prematch_step3_confirmed' 
            preMatch='VerThreeState' 
            text={t('confirm')} 
            {...this.props}/>
        </div>
        <BottomRow 
            setPage={this.props.setPage}
            left_arrow="none" 
            right_arrow="CommandLogState"
        />
      </div>
    );
  }
}

/*CSS Styles*/

const styles = {
  textField: {
    margin: 2,
    width: '60%',
    textTransform: 'capitalize'
  },
  flex : {
    display : 'flex',
    marginTop: '1%' ,
    justifyContent: 'space-evenly', 
  },
  flex_column:{
    display : 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  confirm_callme_field: {
    display : 'flex',
    marginTop: '15%',
    height: '18%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  callme: {
    margin: 2,
    width: '65%',
    color: 'red',
    fontWeight: 'bold',
    fontSize: '3.5vh',
    borderRadius: 25,
  },
  confirm: {
    margin: 2,
    width: '65%',
    color: 'green',
    fontWeight: 'bold',
    fontSize: '3.5vh',
    borderRadius: 25,
  },
  row: {
    display : 'flex',
    width: '100%',
    position:'absolute',
    bottom:0,
  },
  info_box: {
    width : '100%',
    height: '17%',
    textAlign: 'center',
    marginTop: '1%',
  }  
}

export default  withTranslation()(VerThreeState);