import React from 'react';
import Button from '@material-ui/core/Button';
import NavBar from '../NavBar';
import { socket } from '../../App';
import { withTranslation } from 'react-i18next';

class ConfirmFinishedAfterGSState extends React.Component {

  sendCommand = () => {

    let cmdObj = {
      key: 'status',
      confirmed: 1,      
      mcc_id: Date.now(),
      value: 28,
      id: this.props.id
    }

    socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
  };    

	render () {
    const {t} = this.props;
    return (
			<div className="wrapper" id="ConfirmState" style={styles.wrapper}>
          <NavBar setPage={this.props.setPage} disabled={this.props.disabled}/>
          <div className="result_field" style={styles.flex}>
            {t('Confirm the match is finished after golden set?')}
			    </div>

		      	<div className="content" style={styles.flex_column}>

		      		<div style={styles.buttons_field}>
		      			<Button 
                    id="yes_finished"
                    variant="contained" 
                    color="primary" 
                    style={styles.yes_button} 
                    onClick = {() => this.sendCommand()}
                >
			          {t('yes - finished')}
			        	</Button>
              </div>
		      	</div>
    		</div>

		);
	}
}

const styles = {
	wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  overlay_none:{
    display: 'none'
  },
  flex: {
    display : 'flex',
    textAlign: 'center',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '13%',
    justifyContent:'center',
    alignItems: 'center',
    fontSize: '3vh',
    fontWeight: 'bold',
  },
  buttons_field : {
	  display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '30%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flex_column : {
	  display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100vw',
    height: '50%',
    flexDirection: 'column',
    marginTop: '5%',
  },
  yes_button : {
  	margin: 2,
	  height: '100%',
    borderRadius: 15,
    background: 'linear-gradient(#0064c8, #0024c8)',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.8vh',
    justifyContent: 'center',
    width: '70%',

  },
  confirm_command: {
    margin: 2,
    width: '30%',
    borderRadius: 3,
    background: 'linear-gradient(#ff9100, #ffa733)',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.8vh',
  }
};


export default withTranslation()(ConfirmFinishedAfterGSState);