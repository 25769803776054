import React from 'react';
import Button from '@material-ui/core/Button';
import { socket } from '../App'
import BottomRow from './BottomRow';
import NavBar from './NavBar';
import ResultStatsField from './ResultStatsField';
import OpenCloseGame from './OpenCloseGame';
import { withTranslation } from 'react-i18next';

class MainState extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled     : false, 
      points       : typeof window.MCC.stats.running.points !== 'undefined' ? window.MCC.stats.running.points : [0,0],
      setswon      : typeof window.MCC.stats.running.setswon !== 'undefined' ? window.MCC.stats.running.setswon : [0,0],
      set1_points  : (typeof window.MCC.stats.status_7 !== 'undefined' && typeof window.MCC.stats.status_7.points !== 'undefined') ? window.MCC.stats.status_7.points : ['',''],
      set2_points  : (typeof window.MCC.stats.status_8 !== 'undefined' && typeof window.MCC.stats.status_8.points !== 'undefined') ? window.MCC.stats.status_8.points : ['',''],
      set3_points  : (typeof window.MCC.stats.status_9 !== 'undefined' && typeof window.MCC.stats.status_9.points !== 'undefined') ? window.MCC.stats.status_9.points : ['',''],
      set4_points  : (typeof window.MCC.stats.status_10 !== 'undefined' && typeof window.MCC.stats.status_10.points !== 'undefined') ? window.MCC.stats.status_10.points : ['',''],
      set5_points  : (typeof window.MCC.stats.status_11 !== 'undefined' && typeof window.MCC.stats.status_11.points !== 'undefined') ? window.MCC.stats.status_11.points : ['',''],
      setGS_points : (typeof window.MCC.stats.status_27 !== 'undefined' && typeof window.MCC.stats.status_27.points !== 'undefined') ? window.MCC.stats.status_27.points : ['','']      
    };
  }

  /*Emit command object*/
  sendCommand = (key, team, confirmed) => {
    
    let cmdObj = {
      key: key,
      confirmed: confirmed,
      team: team,
      mcc_id: Date.now(),
    }

    //socket.emit('mobile_log', 'Test log');
    
    socket.emit('command', cmdObj);
    window.MCC.dispatchEvent('nav_bar', 'setOverlay');
  }

  componentDidMount = () => {
    window.MCC.return_to_main_state = false;
    window.MCC.mainPage = 'MainState';

    /* If we are in EndOfPeriodState mode, we need to redirect to the EndOfState pop up on REFRESH , not in the MAIN STATE!!! */
    if (window.MCC.periodEnd) {
      window.MCC.mainPage = 'EndOfPeriodState';
      window.MCC.current_nocommand_state = 'EndOfPeriodState'
      this.props.setPage('EndOfPeriodState', {id: this.props.id, team: this.props.team});
    }
  }


  render() {
  const {t} = this.props;
  return (
    <div className="wrapper" id="MainState" style={styles.wrapper}>
      <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
      <div className="row_1" style={styles.row}>
        <ResultStatsField current_page='MainState' current_team="0" mirror={true}/>

        <Button 
            id="timeout"
            variant="contained" 
            onClick = {() => this.sendCommand('timeout', 0)} 
            style={styles.timeout}
        >
          {t('timeout')}
        </Button>

        <ResultStatsField current_page='MainState' current_team="1" mirror={false}/>
      </div>

      <div className="row_2_4" style={styles.row_2_4}>
        <div className="team_serve" style={styles.flex_points}>
          <Button 
              id="home_serve"
              variant="contained" 
              disabled={(window.MCC.serve_home !== 'disabled' && !window.MCC.last_unconfirmed_point) ? false : true} 
              onClick = {() => this.sendCommand('serve', 1)} 
              style={(window.MCC.serve_home !== 'disabled' && !window.MCC.last_unconfirmed_point) ? styles.serve : styles.disabled_serve}
          >
              {t('home serve')}
          </Button>
          <Button 
              id="away_serve"
              variant="contained" 
              disabled={(window.MCC.serve_away !== 'disabled' && !window.MCC.last_unconfirmed_point) ? false : true} 
              onClick = {() => this.sendCommand('serve', 2)} 
              style={(window.MCC.serve_away !== 'disabled' && !window.MCC.last_unconfirmed_point) ? styles.serve : styles.disabled_serve}
          >
              {t('away serve')}
          </Button>  
        </div>
        
        <div className="team_points" style={styles.flex_points}>
          <Button 
              id="home_point"
              variant="contained"
              disabled={(this.props.point !== 'disabled' && !window.MCC.last_unconfirmed_point) ? false : true}  
              onClick = {() => this.sendCommand('point', 1)} 
              style={(this.props.point !== 'disabled' && !window.MCC.last_unconfirmed_point) ? styles.point : styles.disabled}
          >
              {t('home point')}
          </Button>
          <Button 
              id="away_point"
              variant="contained"
              disabled={(this.props.point !== 'disabled' && !window.MCC.last_unconfirmed_point) ? false : true}  
              onClick = {() => this.sendCommand('point', 2)} 
              style={(this.props.point !== 'disabled' && !window.MCC.last_unconfirmed_point) ? styles.point : styles.disabled}
          >
              {t('away point')}
          </Button>  
        </div>

        <div className="confirm_and_review" style={styles.flex_points}>
          <Button 
              id="confirm_point"
              variant="contained" 
              disabled={(this.props.confirm === 'enabled' || window.MCC.last_unconfirmed_point) ? false : true}
              onClick = {() => {
                window.MCC.current_nocommand_state =  'ConfirmState';
                window.MCC.point_state = 'ConfirmState';
                this.props.setPage('ConfirmState', {id: window.MCC.last_unconfirmed_point.id, team: window.MCC.last_unconfirmed_point.team, type: 'point'});
              }} 
              style={(this.props.confirm === 'enabled' || window.MCC.last_unconfirmed_point) ? styles.confirm_point : styles.disabled}
          >
              {t('confirm point')}
          </Button>
          <Button 
              id="point_review"
              variant="contained"
              disabled={(this.props.confirm === 'enabled' || window.MCC.last_unconfirmed_point) ? false : true} 
              onClick = {() => this.sendCommand('point_review', 0)} 
              style={(this.props.confirm === 'enabled' || window.MCC.last_unconfirmed_point) ? styles.point_review : styles.disabled}
          >
              {t('point review')}
          </Button>     
        </div>
       
      </div>

      <div className="row_5" style={styles.row}>
        <Button 
            id="home_subst"
            variant="contained" 
            onClick = {() => this.sendCommand('subst', 1)} 
            style={styles.subst}
        >
            {t('home subst')}
        </Button>
        <Button 
            id="home_chl"
            variant="contained" 
            disabled={(window.MCC.challenge_home === 'enabled') ? false : true}
            onClick = {() => this.sendCommand('challenge', 1, 1)} 
            style={(window.MCC.challenge_home === 'enabled') ? styles.challenge : styles.disabled}
        >
            {t('home chl')}
        </Button>
        <Button 
            id="away_chl"
            variant="contained" 
            disabled={(window.MCC.challenge_away === 'enabled') ? false : true}
            onClick = {() => this.sendCommand('challenge', 2, 1)} 
            style={(window.MCC.challenge_away === 'enabled') ? styles.challenge : styles.disabled}
        >
            {t('away chl')}
        </Button>

        <Button 
            id="away_subst"
            variant="contained" 
            onClick = {() => this.sendCommand('subst', 2)} 
            style={styles.subst}
        >
            {t('away subst')}
        </Button>
      </div>

      <div className="row_6" style={styles.row}>
        <Button 
            id="home_misconduct"
            variant="contained" 
            onClick = {() => this.sendCommand('misconduct', 1)} 
            style={styles.misconduct}
        >
            {t('home misconduct')}
        </Button>
        
        <OpenCloseGame showButton="true"/>

        <Button 
            id="away_misconduct"
            variant="contained" 
            onClick = {() => this.sendCommand('misconduct', 2)} 
            style={styles.misconduct}
        >
            {t('away misconduct')}
        </Button>    
      </div>

      <BottomRow 
        setPage={this.props.setPage}
        left_arrow="CommentState" 
        right_arrow="CommandLogState" 
        id={this.props.id}
        team={this.props.team}   
      />
    </div>);
  }
}

/*CSS Styles*/

const styles = {
  button: {
    margin: 1,
    width: '33,3%',
    borderRadius: 5,
    flexGrow: 1,
  },
  overlay:{
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0,
    background: 'linear-gradient(#000000, #000000)',
    zIndex: 1000
  },
  result: {
    margin: 1,
    width: '33.3%',
    borderRadius: 5,
    flexGrow: 1,
  },
  overlay_none:{
    display: 'none'
  },  
  timeout: {
    margin: 1,
    width: '35%',
    borderRadius: 5,
    background: '#df2029',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    flexGrow: 1,
  },
  serve: {
    margin: 1,
    width: '50%',
    borderRadius: 5,
    background: '#34465d',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.6vh',
    flexGrow: 1,
  },
  point: {
    margin: 1,
    width: '50%',
    borderRadius: 5,
    background: '#4CAF50',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.6vh',
    flexGrow: 1,
  },
  confirm_point: {
    margin: 1,
    width: '50%',
    borderRadius: 5,
    background: '#FF5722',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.6vh',
    flexGrow: 1,
  },
  point_review: {
    margin: 1,
    width: '50%',
    borderRadius: 5,
    background: '#410093',
    color: 'white',
    fontWeight : 'bold',
    fontSize: '2.6vh',
    flexGrow: 1,
  },
  disabled_serve: {
    margin: 1,
    width: '50%',
    borderRadius: 5,
    background: '#EEEEEE',
    color: '#918e8e',
    fontWeight: 'bold',
    fontSize: '2.6vh',
    flexGrow: 1,
    opacity: 0.2,
  },
  disabled: {
    margin: 1,
    width: '25%',
    borderRadius: 5,
    background: '#EEEEEE',
    color: '#918e8e',
    fontWeight: 'bold',
    fontSize: '2.6vh',
    flexGrow: 1,
    opacity: 0.2,
  },
  subst: {
    margin: 1,
    width: '25%',
    borderRadius: 5,
    background: '#34465d',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.6vh',
    flexGrow: 1,
  },
  challenge: {
    margin: 1,
    width: '25%',
    borderRadius: 5,
    background: '#795548',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '2.6vh',
    flexGrow: 1,
  },
  misconduct: {
    margin: 1,
    width: '25%',
    borderRadius: 5,
    background: '#df2029',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.8vh',
    flexGrow: 1,
  },
  input: {
    display: 'none',
  },
  wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display : 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  row: {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100%',
    height: '13%',
  },
  'row_2_4': {
    display : 'flex',
    marginLeft: 'calc(50% - 50vw)',
    width: '100%',
    flexDirection: 'column',
    height: '42%',
  },
  flex: {
    display: 'flex',
  },
  flex_points:{
    display: 'flex',
    height: '33.3%',
  }
};

export default withTranslation()(MainState);