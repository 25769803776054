import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import BottomRow from '../BottomRow';
import { socket } from '../../App';
import NavBar from '../NavBar';
import CallMe from '../CallMe';
import VerificationProgress from './VerificationProgress';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

let classes={};

class VerOneState extends React.Component {

  constructor(props) {
    super(props);
    classes = Object.assign(props.classes);
  }

  state = {
    home_team_confirmed: window.MCC.report.home_team_confirmed ? window.MCC.report.home_team_confirmed.value : '',
    away_team_confirmed: window.MCC.report.away_team_confirmed ? window.MCC.report.away_team_confirmed.value : '',
    ko_time_confirmed: window.MCC.report.ko_time_confirmed ? window.MCC.report.ko_time_confirmed.value : '',
    confirm     : (window.MCC.report.home_team_confirmed && window.MCC.report.home_team_confirmed.value === 'yes' &&
                   window.MCC.report.away_team_confirmed && window.MCC.report.away_team_confirmed.value === 'yes' && 
                   window.MCC.report.ko_time_confirmed && window.MCC.report.ko_time_confirmed.value === 'yes' ) ? true : false
  };

  componentDidMount = () => {
    window.MCC.prematchPage = 'VerOneState';
  }  

  handleChange = event => {
    let confirm = true;

    if (typeof socket !== 'undefined') { 
      socket.emit('report', {[event.target.name]: event.target.value});
    }

    if (event.target && event.target.value  === 'yes'){
      for (var prop in this.state){
        if (prop !== event.target.name && prop !== 'confirm'){
          if (this.state[prop] !== 'yes'){
            confirm = false;
            break;
          }
        }
      }
    }else{
      confirm = false;
    }
    if (event.target) { 
      this.setState({ [event.target.name]: event.target.value, confirm: confirm });
    }
  };


  render() {
    const {t} = this.props;
    return (
      <div className="wrapper" style={styles.flex_column}>
        <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
        <div className="info_box" style={styles.info_box}>
        <VerificationProgress progress={0} data-test="ver_one_progress"/>
          <div className="sport_section">
            <p data-testid="verification_step_1" style={{fontWeight : 'bold', textTransform: 'capitalize'}}>{t('verification 1 - 40 min.')}</p>
          </div>
        </div>

        <div className="home_confirm" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_home_team_confirmed" className="formControl" style={styles.formControl}>
              <InputLabel data-testid="select_home_team" htmlFor="home-comment-simple">*{t('home team confirmed')}</InputLabel>

              <Select
                  id="home-comment-simple"
                  value={this.state.home_team_confirmed}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'home_team_confirmed'
                  }}
              >
                  <MenuItem disabled classes={{root: classes.root}}>{t('please select')}</MenuItem>
                  <MenuItem value="yes" classes={{root: classes.root}}>{t('yes')}</MenuItem>
                  <MenuItem value="no" classes={{root: classes.root}}>{t('no')}</MenuItem>
              </Select>

            </FormControl>
          </form>
        </div>

        <div className="away_confirm" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_away_team_confirmed" className="formControl" style={styles.formControl}>
              <InputLabel data-testid="select_away_team" htmlFor="away-comment-simple">*{t('away team confirmed')}</InputLabel>

              <Select
                  id="away-comment-simple"
                  value={this.state.away_team_confirmed}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'away_team_confirmed'
                  }}
              >
                  <MenuItem disabled classes={{root: classes.root}}>{t('please select')}</MenuItem>
                  <MenuItem value="yes" classes={{root: classes.root}}>{t('yes')}</MenuItem>
                  <MenuItem value="no" classes={{root: classes.root}}>{t('no')}</MenuItem>
              </Select>

            </FormControl>
          </form>
        </div>

        <div className="time_confirm" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_ko_time_confirmed" className="formControl" style={styles.formControl}>
              <InputLabel data-testid="select_event_time" htmlFor="time-comment-simple">*{t('event time confirmed')}</InputLabel>

              <Select
                  id="time-comment-simple"
                  value={this.state.ko_time_confirmed}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'ko_time_confirmed'
                  }}
              >
                  <MenuItem disabled value="" classes={{root: classes.root}}> <em>{t('none')}</em></MenuItem>
                  <MenuItem value="yes" classes={{root: classes.root}}>{t('yes')}</MenuItem>
                  <MenuItem value="no" classes={{root: classes.root}}>{t('no')}</MenuItem>
              </Select>

            </FormControl>
          </form>
        </div>

        <div style={styles.confirm_callme_field}>
          <CallMe showButton/>

          <Button 
              id="confirm"
              variant="outlined" 
              data-testid="confirm_button"
              disabled={typeof this.state !== 'undefined' && typeof this.state.confirm !== 'undefined' ? !this.state.confirm : true} 
              color="secondary" 
              style={this.state && this.state.confirm ? (this.state.confirm ? styles.confirm : styles.confirm_disabled) : styles.confirm_disabled}
              onClick = {() => {
                  socket.emit('report', {'prematch_step1_confirmed' : 'yes'});
                  window.MCC.sendVerification('steps');
                  this.props.setPage('VerTwoState', {id: this.id});
                  
              }}
          >
              {t('confirm')}
          </Button>      

        </div>
        <BottomRow
          setPage={this.props.setPage} 
          left_arrow="none" 
          right_arrow="CommandLogState"
        />
      </div>
    );
  }
}

/*CSS Styles*/

const styles = {
  formControl: {
    margin: 2,
    width: '60%',
    textTransform: 'capitalize'
  },
  root: {
    width: '100%',
    justifyContent: 'space-around',
    display: 'flex',
  },
  flex : {
    display : 'flex',
    marginTop: '1%' ,
    justifyContent: 'center',
  },
  flex_column:{
    display : 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  confirm_callme_field: {
    display : 'flex',
    marginTop: '10%',
    height: '18%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    display : 'flex',
    width: '100%',
    position:'absolute',
    bottom:0,
  },
  info_box: {
    width : '100%',
    height: '17%',
    textAlign: 'center',
    marginTop: '1%',
  },
  confirm: {
    margin: 2,
    height: 60,
    width: '65%',
    color: 'green',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    borderRadius: 25,
  },
  confirm_disabled: {
    margin: 2,
    height: 60,
    width: '65%',
    color: 'grey',
    fontWeight: 'bold',
    fontSize: '3.3vh',
    borderRadius: 25,
  }, 
}

const overrideStyles = {
  root: {
    textTransform: 'capitalize' 
  } 
}

VerOneState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(overrideStyles)(VerOneState));