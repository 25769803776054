import React from 'react';
import BottomRow from './BottomRow';
import List from '@material-ui/core/List';

class OfflineScoringState extends React.Component {

  constructor() {
    super();
    window.MCC.setOfflineScoringData();
  }

  render () {
    return(
        <div className="wrapper" style={styles.wrapper}>
      	    <List component="nav" style={styles.cmd_log}>
        		{window.MCC.offline_scoring_array}
        	</List>
	        <BottomRow 
	        	setPage={this.props.setPage} 
	        	left_arrow="NoConnectionState"
	        	right_arrow="none" 
	        	id={this.props.id}
	          team={this.props.team} 
	          no_short_log={true}   
	        />        	
        </div>
    );
  }
}

const styles = {
  wrapper: {
    position: 'absolute',
    height: '100%',
    width : '100%'
  },
  cmd_log: {
    width: '100%',
    height: '84%',
    overflowY: 'scroll'
  }
}

export default OfflineScoringState;