import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { socket } from '../../App';
import NavBar from '../NavBar';
import CallMe from '../CallMe';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

let classes={};

class VerPostState extends React.Component {

  constructor(props) {
    super(props);
    classes = Object.assign(props.classes);
  }

  state = {
    sr_score_confirmed: window.MCC.report.sr_score_confirmed ? window.MCC.report.sr_score_confirmed.value : '',
    sr_sets_confirmed: window.MCC.report.sr_sets_confirmed ? window.MCC.report.sr_sets_confirmed.value : '',
    sr_kickoff_ontime: window.MCC.report.sr_kickoff_ontime ? window.MCC.report.sr_kickoff_ontime.value : '',
    sr_kickoff_delay: window.MCC.report.sr_kickoff_delay ? window.MCC.report.sr_kickoff_delay.value : '',
    sr_comment: window.MCC.report.sr_comment ? window.MCC.report.sr_comment.value : '',
    confirm     : (window.MCC.report.sr_score_confirmed && window.MCC.report.sr_score_confirmed.value === 'yes' &&
                   window.MCC.report.sr_sets_confirmed && window.MCC.report.sr_sets_confirmed.value === 'yes' && 
                   window.MCC.report.sr_comment && window.MCC.report.sr_comment.value.length) ? true : false,
    set1_points : (typeof window.MCC.stats.status_7 !== 'undefined' && typeof window.MCC.stats.status_7.points !== 'undefined') ? window.MCC.stats.status_7.points : [0,0],
    set2_points : (typeof window.MCC.stats.status_8 !== 'undefined' && typeof window.MCC.stats.status_8.points !== 'undefined') ? window.MCC.stats.status_8.points : [0,0],
    set3_points : (typeof window.MCC.stats.status_9 !== 'undefined' && typeof window.MCC.stats.status_9.points !== 'undefined') ? window.MCC.stats.status_9.points : [0,0],
    set4_points : (typeof window.MCC.stats.status_10 !== 'undefined' && typeof window.MCC.stats.status_10.points !== 'undefined') ? window.MCC.stats.status_10.points : [0,0],
    set5_points : (typeof window.MCC.stats.status_11 !== 'undefined' && typeof window.MCC.stats.status_11.points !== 'undefined') ? window.MCC.stats.status_11.points : [0,0],
    setGS_points : (typeof window.MCC.stats.status_27 !== 'undefined' && typeof window.MCC.stats.status_27.points !== 'undefined') ? window.MCC.stats.status_27.points : [0,0]    
  };

  handleChange = event => {
    let confirm = true;

    socket.emit('report', {[event.target.name]: event.target.value});

    if (event.target.name !== 'sr_kickoff_ontime' && event.target.name !== 'sr_kickoff_delay'){

      if (event.target.value  === 'yes'){
        for (var prop in this.state){
          if (prop !== event.target.name && (prop === 'sr_score_confirmed' || prop === 'sr_sets_confirmed')){
            if (this.state[prop] !== 'yes'){
              confirm = false;
              break;
            }
          }
          if (prop !== event.target.name && (prop === 'sr_comment')){
            if (!this.state[prop].length){
              confirm = false;
              break;
            }
          }
          
        }
      }
      
      else{
        /* Disabled the NEXT button if some of the required fields are not populated */
        if ((event.target.name === 'sr_comment' && !event.target.value.length) || 
            event.target.value === 'no' || this.state.sr_score_confirmed !== 'yes' || 
            this.state.sr_sets_confirmed !== 'yes') {
          confirm = false;
        }
      }
    }
    
    else{
      confirm = this.state.confirm;
    }
    
    this.setState({ [event.target.name]: event.target.value, confirm: confirm });
  };


  render() {
    const {t} = this.props;
    return (
      <div className="wrapper" style={styles.wrapper}>
        <NavBar setPage={this.props.setPage} disabled={this.props.disabled} {...this.props}/>
        <div className="info_box" style={styles.info_box}>
          <div className="sport_section">
            <p>{t('post match verification')}</p>
          </div>
        </div>

        <div className="home_confirm" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_sr_score_confirmed" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">*{t('score')}: ({window.MCC.stats.running.setswon[0]} - {window.MCC.stats.running.setswon[1]})</InputLabel>
              <Select
                  value={this.state.sr_score_confirmed}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'sr_score_confirmed'
                  }}
              >
                  <MenuItem disabled classes={{root: classes.root}}>{t('please select')}</MenuItem>
                  <MenuItem value="yes" classes={{root: classes.root}}>{t('yes')}</MenuItem>
                  <MenuItem value="no" classes={{root: classes.root}}>{t('no')}</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>

        <div className="away_confirm" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_sr_sets_confirmed" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">
               *{t('sets')}: <br/>
               {this.state.set1_points[0]} | {" "}
               {this.state.set2_points[0]} | {" "}
               {this.state.set3_points[0]} | {" "}
               {this.state.set4_points[0]} | {" "}
               {this.state.set5_points[0]} | {" "}
               {this.state.setGS_points[0]} - {" "}
               {this.state.set1_points[1]} | {" "}
               {this.state.set2_points[1]} | {" "}
               {this.state.set3_points[1]} | {" "}
               {this.state.set4_points[1]} | {" "}
               {this.state.set5_points[1]} | {" "}
               {this.state.setGS_points[1]} | {" "}               
              </InputLabel>
              <Select
                  value={this.state.sr_sets_confirmed}
                  onChange={this.handleChange}
                  style={styles.sets_field}
                  inputProps={{
                    name: 'sr_sets_confirmed'
                  }}
              >
                  <MenuItem disabled>{t('please select')}</MenuItem>
                  <MenuItem value="yes">{t('yes')}</MenuItem>
                  <MenuItem value="no">{t('no')}</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>

        <div className="time_confirm" style={styles.flex}>
          <form className="root" autoComplete="off" style={styles.root}>
            <FormControl id="select_sr_kickoff_ontime" className="formControl" style={styles.formControl}>
              <InputLabel htmlFor="comment-simple">{t('start on time?')}</InputLabel>
              <Select
                  value={this.state.sr_kickoff_ontime}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'sr_kickoff_ontime'
                  }}
              >
                <MenuItem disabled value="" classes={{root: classes.root}}>
                  <em>{t('none')}</em>
                </MenuItem>
                  <MenuItem value="yes" classes={{root: classes.root}}>{t('yes')}</MenuItem>
                  <MenuItem value="no" classes={{root: classes.root}}>{t('no')}</MenuItem>
              </Select>
            </FormControl>
          </form>
        </div>

        <div className="late_field" style={styles.flex}>
          <TextField
              id="sr_kickoff_delay"
              label={t('if not how late?')}
              style={styles.formControl}
              value={this.state.sr_kickoff_delay}
              onChange={this.handleChange}
              inputProps={{
                name: 'sr_kickoff_delay'
              }}
          />
        </div>

        <div className="sr_comment_field" style={styles.flex}>
          <TextField
              id="sr_comment"
              label={t('Enter your comment here')}
              style={styles.formControl}
              value={this.state.sr_comment}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'sr_comment'
                  }}
              onClick = {() => {
                socket.emit('report', {'sr_comment' : this.state.sr_comment});
                window.MCC.sendVerification('steps');
              }}
          />
        </div>

        <div style={styles.confirm_callme_field}>
          <CallMe showButton/>
          <Button variant="outlined" 
              id="close_reporting"
              disabled={!this.state.confirm} 
              color="secondary" 
              style={this.state.confirm ? styles.confirm : styles.confirm_disabled}
              onClick = {() => {
                socket.emit('report', {'prematch_stepp_confirmed' : 'yes'});
                window.MCC.sendVerification('steps');
                this.props.setPage('VerConnectionFeedback', { left_arrow: 'none'});
              }}
              //classes: {root: 'VerConnectionFeedback-7'} ,
          >
              {t('next')}
          </Button>
        </div>
      </div>
    );
  }
}

/*CSS Styles*/

const styles = {
  sets_field: {
    paddingTop: '2.5vh',
  },
  formControl: {
    margin: 2,
    width: '90%',
    textTransform: 'capitalize'
  },
  root: {
    width: '100%',
    justifyContent: 'space-around',
    display: 'flex',
  },
  flex : {
    display : 'flex',
    marginTop: '1%' ,
    justifyContent: 'center',
  },
  wrapper:{
    display : 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  confirm_callme_field: {
    display : 'flex',
    marginTop: '5%',
    height: '40%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  confirm: {
    margin: 2,
    height: '20%',
    width: '65%',
    color: 'green',
    fontWeight: 'bold',
    borderRadius: 15,
    fontSize: '3vh',
  },
  confirm_disabled: {
    margin: 2,
    height: '20%',
    width: '65%',
    color: 'grey',
    fontWeight: 'bold',
    borderRadius: 15, 
    fontSize: '3vh',
  },
  info_box: {
    width : '100%',
    height: '17%',
    textAlign: 'center',
    marginTop: '1%',
    textTransform: 'capitalize'
  },
  comment_section: {
    justifyContent: 'space-around',
    display: 'flex',
    width: '90%',
    height: '20%',
  }  
}

const overrideStyles = {
  root: {
    textTransform: 'capitalize' 
  } 
}

VerPostState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(overrideStyles)(VerPostState));